import cityConfig from '@shared/apiConfigs/cities';
import { requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteModal from '../../../components/DeleteModal/deleteModal';
import toastMessage from '../../../constants/toastMessage';
import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Flex from '../../../designComponents/Flex';
import Pagination from '../../../designComponents/Pagination';
import Table, { TableDataType } from '../../../designComponents/Table';
import Typography from '../../../designComponents/Typography';
import {
  ADD_CITY_ROUTE,
  CITY_ROUTE,
  SETTING_ROUTE,
} from '../../../routes/routePaths';
import Setting from '..';
import { CitiesStyled } from './style';

type CityType = {
  _id: string;
  name: string;
  createdAt: string;
};

const Cities = () => {
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [cities, setCities] = useState<CityType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCities, setTotalCities] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [deleteCityId, setDeleteCityId] = useState<string>('');
  const navigate = useNavigate();

  const deleteHandler = async (cityId: string) => {
    setLoading((prevStates) => ({ ...prevStates, [cityId]: true }));
    try {
      await requestAPI(cityConfig.deleteCity(cityId));
      setOpenModal(false);

      setCities((prev) => prev.filter((x) => x._id !== cityId));
      toast.success(toastMessage.cityDeleted);
    } catch (error) {
      toast.error('Could not delete city');
    } finally {
      setLoading((prevStates) => ({
        ...prevStates,
        [cityId]: false,
      }));
    }
  };

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const result = await requestAPI(
          cityConfig.getAllCities({ page: currentPage, limit: 10 })
        );
        const totalCities = result.pagination.totalData;
        setTotalCities(totalCities);
        if (result) {
          setCities(result.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCities();
  }, [currentPage]);

  const handlePagination = ({ page, limit }: any) => {
    setCurrentPage(page);
    console.log(limit);
  };

  const tableData: TableDataType = {
    headings: [
      {
        key: 'sn',
        label: 'SN',
      },
      {
        key: 'name',
        label: 'Name',
      },
      {
        key: 'actions',
        label: 'Action',
      },
    ],
    body: cities.map((city, index) => {
      return {
        sn: `${index + 1 + (currentPage - 1) * 10}`,
        id: city._id,
        name: city.name,
        actions: (
          <Button
            variant="danger"
            outline
            onClick={(e) => {
              e.stopPropagation();
              setDeleteCityId(city._id);
              setOpenModal(true);
            }}
            isLoading={loading[city._id] || false}
          >
            Delete
          </Button>
        ),
      };
    }),
  };
  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Cities',
      path: `${SETTING_ROUTE}/${CITY_ROUTE}`,
    },
  ];
  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <CitiesStyled>
          <Typography variant="heading">Cities/Town</Typography>
          <Flex justifyContent="flex-end" margin="0px 0px 30px 0px">
            <Button variant="primary" onClick={() => navigate(ADD_CITY_ROUTE)}>
              Add City
            </Button>
          </Flex>
          <Table hideSorting tableData={tableData} showCheckBox={false} />
          <Pagination
            activePage={currentPage}
            totalCount={totalCities}
            limit={10}
            handlePagination={handlePagination}
          />
        </CitiesStyled>
      </Setting>
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        desc="Are you sure want to delete city?"
        onConfirm={() => deleteHandler(deleteCityId)}
      />
    </>
  );
};

export default Cities;
