import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  & .content {
    display: flex;
    gap: 5px;
  }

  /* & .filter-btn {
    margin: 0px 12px;
    background: grey;
    border: 0;
    border-radius: 3;
    font-size: 12px;
    line-height: 18px;

    &:hover {
      background-color: red;
    }

    &.active {
      background: black;
      color: white;
    }
  } */
`;
