import adminUsersConfig from '@shared/apiConfigs/adminUsers';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';

import Button from '../../../../designComponents/Button';
import MultiSelect from '../../../../designComponents/MultiSelectDropdown';
import Select from '../../../../designComponents/SelectWithInput';
import Table from '../../../../designComponents/Table';
import Typography from '../../../../designComponents/Typography';
import { roles } from '../../../Leads/ViewDetail/EditLead/data';
import {
  formatClientTableData,
  getClientFilteredData,
  getLeadsAssignedData,
  handleAssignLead,
} from '../../../Leads/ViewDetail/EditLead/function';
import { AdminUser } from '../../../Leads/ViewDetail/EditLead/types';
import { ClientData } from '.';
import { InfoRowLinkedStyled, LeadContainer } from './style';

const AssignClient = ({
  clientDetails,
  clientId,
  fetchClientData,
}: {
  clientDetails: ClientData;
  clientId: string;
  fetchClientData: (id: string) => Promise<void>;
}) => {
  const [userRoleData, setUserRoleData] = useState<AdminUser[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  console.log(errorMessage);

  const [loading, setLoading] = useState(false);

  const handleRoleChange = async (
    role: string,
    setFieldValue: Function,
    index: number
  ) => {
    try {
      const { data } = await requestAPI(
        adminUsersConfig.getAdminUsersByRole(role)
      );
      setUserRoleData(data);
      setFieldValue(`assignTo[${index}].selectedOptions`, []);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LeadContainer>
      <Typography variant="heading" style={{ marginBottom: '10px' }}>
        Assigned To
      </Typography>
      <Formik
        initialValues={{
          assignTo: [{ role: '', name: '', record: {}, selectedOptions: [] }],
        }}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          const userIds = [
            ...values.assignTo.flatMap((item) =>
              item.selectedOptions.map(
                (data: { label: string; value: string }) => data.value
              )
            ),
            ...(clientDetails?.assignLead?.map((item) => item?._id) || []),
          ];

          handleAssignLead(
            setLoading,
            setErrorMessage,
            clientId,
            userIds,
            true,
            resetForm,
            fetchClientData
          );
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {clientDetails.assignLead &&
            clientDetails?.assignLead?.length > 0 ? (
              <Table
                tableData={formatClientTableData(
                  getLeadsAssignedData(clientDetails)
                )}
                style={{ marginBottom: '10px' }}
              />
            ) : (
              ''
            )}
            {values.assignTo.map((assignTo, index) => (
              <InfoRowLinkedStyled key={index}>
                <Select
                  options={roles}
                  onSelect={(selected) => {
                    setFieldValue(`assignTo[${index}].role`, selected);
                    handleRoleChange(selected, setFieldValue, index);
                  }}
                  selected={assignTo.role}
                  onChange
                  searchTerm=""
                />
                <MultiSelect
                  options={getClientFilteredData(
                    userRoleData,
                    assignTo.selectedOptions,
                    clientDetails
                  )}
                  selected={assignTo.selectedOptions}
                  onSelect={(selected) =>
                    setFieldValue(
                      `assignTo[${index}].selectedOptions`,
                      selected
                    )
                  }
                />

                {index !== values.assignTo.length - 1 && (
                  <Button
                    outline
                    onClick={() => {
                      const newAssignTo = values.assignTo.filter(
                        (_, i) => i !== index
                      );
                      setFieldValue('assignTo', newAssignTo);
                    }}
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                      border: '1px solid red',
                    }}
                  >
                    <AiOutlineCloseCircle size={25} />
                  </Button>
                )}
                {index === values.assignTo.length - 1 && (
                  <Button
                    onClick={() => {
                      setFieldValue('assignTo', [
                        ...values.assignTo,
                        { role: '', name: '', record: {}, selectedOptions: [] },
                      ]);
                    }}
                    disabled={
                      !values.assignTo[values.assignTo.length - 1]
                        .selectedOptions ||
                      values.assignTo[values.assignTo.length - 1]
                        .selectedOptions.length === 0
                    }
                  >
                    <AiOutlinePlusCircle size={25} />
                  </Button>
                )}
              </InfoRowLinkedStyled>
            ))}
            <Button
              type="submit"
              isLoading={loading}
              variant="primary"
              disabled={
                !values.assignTo[values.assignTo.length - 1].selectedOptions ||
                values.assignTo[values.assignTo.length - 1].selectedOptions
                  .length === 0
              }
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </LeadContainer>
  );
};

export default AssignClient;
