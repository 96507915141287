import paymentAgreementConfig from '@shared/apiConfigs/paymentAgreement';
import { humanReadableDate, requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Pagination from '../../../designComponents/Pagination';
import Table, { TableDataType } from '../../../designComponents/Table';
import {
  SETTING_ROUTE,
  VIEW_PAYMENT_AGREEMENTS_ROUTE,
} from '../../../routes/routePaths';
import Setting from '..';

type PaymentType = {
  _id: string;
  clientId: string;
  name: string;
  amount: number;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  id: number;
  __v: number;
};

type Props = {};

const PaymentAgreement = (_props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrrentPage] = useState(1);
  const [stages, setStages] = useState([]);

  const navigate = useNavigate();

  const tableData: TableDataType = {
    headings: [
      { key: 'id', label: 'Id' },

      { key: 'client', label: 'Client' },
      { key: 'name', label: 'Name' },
      {
        key: 'amount',
        label: 'Amount',
      },

      {
        key: 'status',
        label: 'Status',
      },
      {
        key: 'createdAt',
        label: 'Created At',
      },
      {
        key: 'updatedAt',
        label: 'Updated At',
      },
      {
        key: 'actions',
        label: 'Actions',
      },
    ],
    body: stages,
  };

  useEffect(() => {
    const deleteHandler = async (paymentId: string) => {
      // const confirmDelete = confirm('Are you sure you want to delete this?');
      // if (!confirmDelete) return;
      try {
        await requestAPI({
          method: 'delete',
          url: `/paymentAggerment/${paymentId}`,
        });
        navigate(0);
      } catch (err) {
        toast.error('Something went wrong!');
      }
    };

    requestAPI(
      paymentAgreementConfig.getAllPaymentAgreement({ page: currentPage })
    )
      .then((data) => {
        setTotalData(data.pagination.totalData);
        const newData = data.data.map((payment: PaymentType) => ({
          id: payment.id,
          client: payment.clientId,
          name: payment.name,
          amount: payment.amount,
          status: payment.status,
          createdAt: humanReadableDate(payment.createdAt),
          updatedAt: humanReadableDate(payment.updatedAt),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="secondary"
                outline
                onClick={() => {
                  navigate(payment._id);
                }}
              >
                View
              </Button>
              <Button variant="update" outline>
                Edit
              </Button>
              <Button
                variant="danger"
                outline
                onClick={() => {
                  deleteHandler(payment._id);
                }}
              >
                Delete
              </Button>
            </div>
          ),
        }));

        setStages(newData);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, [navigate, currentPage]);
  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Payment Agreement',
      path: `${SETTING_ROUTE}/${VIEW_PAYMENT_AGREEMENTS_ROUTE}`,
    },
  ];
  const handlePagination = ({ page }: { page: number }) => {
    setCurrrentPage(page);
  };
  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <Table
          hideSorting
          tableData={tableData}
          showCheckBox={false}
          isLoading={isLoading}
        />
        <Pagination
          activePage={currentPage}
          totalCount={totalData}
          limit={10}
          handlePagination={handlePagination}
        />
      </Setting>
    </>
  );
};

export default PaymentAgreement;
