import styled from 'styled-components';

export const AdminWrapperStyled = styled.div`
  padding: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: calc(100vh - 150px);
`;

export const InfoRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;
