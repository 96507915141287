import styled from 'styled-components';

import CheckGreenSVG from '../../assets/icons/check-green.svg';
export const CheckBoxStyled = styled.input`
  position: relative;
  &:hover {
    cursor: pointer;
  }

  // used before to cover
  &::before {
    content: '';
    position: relative;
    top: 0px;
    left: 0px;
    display: block;
    width: 14px;
    height: 14px;
    background: #fff;
  }

  // used after for styling
  &::after {
    content: '';
    position: relative;
    bottom: 14px;
    left: 0px;
    display: block;
    width: 14px;
    height: 14px;
    border: 1px solid ${({ theme }) => theme.colors.disableText};
    border-radius: 3px;
    background: #fff;
  }
  &:checked::after {
    border-color: ${({ theme }) => theme.colors.green.strong};
    background: url(${CheckGreenSVG});
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const LabelStyled = styled.label`
  color: ${({ theme }) => theme.colors.black};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  cursor: pointer;
`;
