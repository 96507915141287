import { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { CiUser } from 'react-icons/ci';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IoIosLogOut } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { useRoot } from '../../context/RootProvider';
import Typography from '../../designComponents/Typography';
import { HeaderContainer, LogoWrapper, ProfileWrapper } from './style';

type Props = {};

const Header = (_props: Props) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const { clearAuth, auth } = useRoot();
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const ref = useDetectClickOutside({
    onTriggered: () => setDropdownVisible(false),
  });

  return (
    <>
      <HeaderContainer>
        <LogoWrapper>
          <Logo
            style={{ cursor: 'pointer' }}
            className="energyfix-logo"
            onClick={() => navigate('/panel')}
          />
        </LogoWrapper>
        {isDropdownVisible && (
          <div
            style={{
              width: '220px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px',
              zIndex: '9999999',
              padding: '20px',
              background: 'white',
              position: 'absolute',
              right: 10,
              top: 62,
            }}
          >
            <Typography
              onClick={() => navigate('/settings/profile')}
              className="label"
            >
              <CiUser /> Profile
            </Typography>
            <Typography onClick={clearAuth} className="label">
              <IoIosLogOut /> Logout
            </Typography>
          </div>
        )}
        <ProfileWrapper onClick={toggleDropdown} ref={ref}>
          <div className="user">
            <CiUser className="user-pic" />
          </div>
          <div className="user-info">
            <Typography style={{ color: 'gray' }}>
              {auth?.lastName} User
            </Typography>
            <Typography>{auth?.email}</Typography>
          </div>
          <div>{isDropdownVisible ? <FaChevronUp /> : <FaChevronDown />}</div>
        </ProfileWrapper>
      </HeaderContainer>
    </>
  );
};

export default Header;
