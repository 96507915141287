import documentsConfig, { DocumentType } from '@shared/apiConfigs/documents';
import { requestAPI } from '@shared/utils';
import { uploadImagesToS3 } from '@shared/utils/s3Upload';
import { Form, Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import toastMessage from '../../../../constants/toastMessage';
import BreadCrumb from '../../../../designComponents/BreadCrumb';
import BrowseFile from '../../../../designComponents/BrowseFile';
import Button from '../../../../designComponents/Button';
import Input from '../../../../designComponents/Input';
import Select from '../../../../designComponents/SelectWithInput';
import Typography from '../../../../designComponents/Typography';
import {
  ADD_DOCUMENT_ROUTE,
  DOCUMENT_ROUTE,
  SETTING_ROUTE,
} from '../../../../routes/routePaths';
import Setting from '../..';
import {
  DocumentContainerStyled,
  DocumentWrapperStyled,
  InfoRowStyled,
} from './style';
import { validationSchema } from './validation';

type Props = {};

const initialValues = {
  name: '',
  // documentFileName: '',
  documentType: '',
};
const documentType = [
  { label: 'REPORT', value: 'REPORT' },
  { label: 'GRANT', value: 'GRANT' },
  { label: 'CONTRACT', value: 'CONTRACT' },
  { label: 'CERTIFICATE', value: 'CERTIFICATE' },
  { label: 'ADDITIONAL', value: 'ADDITIONAL' },
];

const AddDocument = (_props: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formikRef = useRef<FormikProps<typeof initialValues>>(null);

  const [file, setFile] = useState<File[]>([]);

  const handleAddDocument = async (values: DocumentType) => {
    try {
      setLoading(true);
      if (file.length > 0) {
        const documentURL = await uploadImagesToS3(file);
        console.log(documentURL, 'document url');
        if (documentURL && documentURL?.length > 0) {
          requestAPI(
            documentsConfig.addDocument({
              name: values.name,
              // documentFileName: values.documentFileName,
              documentType: values.documentType,
              documentURL: documentURL[0],
            })
          );
        }
      }
      setLoading(false);
      toast.success(toastMessage.documentAdded);
      navigate('/settings/document');
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddFiles = (files: File[]) => {
    if (formikRef?.current && files.length > 0) {
      formikRef?.current?.setFieldValue('documentFileName', files[0].name);
    }
    setFile(files);
  };
  const handleRemove = () => {
    setFile([]);
    formikRef?.current?.setFieldValue('documentFileName', '');
  };

  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Documents',
      path: `${SETTING_ROUTE}/${DOCUMENT_ROUTE}`,
    },
    {
      label: 'Add Document',
      path: `${SETTING_ROUTE}/${DOCUMENT_ROUTE}/${ADD_DOCUMENT_ROUTE}`,
    },
  ];
  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <DocumentContainerStyled>
          <Typography style={{ fontSize: '20px', marginBottom: '20px' }}>
            New Document
          </Typography>
          <DocumentWrapperStyled>
            <Formik
              initialValues={initialValues}
              onSubmit={handleAddDocument}
              innerRef={formikRef}
              validationSchema={validationSchema}
            >
              {({ values, handleSubmit, setFieldValue, errors, touched }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    {touched.name && errors.name && (
                      <div style={{ color: 'red', marginBottom: '5px' }}>
                        {errors.name}
                      </div>
                    )}

                    <InfoRowStyled>
                      <Typography className="label">Document Title*</Typography>
                      <Input
                        name="name"
                        value={values.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                    </InfoRowStyled>
                    {touched.documentType && errors.documentType && (
                      <div style={{ color: 'red', marginBottom: '5px' }}>
                        {errors.documentType}
                      </div>
                    )}

                    <InfoRowStyled>
                      <Typography className="label">Document Type*</Typography>
                      <Select
                        options={documentType}
                        onSelect={(selectedOption) => {
                          setFieldValue('documentType', selectedOption);
                        }}
                        selected={values.documentType}
                        placeholder="Type of Document"
                      />
                    </InfoRowStyled>

                    <InfoRowStyled>
                      <Typography>Upload Document*</Typography>

                      {file.length === 0 ? (
                        <BrowseFile
                          files={[]}
                          setFiles={handleAddFiles}
                          accepts=".pdf"
                          isUploading={false}
                          showSelectFiles={false}
                        />
                      ) : (
                        <div style={{ marginTop: '10px' }}>
                          <Typography>Uploaded File:</Typography>
                          <Typography>{file[0].name}</Typography>
                          <Button
                            type="button"
                            onClick={handleRemove}
                            variant="secondary"
                          >
                            Cancel
                          </Button>
                        </div>
                      )}
                    </InfoRowStyled>

                    <Button
                      type="submit"
                      variant="primary"
                      style={{ borderRadius: '5px' }}
                      isLoading={loading}
                    >
                      Add Document
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </DocumentWrapperStyled>
        </DocumentContainerStyled>
      </Setting>
    </>
  );
};

export default AddDocument;
