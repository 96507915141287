import styled from 'styled-components';

import Flex from '../../../../designComponents/Flex';

export const ViewFaqStyled = styled(Flex)`
  padding: 3rem 2rem;
  box-shadow: 0px 2px 5px 1px #0002;
  border-radius: 6px;
  min-height: calc(100vh - 150px);
`;
