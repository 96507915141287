import { ConfigType, ParamsType } from "../utils/requestAPI";

const paymentAgreementConfig = {
  createPaymentAgreement: (id: string): ConfigType => ({
    method: "post",
    url: `/payment-agreements/${id}`,
  }),
  updatePaymentAgreement: (id: string): ConfigType => ({
    method: "post",
    url: `/payment-agreements/${id}`,
  }),
  getAllPaymentAgreement: (params?: ParamsType): ConfigType => ({
    method: "get",
    url: `/payment-agreements/`,
    params,
  }),
  getPaymentAgreementById: (id: string): ConfigType => ({
    method: "post",
    url: `/payment-agreements/${id}`,
  }),
  createPaymentLink: (id: string): ConfigType => ({
    method: "post",
    url: `/payment-agreements/generate-payment-link`,
    data: {
      leadId: id,
    },
  }),
  claimAccountLink: (email: string): ConfigType => ({
    method: "post",
    url: `/leads/send-claim-account-link`,
    data: {
      email,
    },
  }),
};

export default paymentAgreementConfig;
