import styled from 'styled-components';

export const SelectWrapper = styled.div<{ $borderRadius: string }>`
  /* height: 50px; */
  padding: 7px;
  display: flex;
  align-items: center;
  border: 1px solid gray;
  width: 100%;
  justify-content: space-between;
  border-radius: ${({ $borderRadius }) => $borderRadius};
`;

export const OptionsContainerStyled = styled.div`
  background-color: #f5f5f5;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 100;
  max-height: 400px;
  overflow-y: auto;

  right: 0;
  padding: 20px 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  & .option {
    padding: 10px 6px;
    margin-bottom: 3px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
      color: white;
    }
  }
`;
