import { PropsWithChildren } from 'react';

import Profile from './Profile';
import SettingSidebar from './SettingSidebar';
import { Wrapper } from './SettingSidebar/style';

const Setting = ({ children }: PropsWithChildren) => {
  if (!children) {
    return <Profile />;
  }
  return (
    <>
      <Wrapper>
        <SettingSidebar />
        <div className="setting-content">{children}</div>
      </Wrapper>
    </>
  );
};

export default Setting;
