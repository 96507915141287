import cityConfig from '@shared/apiConfigs/cities';
import { requestAPI } from '@shared/utils';
import { ErrorMessage, Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import toastMessage from '../../../constants/toastMessage';
import AlertComponent from '../../../designComponents/Alert';
import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Flex from '../../../designComponents/Flex';
import Input from '../../../designComponents/Input';
import Typography from '../../../designComponents/Typography';
import {
  ADD_CITY_ROUTE,
  CITY_ROUTE,
  SETTING_ROUTE,
} from '../../../routes/routePaths';
import Setting from '..';
import { CitiesStyled } from './style';

type FormValues = {
  name: string;
};

const AddCity = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const addCity = async (values: any) => {
    setLoading(true);
    try {
      await requestAPI(
        cityConfig.createCity({
          regionId: values.region,
          name: values.name,
        })
      );
      toast.success(toastMessage.cityCreated);
      navigate(-1);
    } catch (error: any) {
      if (typeof error === 'string') setErrorMessage(error);

      if (error.error && typeof error.error === 'string') {
        setErrorMessage(error.error);
      }
    } finally {
      setLoading(false);
    }
  };
  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Cities',
      path: `${SETTING_ROUTE}/${CITY_ROUTE}`,
    },
    {
      label: 'Add City',
      path: `${SETTING_ROUTE}/${CITY_ROUTE}/${ADD_CITY_ROUTE}`,
    },
  ];
  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <CitiesStyled>
          <Formik
            initialValues={{ name: '' }}
            validate={(values: FormValues) => {
              const errors: Partial<FormValues> = {};
              if (!values.name) {
                errors.name = 'City Name is  Required';
              }

              return errors;
            }}
            onSubmit={addCity}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <Form autoComplete="false" onSubmit={handleSubmit}>
                <Typography style={{ fontSize: '20px' }}>City</Typography>

                <Flex direction="column" gap="10px" margin="20px 0px">
                  <Typography className="label">City Name</Typography>
                  <Input
                    name="name"
                    value={values.name}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                    placeholder="Enter country name"
                  />
                  <ErrorMessage name="name" component="div" />
                </Flex>

                <Flex gap="20px" alignItems="center" justifyContent="flex-end">
                  <Button
                    type="submit"
                    style={{ margin: 12 }}
                    isLoading={loading}
                    variant="primary"
                  >
                    Add City
                  </Button>
                  <Button onClick={() => navigate(-1)} className="btn-cancel">
                    Cancel
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
          {errorMessage && <AlertComponent text={errorMessage} type="error" />}
        </CitiesStyled>
      </Setting>
    </>
  );
};

export default AddCity;
