import { ConfigType } from "../utils/requestAPI";

export type DocumentType = {
  name: string;
  documentFileName?: string;
  documentType: string;
  documentURL?: string;
  grantName?: string;
  leadId?: string;
  isVisible?: boolean;
};

const documentsConfig = {
  getAllDocuments: (
    type?: string,
    leadId?: string,
    limit?: number
  ): ConfigType => {
    let query = `?`;
    if (type) {
      query += `documentType=${type}`;
    }
    if (typeof leadId !== "undefined") {
      query += `&leadId=${leadId}`;
    }
    if (limit) {
      query += `&limit=${limit}`;
    }
    return {
      method: `get`,
      url: `/documents${query}`,
    };
  },
  addDocument: (data: DocumentType): ConfigType => ({
    method: `post`,
    data,
    url: `/documents`,
  }),

  getDocumentById: (id: string): ConfigType => ({
    method: "get",
    url: `/documents/${id}`,
  }),
  editDocumentById: (data: DocumentType, id: any): ConfigType => ({
    method: "put",
    data,
    url: `/documents/${id}`,
  }),
  deleteDocumentById: (id: string): ConfigType => ({
    method: "delete",
    url: `/documents/${id}`,
  }),
};

export default documentsConfig;
