import cityConfig from '@shared/apiConfigs/cities';
import clientsConfig, {
  CreateClientManuallyDataType,
} from '@shared/apiConfigs/clients';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import toastMessage from '../../../constants/toastMessage';
import AlertComponent from '../../../designComponents/Alert';
import Button from '../../../designComponents/Button';
import Input from '../../../designComponents/Input';
import Select from '../../../designComponents/SelectWithInput';
import Typography from '../../../designComponents/Typography';
import { AdminWrapperStyled, InfoRowStyled } from './style';
import { validationSchema } from './validation';

type Props = {};
type cityType = {
  _id: string;
  value: string;
  label: string;
  name: string;
};

const typeOfProperty = [
  { label: 'Bungalow', value: 'Bungalow' },
  { label: 'Detached', value: 'Detached' },
  { label: 'Semi Detached', value: 'Semi Detached' },
  { label: 'Mid Terrace', value: 'Mid Terrace' },
  { label: 'Apartment', value: 'Apartment' },
  { label: 'Multi Unit', value: 'Multi Unit' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Others', value: 'Others' },
];
const ageOfProperty = [
  { label: 'Pre 1990', value: 'Pre 1990' },
  { label: '1960 1980', value: '1960 1980' },
  { label: '1980 2000', value: '1980 2000' },
  { label: '2001 2011', value: '2001 2011' },
  { label: '2011 onwards', value: '2011 onwards' },
  { label: "Don't Know", value: "Don't know" },
  { label: "Don't know the year", value: "Don't know the year0" },
];
const ber = [
  { label: 'A1', value: 'A1' },
  { label: 'A2', value: 'A2' },
  { label: 'A3', value: 'A3' },
  { label: 'B1', value: 'B1' },
  { label: 'B2', value: 'B2' },
  { label: 'B3', value: 'B3' },
  { label: 'C1', value: 'C1' },
  { label: 'C2', value: 'C2' },
  { label: 'C3', value: 'C3' },
  { label: 'D1', value: 'D1' },
  { label: 'D2', value: 'D2' },
  { label: 'E1', value: 'E1' },
  { label: 'E2', value: 'E2' },
  { label: 'F', value: 'F' },
  { label: 'G', value: 'G' },
  { label: "I don't know my rating", value: "I don't know my rating" },
];

const initialValues = {
  addressOne: '',
  addressTwo: '',
  cityId: '',
  eirCode: '',
  typeOfProperty: '',
  ageOfProperty: '',
  ber: '',
  mprn: '',
  email: '',
  firstName: '',
  lastName: '',
  mobileNumber: '',
};

const AddClient = (_props: Props) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [cities, setCities] = useState([{ value: '', label: '' }]);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await requestAPI(cityConfig.getAllCities());
      const data = response.data;
      const newData = data.map((item: cityType) => {
        return {
          value: item._id,
          label: item.name,
        };
      });
      setCities(newData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleAddClient = async (values: CreateClientManuallyDataType) => {
    try {
      setLoading(true);
      await requestAPI(
        clientsConfig.createClientManually({
          addressOne: values.addressOne,
          addressTwo: values.addressTwo,
          cityId: values.cityId,
          eirCode: values.eirCode,
          typeOfProperty: values.typeOfProperty,
          ageOfProperty: values.ageOfProperty,
          ber: values.ber,
          mprn: values.mprn,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          mobileNumber: values.mobileNumber,
        })
      );
      setLoading(false);
      toast.success(toastMessage.clientCreated);
      navigate(-1);
    } catch (error: any) {
      console.error(error);
      if (typeof error === 'string') setErrorMessage(error);

      if (error.error && typeof error.error === 'string')
        setErrorMessage(error.error);
      setLoading(false);
    }
  };

  return (
    <>
      <AdminWrapperStyled>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleAddClient}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '20px',
                  }}
                >
                  <InfoRowStyled>
                    <Typography size={16}>Address Line 1:*</Typography>

                    <Input
                      name="addressOne"
                      value={values.addressOne}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('addressOne', e.target.value)
                      }
                    />
                    {errors.addressOne && touched.addressOne && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.addressOne}
                      </div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography size={16}>Address Line 2:*</Typography>

                    <Input
                      name="addressTwo"
                      value={values.addressTwo}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('addressTwo', e.target.value)
                      }
                    />
                    {errors.addressTwo && touched.addressTwo && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.addressTwo}
                      </div>
                    )}
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography size={16}>City/County:*</Typography>
                    <Select
                      selected={values.cityId || ''}
                      options={cities}
                      onSelect={(selectOption: any) =>
                        setFieldValue('cityId', selectOption)
                      }
                      onChange
                    />
                    {errors.cityId && touched.cityId && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.cityId}
                      </div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography size={16}>EirCode:*</Typography>

                    <Input
                      name="eirCode"
                      value={values.eirCode}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('eirCode', e.target.value)
                      }
                    />
                    {errors.eirCode && touched.eirCode && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.eirCode}
                      </div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography size={16}>Type of Property:*</Typography>
                    <Select
                      selected={values.typeOfProperty || ''}
                      options={typeOfProperty}
                      onSelect={(selectOption: any) =>
                        setFieldValue('typeOfProperty', selectOption)
                      }
                      onChange
                    />
                    {errors.typeOfProperty && touched.typeOfProperty && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.typeOfProperty}
                      </div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography size={16}>Age of Property:*</Typography>
                    <Select
                      selected={values.ageOfProperty || ''}
                      options={ageOfProperty}
                      onSelect={(selectOption: any) =>
                        setFieldValue('ageOfProperty', selectOption)
                      }
                      onChange
                    />
                    {errors.ageOfProperty && touched.ageOfProperty && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.ageOfProperty}
                      </div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography size={16}>BER:*</Typography>
                    <Select
                      selected={values.ber || ''}
                      options={ber}
                      onSelect={(selectOption: any) =>
                        setFieldValue('ber', selectOption)
                      }
                      onChange
                    />
                    {errors.ber && touched.ber && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.ber}
                      </div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography size={16}>MPRN:*</Typography>

                    <Input
                      name="mprn"
                      value={values.mprn}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('mprn', e.target.value)
                      }
                    />
                    {errors.mprn && touched.mprn && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.mprn}
                      </div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography size={16}>Email:*</Typography>

                    <Input
                      name="email"
                      value={values.email}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('email', e.target.value)
                      }
                    />
                    {errors.email && touched.email && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.email}
                      </div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography size={16}>First Name:*</Typography>

                    <Input
                      value={values.firstName}
                      name="firstName"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('firstName', e.target.value)
                      }
                    />
                    {errors.firstName && touched.firstName && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.firstName}
                      </div>
                    )}
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography size={16}>Last Name:*</Typography>

                    <Input
                      value={values.lastName}
                      name="lastName"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('lastName', e.target.value)
                      }
                    />
                    {errors.lastName && touched.lastName && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.lastName}
                      </div>
                    )}
                  </InfoRowStyled>
                  <div>
                    <InfoRowStyled>
                      <Typography size={16}>Phone:*</Typography>

                      <Input
                        name="mobileNumber"
                        value={values.mobileNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('mobileNumber', e.target.value)
                        }
                      />
                      {errors.mobileNumber && touched.mobileNumber && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {errors.mobileNumber}
                        </div>
                      )}
                    </InfoRowStyled>
                  </div>
                  <Button
                    type="submit"
                    variant="primary"
                    style={{
                      width: '150px',
                      borderRadius: '5px',
                    }}
                    isLoading={loading}
                  >
                    Add Client
                  </Button>
                </div>
                {errorMessage && (
                  <AlertComponent text={errorMessage} type="error" />
                )}
              </Form>
            );
          }}
        </Formik>
      </AdminWrapperStyled>
    </>
  );
};

export default AddClient;
