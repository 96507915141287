import { TabWrapperStyled } from './style';
interface Props {
  tabTitles: string[];
  activeTab: string;
  onTabChange: (tabLabel: string) => void;
}

export default function Tab({ tabTitles, onTabChange, activeTab }: Props) {
  return (
    <TabWrapperStyled>
      <div className="tab">
        {tabTitles.map((title, i) => (
          <button
            key={i}
            className={`tab_item ${activeTab === title ? 'active' : ''}`}
            onClick={() => {
              console.log(title);
              onTabChange(title);
            }}
          >
            {title}
            {activeTab === title && <span />}
          </button>
        ))}
      </div>
    </TabWrapperStyled>
  );
}
