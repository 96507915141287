import styled from 'styled-components';

export const BuildingsStyled = styled.div`
  padding: 3rem 2rem;
  box-shadow: 0px 2px 5px 1px #0002;
  border-radius: 6px;
  /* min-height: calc(100vh - 150px); */
`;

export const InfoRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
