import 'react-quill/dist/quill.snow.css';

import faqsConfig from '@shared/apiConfigs/faqs';
import { requestAPI } from '@shared/utils';
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CustomEditor from '../../../../components/Editor';
import AutoCompleteInput from '../../../../designComponents/AutoCompleteInput';
import BreadCrumb from '../../../../designComponents/BreadCrumb';
import Button from '../../../../designComponents/Button';
import Input from '../../../../designComponents/Input';
import Typography from '../../../../designComponents/Typography';
import {
  ADD_FAQ_ROUTE,
  SETTING_ROUTE,
  VIEW_FAQS_ROUTE,
} from '../../../../routes/routePaths';
import Setting from '../..';
import { FaqType, FaqWithCategoryType } from '..';
import { ButtonContainer, FaqWrapperStyled, FormContainer } from './style';
type FormValues = {
  name: string;
  content: string;
  category: string;
};

const AddFaq = () => {
  const [editorState, setEditorState] = useState('');
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);

  const [faqs, setFaqs] = useState<FaqWithCategoryType[]>([]);

  useEffect(() => {
    requestAPI(faqsConfig.getAllFaqs())
      .then(({ data }) => {
        setFaqs(data);
      })
      .catch((err) => console.error(err));
  }, []);
  const categoriesOptions = useMemo(
    () => faqs.map((x) => ({ label: x.category, value: x.category })),
    [faqs]
  );

  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Faqs',
      path: `${SETTING_ROUTE}/${VIEW_FAQS_ROUTE}`,
    },
    {
      label: 'ADD Faqs',
      path: `${SETTING_ROUTE}/${VIEW_FAQS_ROUTE}/${ADD_FAQ_ROUTE}`,
    },
  ];
  const filteredData: FaqType[] = faqs.flatMap((faq) => faq.faq);
  const data = filteredData.find((item) => item._id === params.faqId);
  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <Formik
          initialValues={{
            name: data?.title || '',
            content: data?.content || '',
            category: '',
          }}
          validate={(values: FormValues) => {
            const errors: Partial<FormValues> = {};
            if (!values.name) {
              errors.name = 'Title Required';
            }
            if (!values.content) {
              errors.content = 'Content Required!!!';
            }
            return errors;
          }}
          enableReinitialize
          onSubmit={async (values, { setSubmitting }) => {
            setLoading(true);
            try {
              data?._id
                ? await await requestAPI(
                    faqsConfig.updateFaqs(params.faqId || '', {
                      title: values?.name,
                      content: editorState,
                    })
                  )
                : await requestAPI(
                    faqsConfig.createFaqs({
                      category: values?.category,
                      title: values?.name,
                      content: editorState,
                    })
                  );
              setLoading(false);
              setEditorState('');
              navigate(-1);
            } catch (err) {
              console.error('Error', err);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form autoComplete="false">
              <FaqWrapperStyled>
                <Typography style={{ fontSize: '20px' }}>Faq</Typography>

                <FormContainer>
                  {!params.faqId && (
                    <div style={{ margin: 12 }}>
                      <Typography className="label">Category</Typography>
                      <AutoCompleteInput
                        name="category"
                        value={values.category || ''}
                        onChange={(e) =>
                          setFieldValue('category', e.target.value)
                        }
                        onSelect={(value) => setFieldValue('category', value)}
                        options={categoriesOptions}
                        noDataText="No Category Found. New category will be created."
                        placeholder="Enter category name to select category"
                      />
                      <ErrorMessage name="name" component="div" />
                    </div>
                  )}
                  <div style={{ margin: 12 }}>
                    <Typography className="label">Question</Typography>
                    <Input
                      name="name"
                      value={values.name || ''}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                      placeholder="Enter faq question "
                    />
                    <ErrorMessage name="name" component="div" />
                  </div>
                  <div
                    style={{
                      margin: 12,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography className="label">Content</Typography>
                    <CustomEditor
                      initialContent={editorState || values.content || ''}
                      onEditorChange={(value: string) => {
                        setEditorState(value);
                        setFieldValue('content', value);
                      }}
                      onFilesChange={() => {}}
                    />
                    <ErrorMessage name="content" component="div" />
                  </div>
                  <ButtonContainer>
                    {' '}
                    <Button
                      type="submit"
                      disabled={isSubmitting || !values.name || !values.content}
                      style={{ margin: 12 }}
                      isLoading={loading}
                    >
                      {data ? 'Edit FAQ' : 'Create FAQ'}
                    </Button>
                    <Button onClick={() => navigate(-1)} className="btn-cancel">
                      Cancel
                    </Button>
                  </ButtonContainer>
                </FormContainer>
              </FaqWrapperStyled>
            </Form>
          )}
        </Formik>
      </Setting>
    </>
  );
};

export default AddFaq;
