import theme from '@shared/theme';
import React from 'react';
import { CiEdit } from 'react-icons/ci';
import { FaTrashAlt } from 'react-icons/fa';
import styled from 'styled-components';

import Loader from '../Loader';
import { StyledButton, StyleProps } from './style';

interface IButtonProps
  extends React.ComponentPropsWithoutRef<'button'>,
    StyleProps {
  isDisabled?: boolean;
  htmlType?: 'submit' | 'reset' | 'button';
  bgColor?: string;
  isLoading?: boolean;
  outline?: boolean;
}

const Button = ({
  children,
  variant,
  size,
  bgColor,
  color,
  isDisabled = false,
  width,
  outline,
  isLoading,
  htmlType = 'button',
  ...restProps
}: IButtonProps) => {
  // const loaderColor = outline ? color : 'white';

  return (
    <StyledButton
      size={size}
      variant={variant}
      $bgColor={bgColor}
      disabled={isDisabled}
      color={color}
      width={width}
      $outline={outline}
      type={htmlType}
      {...restProps}
    >
      {isLoading ? (
        <Loader color={theme.colors.white} type="spinner" size={'xsm'} />
      ) : (
        <>
          {variant === 'danger' && <DeleteIcon />}
          {variant === 'update' && <CiEdit />}
          {children}
        </>
      )}
    </StyledButton>
  );
};

Button.defaultProps = {
  variant: 'primary',
  size: 'md',
  bgColor: '',
  outline: false,
  color: '',
  width: 'fit-content',
};

export default Button;

export const FilterButton = styled(Button)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.normalText};
  border-radius: 6px;
  border-color: ${({ theme }) => theme.colors.disableText};
  border-width: 0.5px;
`;

const DeleteIcon = styled(FaTrashAlt)`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.red.strong};
`;
