import faqsConfig from '@shared/apiConfigs/faqs';
import { requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteModal from '../../../../components/DeleteModal/deleteModal';
import ReadOnlyEditor from '../../../../components/Editor/ReadOnlyEditor';
import BreadCrumb from '../../../../designComponents/BreadCrumb';
import Button from '../../../../designComponents/Button';
import Divider from '../../../../designComponents/Divider';
import Flex from '../../../../designComponents/Flex';
import Typography from '../../../../designComponents/Typography';
import {
  FAQ_DETAILS_ROUTE,
  SETTING_ROUTE,
  VIEW_FAQS_ROUTE,
} from '../../../../routes/routePaths';
import Setting from '../..';
import { FaqWithCategoryType } from '..';
import { ViewFaqStyled } from './style';

const ViewFaqs = () => {
  const { faqId: faqCategoryId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [deleteFaqId, setDeleteFaqId] = useState<string>('');
  const navigate = useNavigate();
  const [faqWithCategory, setFaqWithCategory] =
    useState<FaqWithCategoryType | null>(null);

  useEffect(() => {
    if (faqCategoryId && typeof faqCategoryId === 'string') {
      const fetchAllData = async () => {
        const result = await requestAPI(faqsConfig.getFaqsById(faqCategoryId));
        if (result && result) {
          setFaqWithCategory(result);
        }
      };
      fetchAllData();
    }
  }, [faqCategoryId]);

  if (!faqWithCategory) {
    return (
      <Setting>
        <Typography>Loading Data. PLease wait ...</Typography>
      </Setting>
    );
  }
  const faqs = faqWithCategory.faq;
  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Faqs',
      path: `${SETTING_ROUTE}/${VIEW_FAQS_ROUTE}`,
    },
    {
      label: 'View Faqs',
      path: `${SETTING_ROUTE}/${VIEW_FAQS_ROUTE}/${FAQ_DETAILS_ROUTE}`,
    },
  ];
  const deleteFaq = async (id: string) => {
    try {
      await requestAPI(faqsConfig.deleteSingleFaqById(id));
      toast.success('Deleted successfully');
      setOpenModal(false);
      setFaqWithCategory(
        (prev) =>
          prev && {
            ...prev,
            faq: prev?.faq.filter((x) => x._id !== id),
          }
      );
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <ViewFaqStyled direction="column" gap="25px">
          <div>
            <Typography variant="heading">
              {faqWithCategory.category}
            </Typography>
            <Divider spaceY="15px" />
          </div>
          <Flex direction="column" gap="30px">
            {faqs.map((faq, index) => {
              return (
                <Flex key={faq._id} direction="row" gap="8px">
                  <Typography>{index + 1}.</Typography>
                  <div style={{ width: '100%', position: 'relative' }}>
                    <Flex
                      inline
                      justifyContent="flex-end"
                      gap="20px"
                      style={{
                        position: 'absolute',
                        right: '0px',
                      }}
                    >
                      <Button
                        variant="nostyle"
                        onClick={() =>
                          navigate(
                            `${SETTING_ROUTE}/${VIEW_FAQS_ROUTE}/${faqCategoryId}/edit/${faq._id}`
                          )
                        }
                      >
                        <FaPen color="#233fff" size={12} />
                      </Button>
                      <Button
                        variant="nostyle"
                        onClick={() => {
                          setOpenModal(true);
                          setDeleteFaqId(faq._id);
                        }}
                      >
                        <FaTrash color="#ff3f3f" size={12} />
                      </Button>
                    </Flex>
                    <Flex direction="column" gap="0px">
                      <Typography style={{ paddingRight: '45px' }}>
                        {faq.title}
                      </Typography>
                      <ReadOnlyEditor content={faq.content} />
                    </Flex>
                  </div>
                </Flex>
              );
            })}
          </Flex>
        </ViewFaqStyled>
      </Setting>
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={() => deleteFaq(deleteFaqId)}
        desc="Are you sure want to delete single faq?"
      />
    </>
  );
};

export default ViewFaqs;
