import styled from 'styled-components';

export const FaqWrapperStyled = styled.div`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`;
export const FormContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & .label {
    margin-bottom: 7px;
    font-size: 16px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  & .btn-cancel {
    background-color: white;
    color: black;
    border: none;
  }
`;
