// Import necessary dependencies
import leadsConfig from '@shared/apiConfigs/leads';
import Input from '@shared/designComponents/Input';
import { humanReadableDate, requestAPI } from '@shared/utils';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SearchIcon from '../../assets/search.svg';
import { useRoot } from '../../context/RootProvider';
import Button from '../../designComponents/Button';
import Pagination from '../../designComponents/Pagination';
import Table, { TableDataType } from '../../designComponents/Table';
import Typography from '../../designComponents/Typography';
import useDebounce from '../../hooks/useDebounce';
type Props = {};

export type LeadType = {
  _id: string;
  cityId: string;
  regionId: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: number;
  addressOne: string;
  addressTwo: string;
  ber: string;
  mprn: string;
  newsletterOptIn: boolean;
  termsAndConditions: boolean;
  typeOfQuestions: string;
  createdAt: Date;
  updatedAt: Date;
  id: number;
  __v: number;
};

const Leads = (_props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [leads, setLeads] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);
  const [assignedLead, setAssignedLead] = useState([]);
  const { auth } = useRoot();
  console.log(assignedLead);

  const navigate = useNavigate();
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalLeads, setTotalLeads] = useState(0);

  const handleChange = (value: string) => {
    setSearch(value);
    navigate(`/leads/?search=${encodeURIComponent(value)}`, {
      replace: true,
    });
  };

  const tableData: TableDataType = {
    headings: [
      { key: 'id', label: 'Lead Id' },
      { key: 'fullName', label: 'Lead Name' },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'question',
        label: 'Where did you hear about us?',
      },
      {
        key: 'phone',
        label: 'Phone Number',
      },
      {
        key: 'submittedAt',
        label: 'Created At',
      },

      {
        key: 'actions',
        label: 'Actions',
      },
    ],
    body: leads,
  };

  const fetchLeads = async (searchTerm: string) => {
    try {
      if (auth?.role === 'SUPER-ADMIN' || auth?.role === 'ADMIN') {
        const response = await requestAPI(
          leadsConfig.getAllLeads({
            page: currentPage,
            limit: 10,
            isClient: false,
            search: searchTerm,
          })
        );
        const totalLeads = response.pagination.totalData;
        setTotalLeads(totalLeads);
        const data = response.data;
        const newData = data.map((lead: LeadType) => {
          return {
            id: lead._id,
            fullName: `${lead.firstName} ${lead.lastName}`,
            email: lead.email,
            question: lead.typeOfQuestions,
            phone: lead.mobileNumber,
            submittedAt: humanReadableDate(lead.createdAt),
            actions: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <Button
                  variant="cancel"
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${lead._id}`);
                  }}
                >
                  View
                </Button>
              </div>
            ),
          };
        });
        setLeads(newData);
        setIsLoading(false);
      } else {
        const data = await requestAPI(leadsConfig.getAssignedLead());
        const newData = data.map((lead: LeadType) => {
          return {
            id: lead._id,
            fullName: `${lead.firstName} ${lead.lastName}`,
            email: lead?.email,
            phone: lead.mobileNumber,
            submittedAt: humanReadableDate(lead.createdAt),
            updatedAt: humanReadableDate(lead.updatedAt),
            actions: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <Button
                  variant="cancel"
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${lead._id}`);
                  }}
                >
                  View
                </Button>
              </div>
            ),
          };
        });
        setLeads(newData);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAssignedLead = async () => {
    try {
      const data = await requestAPI(leadsConfig.getAssignedLead());
      setAssignedLead(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAssignedLead();
  }, []);

  useEffect(() => {
    fetchLeads(debouncedSearch);
  }, [debouncedSearch, currentPage]);

  const handlePagination = ({ page }: { page: number }) => {
    setCurrentPage(page);
  };

  return (
    <div
      style={{
        marginLeft: '20px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Typography style={{ fontWeight: '600', fontSize: '16px' }}>
        Leads
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: '500px',
        }}
      >
        <Input
          placeholder="Search Leads..."
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(ev.target.value);
          }}
          iconLeft={<img src={SearchIcon} alt="search icon" />}
          value={search}
        />
        {/* <Button>Action</Button> */}
      </div>
      <div>
        <Table
          tableData={tableData}
          isLoading={isLoading}
          onCheck={setSelectedLeads}
          checkedData={selectedLeads}
          hideSorting
        />
        <Pagination
          activePage={currentPage}
          totalCount={totalLeads}
          limit={10}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default Leads;
