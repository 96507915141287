import adminUsersConfig from '@shared/apiConfigs/adminUsers';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import queryString from 'query-string';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Mail from '../../../assets/Mail.svg';
import toastMessage from '../../../constants/toastMessage';
import AlertComponent from '../../../designComponents/Alert';
import Button from '../../../designComponents/Button';
import FormContainer from '../../../designComponents/FormContainer';
import Input from '../../../designComponents/Input';
import Typography from '../../../designComponents/Typography';
import { Container, LoginWrapper } from './style';

type Props = {};
const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required'),
});

const UpdatePassword = (_props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);

  const token: any = parsedQueryString.token;
  const email: any = parsedQueryString.email;
  const initialValues = {
    email: email,
    token: '',
    // confirmPassword: '',
    password: '',
  };

  const handleUpdatePassword = async (data: typeof initialValues) => {
    try {
      await requestAPI(
        adminUsersConfig.updatePassword({
          token: token ?? '',
          email: email ?? '',
          password: data.password,
          // confirmPassword: data.confirmPassword,
        })
      );
      setIsLoading(false);
      toast.success(toastMessage.passwordUpdated);
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };
  console.log(handleUpdatePassword);

  return (
    <LoginWrapper>
      <Container>
        <Typography
          variant="heading"
          style={{ textAlign: 'center', marginBottom: '20px' }}
        >
          Set Your Password
        </Typography>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleUpdatePassword}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors, handleSubmit, touched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className="form-wrapper">
                  <div>
                    <FormContainer label="Email*">
                      <Input
                        name="email"
                        value={values.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                        placeholder="Enter email"
                        disabled
                        iconRight={<img src={Mail} alt="mail icon" />}
                      />
                    </FormContainer>
                  </div>

                  <div>
                    <FormContainer
                      label="Password*"
                      error={touched.password ? errors.password : ''}
                    >
                      <Input
                        name="password"
                        value={values.password}
                        onChange={(e) =>
                          setFieldValue('password', e.target.value)
                        }
                        placeholder="Enter password"
                        type="password"
                      />
                    </FormContainer>
                  </div>
                  <div>
                    <FormContainer
                      label="Confirm Password*"
                      // error={
                      //   touched.confirmPassword ? errors.confirmPassword : ''
                      // }
                    >
                      <Input
                        name="confirmPassword"
                        onChange={(e) =>
                          setFieldValue('confirmPassword', e.target.value)
                        }
                        placeholder="Confirm Password"
                        type="password"
                      />
                    </FormContainer>
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    isLoading={isLoading}
                    style={{ marginBottom: '20px', width: '100%' }}
                  >
                    Update
                  </Button>

                  {errorMessage && (
                    <AlertComponent text={errorMessage} type="error" />
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </LoginWrapper>
  );
};

export default UpdatePassword;
