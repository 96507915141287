export const labels: { [key: string]: { [key: string]: string } } = {
  document: {
    name: 'Title',
    notes: 'Notes',
    documentFileName: 'Documnet Name',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    __v: 'Version',
    _id: 'Id',
  },
  stages: {
    name: 'Name',
    amount: 'Amount',
    clinetId: 'Client Id',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    status: 'Status',
    __v: 'Version',
    id: 'Id',
    clientId: 'Client Id',
    _id: '_Id',
  },
  paymentAggerment: {
    name: 'Name',
    amount: 'Amount',
    clinetId: 'Client Id',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    status: 'Status',
    __v: 'Version',
    _id: 'Id',
    id: 'Id',
    dueDate: 'Due Date',
    clientId: 'Client Id',
  },
  adminUsers: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    role: 'Role',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    __v: 'Version',
    _id: 'System Id',
    id: 'Id',
  },
  grantTable: {
    name: 'Name',
    homeType: 'Home Type',
    value: 'Value',
    slug: 'Slug',
    createdAt: 'Created At',
    _id: 'Id',
    id: 'Id',
    updatedAt: 'Updated At',
    __v: 'Version',
  },
  grantClientRelationShip: {
    _id: 'Id',
    clientId: 'Client Id',
    __v: 'Version',
    updatedAt: 'Updated At',
    status: 'Status',
    reliefAmount: 'Relief Amount',
    createdAt: 'Created At',
    buildingType: 'Building Type',
    adminUserId: 'Admin Id',
  },
  faqs: {
    title: 'Name',
    content: 'Content',
    slug: 'Slug',
    createdAt: 'Created At',
    _id: 'System Id',
    id: ' Id',
    updatedAt: 'Updated At',
    __v: 'Version',
  },
  delayedJob: {
    _id: 'ID',
    __v: 'Version',
    updatedAt: 'Updated At',
    queue: 'Queue',
    priority: 'Priority',
    lockedBy: 'Locked By',
    lastError: 'Last Error',
    id: 'Id',
    handler: 'Handler',
    createdAt: 'Created At',
    attempts: 'Attempts',
  },
};
