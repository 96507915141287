import styled from 'styled-components';

export const DocumentContainerStyled = styled.div`
  max-width: 1000px;
  margin: 30px auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
`;

export const InfoRowStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;

  & .label {
    width: 150px;
    font-size: 16px;
  }
`;

export const DocumentWrapperStyled = styled.div``;
