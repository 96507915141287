import grantsConfig from '@shared/apiConfigs/grants';
import { requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import GrantsCard from '../../../../components/Clients/GrantsCard';
import Button from '../../../../designComponents/Button';
import Modal from '../../../../designComponents/Modal';
import AddGrants from './AddGrants';

const ClientGrants = () => {
  const [showModal, setShowModal] = useState(false);
  const [grants, setGrants] = useState([]);
  const { clientId } = useParams();

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getClientAllGrants = async () => {
    try {
      const data =
        clientId &&
        (await requestAPI(grantsConfig.getGrantsByLeadId(clientId)));
      setGrants(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getClientAllGrants();
  }, []);

  console.log('grant', grants);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="primary" onClick={openModal}>
          Add New Grant
        </Button>
      </div>

      {grants?.map((grant: any) => {
        return (
          <GrantsCard
            key={grant._id}
            title={grant?.grantNameId?.name}
            date={grant.createdAt}
            buildType={grant?.buildingTypeId?.buildingName}
            attachments={grant?.document}
            amount={grant?.reliefAmount}
            status={grant?.grantStatus}
          />
        );
      })}

      <Modal open={showModal} onClose={closeModal}>
        <AddGrants
          setShowModal={setShowModal}
          fetchGrants={getClientAllGrants}
        />
      </Modal>
    </div>
  );
};

export default ClientGrants;
