import styled from 'styled-components';

export const WrapperStyled = styled.div`
  & .title {
    background-color: #d9e6ff;
    padding: 10px;
    border-radius: 5px;
  }

  & .title-text {
    color: blue;
    font-size: 16px;
  }
`;

export const AccordianConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const InfoRowStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  & .label {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
  }
`;
