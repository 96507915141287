import grantTableRowsConfig from '@shared/apiConfigs/grantTableRows';
import { requestAPI } from '@shared/utils';
import { ErrorMessage, Form, Formik, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomEditor from '../../../../components/Editor/Editor';
import BreadCrumb from '../../../../designComponents/BreadCrumb';
import Button from '../../../../designComponents/Button';
import Input from '../../../../designComponents/Input';
import Typography from '../../../../designComponents/Typography';
import {
  ADD_GRANT_TABLE_ROW_ROUTE,
  SETTING_ROUTE,
  VIEW_GRANT_TABLE_ROW_ROUTE,
} from '../../../../routes/routePaths';
import Setting from '../..';
import {
  ButtonContainer,
  FormContainer,
  TableRowsWrapperStyled,
} from './style';
interface GrantDataType {
  createdAt: string;
  homeType: string;
  name: string;
  updatedAt: string;
  value: string;
  _id: string;
}
const CreateTableRows = ({
  grantId,
  setShowModal,
}: {
  grantId?: string;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [editorState, setEditorState] = useState('');
  //   const [homeType, setHomeType] = useState('');
  const navigate = useNavigate();
  const [grantData, setGrantData] = useState<GrantDataType>();
  const [loading, setLoading] = useState(false);
  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Grant Table Rows',
      path: `${SETTING_ROUTE}/${VIEW_GRANT_TABLE_ROW_ROUTE}`,
    },
    {
      label: 'Add Grant Table Rows',
      path: `${SETTING_ROUTE}/${VIEW_GRANT_TABLE_ROW_ROUTE}/${ADD_GRANT_TABLE_ROW_ROUTE}`,
    },
  ];

  useEffect(() => {
    const getGrantsById = async () => {
      try {
        const grant =
          grantId &&
          (await requestAPI(
            grantTableRowsConfig.getGrantTableRowsById(grantId)
          ));
        setGrantData(grant);
        setEditorState(grant.value);
      } catch (error) {
        console.error(error);
      }
    };
    getGrantsById();
  }, [grantId]);

  return (
    <>
      {grantId ? (
        <Formik
          initialValues={{
            name: grantData?.name,
            value: grantData?.value,
            homeType: grantData?.homeType,
          }}
          enableReinitialize
          validate={(values: FormikValues) => {
            const errors: Partial<FormikValues> = {};
            if (!values.name) {
              errors.name = 'Name Required';
            }
            if (!values.value) {
              errors.value = 'Value Required!!!';
            }
            if (!values.homeType) {
              errors.homeType = 'Home Type Required!!!';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setLoading(true);
            try {
              if (
                typeof values.name !== 'undefined' &&
                typeof values.homeType !== 'undefined'
              ) {
                await requestAPI(
                  grantTableRowsConfig.updateGrantTable(grantId, {
                    name: values.name,
                    value: editorState,
                    homeType: values.homeType,
                  })
                );
                setLoading(false);
                setEditorState('');
                toast.success('Updated sucessfully');
                setShowModal && setShowModal(false);
              }
            } catch (err) {
              console.error('Error', err);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <TableRowsWrapperStyled>
                <Typography style={{ fontSize: '20px', marginBottom: '10px' }}>
                  Grant Table Rows
                </Typography>
                {/* <hr /> */}
                <FormContainer>
                  <div>
                    <Typography className="label">Name*</Typography>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                    />
                    {/* <Field type="text" name="name" /> */}
                    <ErrorMessage name="name" component="div" />
                  </div>
                  <div>
                    <Typography className="label">Home Type</Typography>
                    {/* <Field type="text" name="homeType" /> */}
                    <Input
                      name="homeType"
                      value={values.homeType}
                      onChange={(e) =>
                        setFieldValue('homeType', e.target.value)
                      }
                    />
                    <ErrorMessage name="homeType" component="div" />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography className="label">value</Typography>
                    <CustomEditor
                      initialContent={editorState}
                      onEditorChange={(value: string) => {
                        setEditorState(value);
                        setFieldValue('value', value);
                      }}
                      onFilesChange={() => {}}
                    />
                    <ErrorMessage name="value" component="div" />
                  </div>

                  <ButtonContainer>
                    <Button
                      type="submit"
                      isLoading={loading}

                      //   disabled={isSubmitting || !values.name || !values.value}
                    >
                      Update Grant table row
                    </Button>
                    <Button
                      style={{ margin: 12 }}
                      className="btn-cancel"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </ButtonContainer>
                </FormContainer>
              </TableRowsWrapperStyled>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <BreadCrumb breadCrumb={breadcrumbs} />

          <Setting>
            <Formik
              initialValues={{ name: '', value: '', homeType: '' }}
              validate={(values: FormikValues) => {
                const errors: Partial<FormikValues> = {};
                if (!values.name) {
                  errors.name = 'Name Required';
                }
                if (!values.value) {
                  errors.value = 'Value Required!!!';
                }
                if (!values.homeType) {
                  errors.homeType = 'Home Type Required!!!';
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setLoading(true);
                try {
                  await requestAPI(
                    grantTableRowsConfig.createGrantTableRows({
                      name: values.name,
                      value: editorState,
                      homeType: values.homeType,
                    })
                  );
                  setLoading(false);
                  setEditorState('');
                  navigate(-1);
                } catch (err) {
                  console.log('Error', err);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <TableRowsWrapperStyled>
                    <Typography
                      style={{ fontSize: '20px', marginBottom: '10px' }}
                    >
                      Grant Table Rows
                    </Typography>
                    {/* <hr /> */}
                    <FormContainer>
                      <div>
                        <Typography className="label">Name*</Typography>
                        <Input
                          name="name"
                          value={values.name}
                          onChange={(e) =>
                            setFieldValue('name', e.target.value)
                          }
                        />
                        {/* <Field type="text" name="name" /> */}
                        <ErrorMessage name="name" component="div" />
                      </div>
                      <div>
                        <Typography className="label">Home Type</Typography>
                        {/* <Field type="text" name="homeType" /> */}
                        <Input
                          name="homeType"
                          value={values.homeType}
                          onChange={(e) =>
                            setFieldValue('homeType', e.target.value)
                          }
                        />
                        <ErrorMessage name="homeType" component="div" />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Typography className="label">value</Typography>
                        <CustomEditor
                          initialContent={editorState}
                          onEditorChange={(value: string) => {
                            setEditorState(value);
                            setFieldValue('value', value);
                          }}
                          onFilesChange={() => {}}
                        />
                        <ErrorMessage name="value" component="div" />
                      </div>

                      <ButtonContainer>
                        <Button
                          type="submit"
                          isLoading={loading}

                          //   disabled={isSubmitting || !values.name || !values.value}
                        >
                          Create Grant table row
                        </Button>
                        <Button
                          style={{ margin: 12 }}
                          className="btn-cancel"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      </ButtonContainer>
                    </FormContainer>
                  </TableRowsWrapperStyled>
                </Form>
              )}
            </Formik>
          </Setting>
        </>
      )}
    </>
  );
};

export default CreateTableRows;
