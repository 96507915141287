import grantConfig from '@shared/apiConfigs/grant';
import { humanReadableDate, requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteModal from '../../../components/DeleteModal/deleteModal';
import toastMessage from '../../../constants/toastMessage';
// import { toast } from 'react-toastify';
// import grantsConfig from '@shared/apiConfigs/grants';
import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Flex from '../../../designComponents/Flex';
import Pagination from '../../../designComponents/Pagination';
import Table, { TableDataType } from '../../../designComponents/Table';
import { SETTING_ROUTE, VIEW_GRANTS_ROUTE } from '../../../routes/routePaths';
import Setting from '..';

type GrantType = {
  _id: string;
  __v?: number;
  name: string;
  updatedAt: Date;
  createdAt: Date;
};

type Props = {};

const Grants = (_props: Props) => {
  const [loading, setLoading] = useState(false);
  const [loadingButtonId, setLoadingButtonId] = useState('');
  const [grants, setGrants] = useState<GrantType[]>([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalGrants, setTotalGrants] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [deleteGrantId, setDeleteGrantId] = useState<string>('');

  const deleteHandler = async (grantId: string) => {
    setLoadingButtonId(grantId);
    setLoading(true);
    try {
      await requestAPI(grantConfig.deleteGrant(grantId));
      setLoading(false);
      setOpenModal(false);

      setGrants((prev) => prev.filter((x) => x._id !== grantId));
      toast.success(toastMessage.grantDeleted);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getAllGrants = async () => {
    try {
      const data = await requestAPI(grantConfig.getAllGrants(currentPage, 10));
      const totalGrants = data.pagination.totalData;
      setTotalGrants(totalGrants);
      setGrants(data.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAllGrants();
  }, [currentPage]);

  const handlePagination = ({ page, limit }: any) => {
    setCurrentPage(page);
    console.log(limit);
  };
  const tableData: TableDataType = {
    headings: [
      { key: 'grant', label: 'Grant' },

      {
        key: 'createdAt',
        label: 'Created At',
      },

      {
        key: 'actions',
        label: 'Action',
      },
    ],
    body: grants.map((grant) => ({
      id: grant._id,
      grant: grant.name,
      createdAt: humanReadableDate(grant.createdAt),

      actions: (
        <div
          style={{ display: 'flex', gap: '7px', justifyContent: 'flex-end' }}
        >
          {/* <Button
            variant="white"
            onClick={() =>
              navigate(`/settings/buildings/edit-building/${grant._id}`)
            }
          >
            Edit
          </Button> */}
          <Button
            isLoading={loading && loadingButtonId === grant._id}
            variant="danger"
            outline
            onClick={(e) => {
              e.stopPropagation();
              setDeleteGrantId(grant._id);
              setOpenModal(true);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    })),
  };

  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Grants',
      path: `${SETTING_ROUTE}/${VIEW_GRANTS_ROUTE}`,
    },
  ];
  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <Flex
          justifyContent="flex-end"
          style={{
            marginBottom: 16,
          }}
        >
          <Button variant="primary" onClick={() => navigate('new-grant')}>
            Add Grant
          </Button>
        </Flex>
        <Table tableData={tableData} showCheckBox={false} hideSorting={true} />
        <Pagination
          activePage={currentPage}
          totalCount={totalGrants}
          limit={10}
          handlePagination={handlePagination}
        />
      </Setting>
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        desc="Are you sure want to delete Grants?"
        onConfirm={() => deleteHandler(deleteGrantId)}
      />
    </>
  );
};

export default Grants;
