/**
 * 1. use kebab case for route names ie: user-profile
 * 2. use descriptive names
 * 3. avoid special characters
 * 4. use route constants
 * 5. Avoid Overusing Abbreviations: like /user to /usr
 */

export const TEST_ROUTE = '/test';
export const HOME_ROUTE = '/';

export const REGISTER_ROUTE = '/register';
export const DASHBOARD_ROUTE = '/panel';
export const LEAD_ROUTE = '/leads';
export const EDIT_LEAD_ROUTE = ':leadId';
export const CLIENT_ROUTE = '/clients';
export const VIEW_CLIENT_ROUTE = ':clientId';
export const ADD_CLIENT_ROUTE = 'add';
export const VIEW_CLIENT_GRANT_ROUTE = ':title';
export const SETTING_ROUTE = '/settings';
export const SETTING_PARAMS_ROUTE = ':settingsParams';
export const SETTING_VIEW_DETAILS_ROUTE = ':id';
export const SETTING_EDIT_DETAILS_ROUTE = 'edit';
export const ONBOARDING_ROUTE = '/onboarding';
export const FORGOT_ROUTE = '/forget-password';
export const NOT_FOUND_ROUTE = '*';
export const LOG_OUT_ROUTE = '/logout';
export const VERIFICATION_ROUTE = '/verification-code';
export const PROFILE_ROUTE = 'profile';
export const MY_JOBS_ROUTE = '/my-jobs';
export const VIEW_MY_NOTIFICATIONS = '/notifications';
export const UPDATE_PASSWORD_ROUTE = 'admin/setPassword';

//Routing inside the Settings

export const STAGE_ROUTE = 'stages';
export const DOCUMENT_ROUTE = 'document';
export const ADD_DOCUMENT_ROUTE = 'add-document';
export const EDIT_DOCUMENT_ROUTE = `edit-document/:id`;

export const VIEW_FAQS_ROUTE = 'faqs';
export const ADD_FAQ_ROUTE = 'add';
export const EDIT_SINGLE_FAQ_ROUTE = ':faqCatId/edit/:faqId';
export const FAQ_DETAILS_ROUTE = ':faqId';
export const VIEW_GRANTS_ROUTE = 'grants';
export const ADD_CLIENT_GRANT_RELATIONSHIP = 'new-grant';
export const VIEW_GRANT_TABLE_ROW_ROUTE = 'grant-table-rows';
export const ADD_GRANT_TABLE_ROW_ROUTE = 'new';
export const VIEW_ADMNN_USER_ROUTE = 'admin-users';
export const EDIT_ADMIN_USER_ROUTE = 'edit/:id';
export const ADD_ADMIN_ROUTE = 'add';
export const VIEW_DELAY_JOB_RECORD_ROUTE = 'delayed-job-records';
export const VIEW_PAYMENT_AGREEMENTS_ROUTE = 'payment-agreement';
export const STAGE_CREATE_WORK_ROUTE = 'create-work';
export const EDIT_STAGE_WORK_ROUTE = `edit-work/:id`;

export const COUNTRY_ROUTE = 'countries';
export const ADD_COUNTRY_ROUTE = 'add-country';
export const CITY_ROUTE = 'cities';
export const ADD_CITY_ROUTE = 'add-city';
export const LEAD_PRICE_ROUTE = 'lead-price';
export const ADD_LEAD_PRICE_ROUTE = 'add-new';

export const BUILDING_ROUTE = 'buildings';
export const ADD_BUILDINGTYPE_ROUTE = 'new-buildingType';
export const EDIT_BUILDINGTYPE_ROUTE = 'edit-building/:id';

export const RELEIF_AMOUNT_ROUTE = 'relief-amount';
export const ADD_RELIEF_AMOUNT_ROUTE = 'create-amount';
