// import EditDetails from '../components/EditDetails';
import React from 'react';

import ShowDetails from '../components/ShowDetails';
import ErrorBoundary from '../designComponents/ErrorBoundary';
import { NoLayout, PrivateLayout } from '../layout/Layout';
import LandingPageLayout from '../layout/Layout/PublicLayout';
import Login from '../pages/Auth/Login';
import Register from '../pages/Auth/Register';
import UpdatePassword from '../pages/Auth/UpdatePassword';
import Clients from '../pages/Client';
import AddClient from '../pages/Client/AddClient';
import ViewDetailClient from '../pages/Client/ViewDetail';
import Dashboard from '../pages/Dashboard';
import Leads from '../pages/Leads';
import ViewDetails from '../pages/Leads/ViewDetail';
// import EditLead from '../pages/Leads/ViewDetail/EditLead';
import Setting from '../pages/Settings';
import AdminUsers from '../pages/Settings/AdminUsers';
import AddAdmin from '../pages/Settings/AdminUsers/AddAdmin';
import EditAdmin from '../pages/Settings/AdminUsers/EditAdmin';
import BuildingType from '../pages/Settings/BuildingType';
import AddBuilding from '../pages/Settings/BuildingType/AddBuilding';
import Cities from '../pages/Settings/Cities';
import AddCity from '../pages/Settings/Cities/AddCity';
import DelayedJobs from '../pages/Settings/DelayedJobs';
import Documents from '../pages/Settings/Documents';
import AddDocument from '../pages/Settings/Documents/AddDocument';
import EditDocument from '../pages/Settings/Documents/EditDocument';
import Faqs from '../pages/Settings/Faqs';
import AddFaq from '../pages/Settings/Faqs/AddFaq';
import ViewFaqs from '../pages/Settings/Faqs/ViewFaq';
import Grants from '../pages/Settings/Grants';
import NewGrant from '../pages/Settings/Grants/NewClientGRantRelationship';
import GrantTableRows from '../pages/Settings/GrantTableRows';
import CreateTableRows from '../pages/Settings/GrantTableRows/CreateTableRows';
import LeadPrice from '../pages/Settings/LeadPrice';
import AddLeadPrice from '../pages/Settings/LeadPrice/AddLeadPrice';
import PaymentAgreement from '../pages/Settings/PaymentAgreement';
import Profile from '../pages/Settings/Profile';
import ReliefAmount from '../pages/Settings/ReliefAmount';
import CreateReliefAmount from '../pages/Settings/ReliefAmount/CraeteReliefAmount';
import Stages from '../pages/Settings/Stages';
import CreateWork from '../pages/Settings/Stages/CreateWork';
import EditWork from '../pages/Settings/Stages/EditWork';
import {
  ADD_ADMIN_ROUTE,
  ADD_BUILDINGTYPE_ROUTE,
  ADD_CITY_ROUTE,
  ADD_CLIENT_GRANT_RELATIONSHIP,
  ADD_CLIENT_ROUTE,
  ADD_DOCUMENT_ROUTE,
  ADD_FAQ_ROUTE,
  ADD_GRANT_TABLE_ROW_ROUTE,
  ADD_LEAD_PRICE_ROUTE,
  ADD_RELIEF_AMOUNT_ROUTE,
  BUILDING_ROUTE,
  CITY_ROUTE,
  CLIENT_ROUTE,
  DASHBOARD_ROUTE,
  DOCUMENT_ROUTE,
  EDIT_ADMIN_USER_ROUTE,
  EDIT_BUILDINGTYPE_ROUTE,
  EDIT_DOCUMENT_ROUTE,
  EDIT_LEAD_ROUTE,
  EDIT_SINGLE_FAQ_ROUTE,
  EDIT_STAGE_WORK_ROUTE,
  FAQ_DETAILS_ROUTE,
  HOME_ROUTE,
  LEAD_PRICE_ROUTE,
  LEAD_ROUTE,
  NOT_FOUND_ROUTE,
  PROFILE_ROUTE,
  REGISTER_ROUTE,
  RELEIF_AMOUNT_ROUTE,
  // SETTING_EDIT_DETAILS_ROUTE,
  SETTING_ROUTE,
  SETTING_VIEW_DETAILS_ROUTE,
  STAGE_CREATE_WORK_ROUTE,
  STAGE_ROUTE,
  UPDATE_PASSWORD_ROUTE,
  VIEW_ADMNN_USER_ROUTE,
  // VIEW_CLIENT_GRANT_ROUTE,
  VIEW_CLIENT_ROUTE,
  VIEW_DELAY_JOB_RECORD_ROUTE,
  VIEW_FAQS_ROUTE,
  VIEW_GRANT_TABLE_ROW_ROUTE,
  VIEW_GRANTS_ROUTE,
  VIEW_PAYMENT_AGREEMENTS_ROUTE,
} from './routePaths';

const routes: IRoutes[] = [
  {
    id: 1,
    path: NOT_FOUND_ROUTE,
    component: ErrorBoundary,
    layout: LandingPageLayout,
  },
  {
    id: 100,
    path: REGISTER_ROUTE,
    component: Register,
    layout: LandingPageLayout,
  },
  {
    id: 101,
    path: DASHBOARD_ROUTE,
    component: Dashboard,
    layout: PrivateLayout,
    isProtected: true,
  },
  {
    id: 102,
    path: HOME_ROUTE,
    component: Login,
    layout: NoLayout,
  },
  {
    id: 1022,
    path: UPDATE_PASSWORD_ROUTE,
    component: UpdatePassword,
    layout: NoLayout,
  },
  {
    id: 103,
    path: LEAD_ROUTE,
    component: Leads,
    layout: PrivateLayout,
    children: [
      {
        id: 1031,
        path: EDIT_LEAD_ROUTE,
        component: ViewDetails,
      },
    ],
  },
  {
    id: 103,
    path: CLIENT_ROUTE,
    component: Clients,
    layout: PrivateLayout,
    children: [
      {
        id: 1032,
        path: VIEW_CLIENT_ROUTE,
        component: ViewDetailClient,
      },
      {
        id: 1033,
        path: ADD_CLIENT_ROUTE,
        component: AddClient,
      },
    ],
  },
  {
    id: 103,
    path: SETTING_ROUTE,
    component: Setting,
    layout: PrivateLayout,
    children: [
      {
        id: 300,
        path: PROFILE_ROUTE,
        component: Profile,
      },
      {
        id: 1031,
        path: DOCUMENT_ROUTE,
        component: Documents,
        children: [
          {
            id: 1032,
            path: SETTING_VIEW_DETAILS_ROUTE,
            component: ShowDetails,
          },
          {
            id: 1032,
            path: ADD_DOCUMENT_ROUTE,
            component: AddDocument,
          },
          {
            id: 1032,
            path: EDIT_DOCUMENT_ROUTE,
            component: EditDocument,
          },
        ],
      },
      {
        id: 1031,
        path: STAGE_ROUTE,
        component: Stages,
        children: [
          {
            id: 1032,
            path: SETTING_VIEW_DETAILS_ROUTE,
            component: ShowDetails,
          },
          {
            id: 1033,
            path: STAGE_CREATE_WORK_ROUTE,
            component: CreateWork,
          },
          {
            id: 1034,
            path: EDIT_STAGE_WORK_ROUTE,
            component: EditWork,
          },
        ],
      },
      {
        id: 408,
        path: VIEW_FAQS_ROUTE,
        component: Faqs,
        children: [
          {
            id: 4081,
            path: FAQ_DETAILS_ROUTE,
            component: ViewFaqs,
          },
          {
            id: 4082,
            path: ADD_FAQ_ROUTE,
            component: AddFaq,
          },
          {
            id: 4084,
            path: EDIT_SINGLE_FAQ_ROUTE,
            component: AddFaq,
          },
        ],
      },
      {
        id: 410,
        path: VIEW_GRANT_TABLE_ROW_ROUTE,
        component: GrantTableRows,
        children: [
          {
            id: 4101,
            path: SETTING_VIEW_DETAILS_ROUTE,
            component: ShowDetails,
          },
          {
            id: 4102,
            path: ADD_GRANT_TABLE_ROW_ROUTE,
            component: CreateTableRows,
          },
        ],
      },
      {
        id: 412,
        path: VIEW_ADMNN_USER_ROUTE,
        component: AdminUsers,
        children: [
          {
            id: 413,
            path: SETTING_VIEW_DETAILS_ROUTE,
            component: ShowDetails,
          },
          {
            id: 4130,
            path: ADD_ADMIN_ROUTE,
            component: AddAdmin,
          },
          {
            id: 4130,
            path: EDIT_ADMIN_USER_ROUTE,
            component: EditAdmin,
          },
        ],
      },
      {
        id: 414,
        path: VIEW_DELAY_JOB_RECORD_ROUTE,
        component: DelayedJobs,
        children: [
          {
            id: 415,
            path: SETTING_VIEW_DETAILS_ROUTE,
            component: ShowDetails,
          },
        ],
      },
      {
        id: 421,
        path: VIEW_PAYMENT_AGREEMENTS_ROUTE,
        component: PaymentAgreement,
        children: [
          {
            id: 422,
            path: SETTING_VIEW_DETAILS_ROUTE,
            component: ShowDetails,
          },
        ],
      },
      {
        id: 414,
        path: VIEW_GRANTS_ROUTE,
        component: Grants,
        children: [
          {
            id: 432,
            path: SETTING_VIEW_DETAILS_ROUTE,
            component: ShowDetails,
          },
          {
            id: 433,
            path: ADD_CLIENT_GRANT_RELATIONSHIP,
            component: NewGrant,
          },
        ],
      },

      {
        id: 5000,
        path: CITY_ROUTE,
        component: Cities,
        children: [
          {
            component: AddCity,
            id: 50001,
            path: ADD_CITY_ROUTE,
          },
        ],
      },
      {
        id: 6000,
        path: LEAD_PRICE_ROUTE,
        component: LeadPrice,
        children: [
          {
            id: 60001,
            component: AddLeadPrice,
            path: ADD_LEAD_PRICE_ROUTE,
          },
        ],
      },
      {
        id: 7000,
        path: BUILDING_ROUTE,
        component: BuildingType,

        children: [
          {
            id: 70001,
            component: AddBuilding,
            path: ADD_BUILDINGTYPE_ROUTE,
          },
          {
            id: 70002,
            component: AddBuilding,
            path: EDIT_BUILDINGTYPE_ROUTE,
          },
        ],
      },
      {
        id: 8000,
        path: RELEIF_AMOUNT_ROUTE,
        component: ReliefAmount,

        children: [
          {
            id: 70001,
            component: CreateReliefAmount,
            path: ADD_RELIEF_AMOUNT_ROUTE,
          },
          {
            id: 70002,
            component: AddBuilding,
            path: EDIT_BUILDINGTYPE_ROUTE,
          },
        ],
      },
    ],
  },
];
export default routes;

export interface IRoutes {
  id: number;
  path: string;
  component: React.FC;
  layout: React.FC<React.PropsWithChildren<{ extraInfo?: any }>>;

  isProtected?: boolean;
  redirectToOnAuth?: string;
  role?: string[];
  restrictTo?: string[];
  allowTo?: string[];
  children?: Array<IChildren>;
  extraInfo?: any;
}

export interface IChildren {
  id: number;
  path: string;
  component: React.FC;
  children?: Array<IChildren>;
}
