import 'react-quill/dist/quill.bubble.css';
import './editor.css';

import { CSSProperties } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import styled from 'styled-components';

type Props = { content: any; containerStyle?: CSSProperties } & ReactQuillProps;

const ReadOnlyEditor = ({ content, containerStyle, ...restProps }: Props) => {
  return (
    <EditorWrapperStyled style={containerStyle}>
      <ReactQuill readOnly value={content} theme="bubble" {...restProps} />
    </EditorWrapperStyled>
  );
};

export default ReadOnlyEditor;

const EditorWrapperStyled = styled.div`
  & .ql-editor {
    font-size: 14px;
    font-family: 'Roboto';
    padding: 5px 0px;
    background: inherit;
    & iframe {
      padding: 5px 0px;
      height: 400px;
    }
  }
`;
