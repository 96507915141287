import adminUsersConfig from '@shared/apiConfigs/adminUsers';
import theme from '@shared/theme';
import { humanReadableDate, requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteModal from '../../../components/DeleteModal/deleteModal';
import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Pagination from '../../../designComponents/Pagination';
import Table, { TableDataType } from '../../../designComponents/Table';
import {
  SETTING_ROUTE,
  VIEW_ADMNN_USER_ROUTE,
} from '../../../routes/routePaths';
import Setting from '..';
import { Wrapper } from '../Documents/style';

type UserType = {
  _id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
};

type Props = {};

type CountType = {
  SUPERADMIN: number;
  ADMIN: number;
  CUSTOMERSERVICEASSISTANT: number;
  BERADVISOR: number;
};

const AdminUsers = (_props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState('All');
  const [count, setCount] = useState<CountType>({
    SUPERADMIN: 0,
    ADMIN: 0,
    CUSTOMERSERVICEASSISTANT: 0,
    BERADVISOR: 0,
  });
  const [totalCount, setTotalCount] = useState(0);

  const [role, setRole] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState<string>('');

  const handleButtonClick = (buttonLabel: string, userRole: string) => {
    setActiveButton(buttonLabel);
    setRole(userRole);
    setCurrentPage(1);
  };

  const buttons = {
    All: '',
    'Super Admin': 'SUPER-ADMIN',
    'Admin Users': 'ADMIN',
    'Customer Service': 'CUSTOMER-SERVICE-ASSISTANT',
    'Ber Advisor': 'BERADVISOR',
  };

  const deleteHandler = async (userId: string) => {
    try {
      await requestAPI(adminUsersConfig.deleteAdminUserById(userId));
      fetchUsers();
      setOpenModal(false);
      toast.success('Admin User deleted successfully');
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  const tableData: TableDataType = {
    headings: [
      { key: 'id', label: 'Id' },
      { key: 'email', label: 'Email' },
      { key: 'role', label: 'Role' },
      {
        key: 'username',
        label: 'Username',
      },
      {
        key: 'createdAt',
        label: 'Created At',
      },
      {
        key: 'updatedAt',
        label: 'Updated At',
      },
      {
        key: 'actions',
        label: 'Actions',
      },
    ],
    body: users.map((user: UserType) => ({
      id: user._id,
      email: user.email,
      role: user.role,
      username: user?.firstName + ' ' + user?.lastName,
      createdAt: humanReadableDate(user.createdAt),
      updatedAt: humanReadableDate(user.updatedAt),
      actions: (
        <div
          style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}
        >
          {/* <Button
            style={{
              margin: '0px 8px',
              background: 'grey',
              border: 0,
              borderRadius: 3,
              fontSize: 12,
            }}
            onClick={() => {
              navigate(user._id);
            }}
          >
            View
          </Button> */}
          {/* <Button
            variant="update"
            outline
            onClick={() => {
              navigate(`/settings/admin-users/edit/${user._id}`);
            }}
          >
            Edit
          </Button> */}
          <Button
            variant="danger"
            outline
            onClick={() => {
              setDeleteUserId(user._id);
              setOpenModal(true);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    })),
  };

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const data = await requestAPI(
        adminUsersConfig.getAllAdminUsers(currentPage, limit, role)
      );
      setCount(data.counts);
      setTotalCount(data.counts.usersTotals);
      setUsers(data.data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage, limit, role]);

  const handlePagination = ({ page, limit }: any) => {
    setCurrentPage(page);
    setLimit(limit);
  };

  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'View Admin Users',
      path: `${SETTING_ROUTE}/${VIEW_ADMNN_USER_ROUTE}`,
    },
  ];
  console.log(role);

  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <Wrapper>
          <div className="content">
            {Object.entries(buttons).map(([label, value]) => {
              let countNumber = count[value as keyof CountType];

              if (value === 'CUSTOMER-SERVICE-ASSISTANT') {
                countNumber = count['CUSTOMERSERVICEASSISTANT'];
              }
              if (value === 'SUPER-ADMIN') {
                countNumber = count['SUPERADMIN'];
              }

              return (
                <Button
                  key={label}
                  onClick={() => handleButtonClick(label, value)}
                  style={{
                    borderRadius: 3,
                    background:
                      activeButton === label ? theme.colors.primary : 'white',
                    color: activeButton === label ? 'white' : 'black',
                  }}
                  className="filter-btn"
                >
                  {label}
                  {label === 'All' && ` (${totalCount || 0}) `}
                  {label !== 'All' && ` (${countNumber})`}
                </Button>
              );
            })}

            <Button
              variant="primary"
              onClick={() => navigate('/settings/admin-users/add')}
              style={{
                marginLeft: 'auto',
              }}
            >
              New Admin User
            </Button>
          </div>
          <Table tableData={tableData} isLoading={isLoading} hideSorting />
          <Pagination
            activePage={currentPage}
            totalCount={
              role === 'CUSTOMER-SERVICE-ASSISTANT'
                ? count['CUSTOMERSERVICEASSISTANT']
                : role === 'SUPER-ADMIN'
                  ? count['SUPERADMIN']
                  : role === 'BERADVISOR'
                    ? count['BERADVISOR']
                    : role === 'ADMIN'
                      ? count['ADMIN']
                      : totalCount
            }
            limit={limit}
            handlePagination={handlePagination}
          />
        </Wrapper>
      </Setting>
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        desc="Are you sure want to delete this user?"
        onConfirm={() => deleteHandler(deleteUserId)}
      />
    </>
  );
};

export default AdminUsers;
