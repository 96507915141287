import { flexCenter } from '@shared/style/reusableStyle';
import React from 'react';
import styled from 'styled-components';

import Typography from '../Typography';

const DividerWrapper = styled.div<{
  $orientation: string;
  $bgColor?: string;
  $spaceX: string;
  $spaceY: string;
}>`
  width: ${(props) => (props.$orientation === 'vertical' ? '1px' : '100%')};
  height: ${(props) => (props.$orientation === 'horizontal' ? '1px' : 'auto')};
  max-height: 100%;
  background-color: ${(props) =>
    props.$bgColor || props.theme?.colors?.disableText};
  margin: ${(props) =>
    props.$orientation === 'horizontal'
      ? ` ${props.$spaceY} 0px`
      : `0px ${props.$spaceX}`};

  ${flexCenter(0)}
  & .divider-text {
    text-align: center;
    background-color: #fcfcfc;
    padding: 0px 5px;
  }
`;

const Divider = ({
  orientation = 'horizontal',
  style,
  bgColor,
  spaceX = '18px',
  spaceY = '18px',
  text,
  textStyle,
}: DividerProps) => {
  return (
    <DividerWrapper
      $orientation={orientation}
      style={style}
      $bgColor={bgColor}
      $spaceX={spaceX}
      $spaceY={spaceY}
    >
      {text && (
        <Typography style={textStyle} className="divider-text">
          {text}
        </Typography>
      )}
    </DividerWrapper>
  );
};

export default Divider;

interface DividerProps {
  orientation?: 'vertical' | 'horizontal';
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  bgColor?: string;
  spaceX?: string;
  spaceY?: string;
  text?: string;
}
