import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin: 20px 30px;

  gap: 30px;

  & .setting-nav {
    width: 250px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 7px 0px;
    padding: 20px;
    flex-shrink: 0;
  }
  & .heading {
    margin-bottom: 1rem;
    font-weight: 600;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  & .setting-content {
    flex: 1;
  }
`;

export const SidebarItem = styled.div<{ $active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  gap: 18px;
  padding: 5px 10px;
  margin: 2.5px 5px;
  border-radius: 4px;
  height: 40px;
  background-color: ${({ $active, theme }) =>
    $active ? theme.colors.primary : 'transparent'};
  color: ${({ $active, theme }) =>
    $active ? theme.colors.white : theme.colors.black};
  transition: all 0.1s ease;
  & svg.icon {
    height: 24px;
    width: 24px;
    margin: 0 !important;
    flex-shrink: 0;
  }

  & .label {
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    background-color: ${({ theme, $active }) =>
      $active ? theme.colors.primary : theme.colors.ad_disableButtonText};

    & svg.icon {
      height: 24px;
      width: 24px;
      flex-shrink: 0;
      color: ${({ $active, theme }) =>
        $active ? theme.colors.white : theme.colors.black};
      transition: all 0.1s ease;
    }
  }
`;
