// import { TiInfo } from 'react-icons/ti';

import { useState } from 'react';

import Button from '../../designComponents/Button';
import Modal from '../../designComponents/Modal';
import Typography from '../../designComponents/Typography';

interface ModalPropsType {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  warning?: string;
  desc: string;
}

const DeleteModal = ({
  open,
  onClose,

  desc,
  onConfirm,
}: ModalPropsType) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '20px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <Typography fontColor="dark" style={{ fontSize: '22px' }}>
          {desc}
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button variant="cancel" outline onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            isLoading={loading}
            outline
            onClick={async () => {
              setLoading(true);
              await onConfirm();
              setLoading(false);
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
