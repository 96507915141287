import React, { useEffect } from 'react';
import styled from 'styled-components';

export type SizeType = 'sm' | 'md' | 'lg' | number;

const getModalSize = (size: SizeType) => {
  if (typeof size === 'number') {
    return size + 'px';
  }
  switch (size) {
    case 'lg':
      return '78%';
    case 'md':
      return '56%';
    case 'sm':
      return '37%';

    default:
      return 137;
  }
};

const OverlayStyled = styled.div<{}>`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndices.overlay};
  background: black;
  opacity: 60%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
`;
const ModalStyled = styled.div<{
  size: SizeType;
  isFullWidth: boolean;
  isCentered?: boolean;
}>`
  border-radius: 6px;
  position: fixed;
  background: white;
  top: ${({ isCentered, isFullWidth }) =>
    isCentered ? '50%' : isFullWidth ? '0px' : '100px'};
  left: 50%;

  transform: ${({ isCentered }) =>
    isCentered ? 'translate(-50%, -50%)' : 'translateX(-50%)'};

  padding: 10px 20px 30px 20px;
  height: ${({ isFullWidth }) => (isFullWidth ? 'calc(100vh - 0px)' : 'auto')};
  width: ${({ size, isFullWidth }) =>
    isFullWidth ? '100%' : getModalSize(size)};

  z-index: ${({ theme }) => theme.zIndices.modal};
  & .close {
    cursor: pointer;
    font-size: 35px;
    margin-left: auto;
  }
`;
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
`;
const ModalTitle = styled.h4`
  font-size: 18px;
  margin: 0px;
`;

interface IModalProps {
  style?: React.CSSProperties;
  open: boolean;
  onClose: () => void;
  size?: SizeType;
  isFullWidth?: boolean;
  isCentered?: boolean;
  title?: string;
}

const Modal: React.FC<React.PropsWithChildren<IModalProps>> = ({
  style = {},
  open = false,
  onClose,
  children,
  size = 'md',
  isFullWidth = false,
  isCentered = false,
  title,
}) => {
  useEffect(() => {
    if (open) {
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'hidden';
      }
    } else {
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'unset';
      }
    }
    return () => {
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'unset';
      }
    };
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  if (!open) {
    return null;
  }
  return (
    <>
      <OverlayStyled onClick={handleClose} />
      <ModalStyled
        size={size}
        isFullWidth={isFullWidth}
        isCentered={isCentered}
        style={style}
      >
        <ModalHeader>
          {!!title && <ModalTitle>{title}</ModalTitle>}
          <div
            className="close"
            onClick={() => {
              handleClose();
            }}
          >
            <div>&times;</div>
          </div>
        </ModalHeader>

        {children}
      </ModalStyled>
    </>
  );
};

export default Modal;
