import {
  flexEnd,
  multipleLineTextEllipse,
  scrollCss,
} from '@shared/style/reusableStyle';
import styled from 'styled-components';

export const TableContainer = styled.div<{ $maxHeight?: string }>`
  overflow: auto;
  width: 100%;
  border: 1px solid #e4ebed;
  max-height: ${({ $maxHeight }) => $maxHeight || 'auto'};
  ${scrollCss}
`;

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
export const TableHead = styled.thead<{ $bgColor?: string }>`
  // border-top: 1px solid ${({ theme }) => theme.colors.disableButtonText};
  border-bottom: 1px solid ${({ theme }) => theme.colors.ad_disableButtonText};
  color: ${({ theme }) => theme.colors.secondaryText};
  background: ${({ theme, $bgColor }) => $bgColor || theme.colors.white};
  position: sticky;
  top: 0px;
  z-index: 99;
`;
export const TableBody = styled.tbody`
  color: ${({ theme }) => theme.colors.tableBody};
  position: relative;
`;

export const TableRow = styled.tr<{ $bgColor?: string }>`
  border-bottom: 1px solid #e4ebed;
  &.header {
    background: ${({ theme, $bgColor }) => $bgColor || theme.colors.white};
  }

  &.row-clickable {
    cursor: pointer;
    /* &:hover {
      background: ${({ theme }) => theme.colors.lightBackground};
    } */
  }
`;
export const TableDataCell = styled.td`
  padding: 6px;
  max-width: 200px;
  /* text-align: left; */

  &:last-child.actions {
    text-align: right;
  }
`;
export const TableHeadCell = styled.th`
  padding: 12px 10px;
  text-align: left;
  /* width: 100%; */

  &:last-child.actions {
    text-align: right;
    /* background-color: blue; */
    display: flex;
    justify-content: flex-end;
    /* padding-right: 10px; */
  }
`;

export const TableFooter = styled.tfoot``;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & .menu-icon {
    display: flex;
    flex-direction: column;
    height: 15px;
    width: 15px;
    cursor: pointer;
  }
`;

export const LoadingStyled = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: ${({ theme }) => theme.zIndices.auto};
  background: rgba(200, 200, 200, 0.4);
`;

export const NodataStyled = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableCardViewContainer = styled.div`
  margin: 20px 0px;
  width: 100%;
`;

export const IndividualTableRow = styled.div`
  background: ${({ theme }) => theme.colors.lightBackground};
  border-radius: 6px;
  padding: 18px 24px;

  & + & {
    margin-top: 20px;
  }
`;

export const TableCardRow = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  & .heading {
    flex-shrink: 0;
  }
  & .ellipsis {
    ${multipleLineTextEllipse(2)}
  }

  & + & {
    margin-top: 6px;
  }

  &.actions {
    ${flexEnd(12)};
  }
`;

export const PaginationStyled = styled.div`
  margin-top: 24px;
`;
export const PaginationTextStyled = styled.div`
  margin-bottom: 8px;
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-child(odd) {
    background-color: white;
  }

  &:nth-child(even) {
    background: ${({ theme }) => theme.colors.lightBackground};
  }
`;
