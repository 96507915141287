import authConfig, { changePassword } from '@shared/apiConfigs/auth';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Eye from '../../../assets/eye.svg';
import EyeSlash from '../../../assets/eyeSlash.svg';
import { useRoot } from '../../../context/RootProvider';
import AlertComponent from '../../../designComponents/Alert';
import Button from '../../../designComponents/Button';
import FormContainer from '../../../designComponents/FormContainer';
import Input from '../../../designComponents/Input';
import validation from './validation';

const ChangePassword = () => {
  const { auth } = useRoot();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    email: auth?.email,
    currentPassword: '',
    newPassword: '',
  };

  const handleChangePassword = async (values: changePassword) => {
    try {
      setLoading(true);
      await requestAPI(
        authConfig.changePassword({
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        })
      );
      toast.success('password changed successfully');
      navigate('/');
      setLoading(false);
    } catch (error: any) {
      if (typeof error === 'string') setErrorMessage(error);

      if (error.error && typeof error.error === 'string')
        setErrorMessage(error.error);

      setLoading(false);
    }
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleChangePassword}
        validationSchema={validation.ChangePassword}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <FormContainer label="Email">
                <Input
                  name="email"
                  value={values.email}
                  onChange={(e) => setFieldValue('email', e.target.value)}
                />
              </FormContainer>
              <FormContainer
                label="Current Password"
                error={touched?.currentPassword ? errors.currentPassword : ''}
              >
                <Input
                  name="currentPassword"
                  value={values.currentPassword}
                  onChange={(e) =>
                    setFieldValue('currentPassword', e.target.value)
                  }
                  type={showPassword ? 'text' : 'password'}
                  iconRight={
                    <img
                      src={showPassword ? EyeSlash : Eye}
                      alt="Eye icon"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowPassword((prev) => !prev)}
                    />
                  }
                />
              </FormContainer>
              <FormContainer
                label="New Password"
                error={touched?.newPassword ? errors.newPassword : ''}
              >
                <Input
                  name="newPassword"
                  value={values.newPassword}
                  onChange={(e) => setFieldValue('newPassword', e.target.value)}
                />
              </FormContainer>
              {/* <FormContainer
                label="Confirm Password"
                // error={touched?.confirmPassword ? errors.confirmPassword : ''}
              >
                <Input
                  name="confirmPassword"
                  onChange={(e) =>
                    setFieldValue('confirmPassword', e.target.value)
                  }
                />
              </FormContainer> */}
              {errorMessage && (
                <AlertComponent text={errorMessage} type="error" />
              )}
              <Button type="submit" isLoading={loading}>
                Update
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChangePassword;
