import React from 'react';
import { Outlet } from 'react-router-dom';

export interface IProtectedComponentProps {
  children: React.ReactNode;
  allowed?: string[];
  extraInfo?: any;
}

const ProtectedComponent: React.FC<IProtectedComponentProps> = ({
  children,
  //   allowed = [],
}) => {
  return children || <Outlet />;
};

export default ProtectedComponent;
