import 'react-toastify/dist/ReactToastify.css';

import GlobalStyle from '@shared/style/globalStyle';
import theme from '@shared/theme';
import { Provider } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import { store } from '../src/redux/store';
import RootProvider from './context/RootProvider';
import ScrollToTop from './designComponents/ScrollToTop';

function App() {
  return (
    <RootProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <GlobalStyle />
          <Outlet />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover
            theme="light"
            progressStyle={{ background: theme.colors.primary }}
          />
        </ThemeProvider>
      </Provider>
    </RootProvider>
  );
}

export default App;
