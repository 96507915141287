import * as Yup from 'yup';

const validation = Yup.object().shape({
  name: Yup.string().required('Title is required'),
  amount: Yup.number()
    .required('Amount is required')
    .typeError('Amount must be a number'),
  status: Yup.string().required('Status is required'),
});

export default validation;
