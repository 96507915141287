import { FaCity, FaMoneyBill1Wave } from 'react-icons/fa6';

import { ReactComponent as BuildingSVG } from '../../../assets/icons/building.svg';
import { ReactComponent as profileSVG } from '../../../assets/icons/client.svg';
// import { ReactComponent as delayedSVG } from '../../../assets/icons/delayed-jobs.svg';
// import { ReactComponent as documentSVG } from '../../../assets/icons/document.svg';
import { ReactComponent as faqsSVG } from '../../../assets/icons/faqs.svg';
import { ReactComponent as grantTableSVG } from '../../../assets/icons/grant-table-row.svg';
import { ReactComponent as grantSVG } from '../../../assets/icons/grants.svg';
import { ReactComponent as paymentSVG } from '../../../assets/icons/payment-agreement.svg';
// import { ReactComponent as stageSVG } from '../../../assets/icons/stages.svg';
import {
  BUILDING_ROUTE,
  CITY_ROUTE,
  // DOCUMENT_ROUTE,
  LEAD_PRICE_ROUTE,
  RELEIF_AMOUNT_ROUTE,
  // STAGE_ROUTE,
  VIEW_ADMNN_USER_ROUTE,
  // VIEW_DELAY_JOB_RECORD_ROUTE,
  VIEW_FAQS_ROUTE,
  VIEW_GRANT_TABLE_ROW_ROUTE,
  VIEW_GRANTS_ROUTE,
  VIEW_PAYMENT_AGREEMENTS_ROUTE,
} from '../../../routes/routePaths';

const urlPrefix = (url: string) => {
  return `/settings/${url}`;
};
export const menuData = [
  {
    label: 'Profile',
    icon: profileSVG,
    Url: urlPrefix('profile'),
  },
];

export const menuDev = [
  // {
  //   label: 'Documents',
  //   icon: documentSVG,
  //   Url: urlPrefix(DOCUMENT_ROUTE),
  // },
  // {
  //   label: 'Stages',
  //   icon: stageSVG,
  //   Url: urlPrefix(STAGE_ROUTE),
  // },
  {
    label: 'Grants',
    icon: grantSVG,
    Url: urlPrefix(VIEW_GRANTS_ROUTE),
  },
  {
    label: 'Building Type',
    icon: BuildingSVG,
    Url: urlPrefix(BUILDING_ROUTE),
  },
  {
    label: 'Relief Amount',
    icon: grantSVG,
    Url: urlPrefix(RELEIF_AMOUNT_ROUTE),
  },
  {
    label: 'Payment Agreements',
    icon: paymentSVG,
    Url: urlPrefix(VIEW_PAYMENT_AGREEMENTS_ROUTE),
  },
  // {
  //   label: 'Delayed Jobs',
  //   icon: delayedSVG,
  //   Url: urlPrefix(VIEW_DELAY_JOB_RECORD_ROUTE),
  // },

  {
    label: 'Admin Users',
    icon: profileSVG,
    Url: urlPrefix(VIEW_ADMNN_USER_ROUTE),
  },
  {
    label: 'Lead Price',
    icon: FaMoneyBill1Wave,
    Url: urlPrefix(LEAD_PRICE_ROUTE),
  },
];

export const menuFrontendSetup = [
  {
    label: 'Grant Table Rows',
    icon: grantTableSVG,
    Url: urlPrefix(VIEW_GRANT_TABLE_ROW_ROUTE),
  },
  {
    label: 'FAQs',
    icon: faqsSVG,
    Url: urlPrefix(VIEW_FAQS_ROUTE),
  },

  {
    label: 'City',
    icon: FaCity,
    Url: urlPrefix(CITY_ROUTE),
  },
];
