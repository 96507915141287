import theme from '@shared/theme';
import { ComponentProps, PropsWithChildren } from 'react';
import { FaAngleDown } from 'react-icons/fa6';
import styled from 'styled-components';
import { CSS } from 'styled-components/dist/types';

import AccordionProvider, { useAccordion } from './AccordionProvider';

// interface IAccordionComposition {
//   Header: React.FC<PropsWithChildren>;
//   Content: React.FC<PropsWithChildren>;
// }

interface AccordainHeaderProps extends ComponentProps<'div'> {
  className?: string;
  enableIcon?: boolean;
}
const Accordion = ({
  children,
  initialValue,
}: PropsWithChildren<{ initialValue?: boolean }>) => {
  return (
    <AccordionProvider initialValue={initialValue ?? false}>
      {children}
    </AccordionProvider>
  );
};

function AccordionHeader({
  children,
  className,
  enableIcon = true,
  onClick,
  ...restProps
}: PropsWithChildren<AccordainHeaderProps>) {
  const { toggle } = useAccordion();

  return (
    <AccordionHeaderStyle
      onClick={(e) => {
        toggle();
        if (onClick) {
          onClick(e);
        }
      }}
      className={`accordion-header ${className}`}
      {...restProps}
    >
      {children}
      {enableIcon && (
        <FaAngleDown width={12} height={12} color={theme.colors.white} />
      )}
    </AccordionHeaderStyle>
  );
}

function AccordionContent({
  children,
  animationType = 'height',
}: PropsWithChildren<{ animationType?: 'display' | 'height' }>) {
  const { expanded } = useAccordion();

  const style: CSS.Properties =
    animationType === 'height'
      ? {
          maxHeight: expanded ? 'fit-content' : '0px',
          visibility: expanded ? 'visible' : 'hidden',
          transition: 'all 0.2s ease-in-out',
        }
      : {
          display: expanded ? 'block' : 'none',
        };

  return <div style={style}>{children}</div>;
}

Accordion.Header = AccordionHeader;
Accordion.Content = AccordionContent;

export default Accordion;

const AccordionHeaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100px; */
`;
