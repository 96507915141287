import stagesConfig from '@shared/apiConfigs/stages';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import toastMessage from '../../../../constants/toastMessage';
import BreadCrumb from '../../../../designComponents/BreadCrumb';
import Button from '../../../../designComponents/Button';
import Input from '../../../../designComponents/Input';
import Select, {
  OptionType,
} from '../../../../designComponents/SelectWithInput';
import Typography from '../../../../designComponents/Typography';
import {
  EDIT_STAGE_WORK_ROUTE,
  SETTING_ROUTE,
  STAGE_ROUTE,
} from '../../../../routes/routePaths';
import Setting from '../..';
import validation from '../CreateWork/validation';
import {
  ButtonWrapperStyled,
  InfoRowStyled,
  WorkContainerStyled,
} from './style';

interface PropsType {
  stageId?: string;
  fetchStages?: () => Promise<void>;
  closeModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const status = [
  {
    label: 'InProgress',
    value: 'InProgress',
  },
  {
    label: 'Completed',
    value: 'Completed',
  },
  {
    label: 'Delayed',
    value: 'Delayed',
  },
];
const EditWork = ({ stageId, closeModal, fetchStages }: PropsType) => {
  const { id: routeId } = useParams();
  const [loading, setLoading] = useState(false);
  // const [selectOption, setSelectOption] = useState<string>('');
  const [option, setOption] = useState<OptionType[]>([]);
  const [initialValues, setInitialValues] = useState({
    // clientId: '',
    name: '',
    amount: '',
    status: '',
  });
  const [loadingClient, setLoadingClient] = useState(true);
  console.log(option);

  const fetchStageData = async () => {
    try {
      // if (!routeId) {
      //   console.error('Route ID is undefined');
      //   return;
      // }
      if (stageId) {
        const data = await requestAPI(stagesConfig.getStagesById(stageId));
        setInitialValues({
          // clientId: data.clientId,
          name: data.name,
          amount: data.amount,
          status: data.status,
        });
        // setSelectOption(data.clientId);
      } else {
        const data =
          routeId && (await requestAPI(stagesConfig.getStagesById(routeId)));
        setInitialValues({
          // clientId: data.clientId,
          name: data.name,
          amount: data.amount,
          status: data.status,
        });
        // setSelectOption(data.clientId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClient(false);
    }
  };

  const fetchAllClients = async () => {
    try {
      const { clients } = await requestAPI(stagesConfig.getAllClients(true));
      setOption(
        clients?.map((client: any) => {
          return {
            label: client.firstName,
            value: client._id,
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchStageData();
    fetchAllClients();
  }, [routeId]);

  const handleUpdateWork = async (data: any) => {
    try {
      if (stageId) {
        setLoading(true);
        await requestAPI(stagesConfig.updateStageById(data, stageId));
        setLoading(false);
        toast.success(toastMessage.workUpdated);
        fetchStages && (await fetchStages());
        closeModal && closeModal(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  if (loadingClient) {
    return <div>Loading client data...</div>;
  }

  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Stages',
      path: `${SETTING_ROUTE}/${STAGE_ROUTE}`,
    },
    {
      label: 'Edit Work',
      path: `${SETTING_ROUTE}/${STAGE_ROUTE}/${EDIT_STAGE_WORK_ROUTE}`,
    },
  ];

  return (
    <>
      {stageId ? (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleUpdateWork}
          validationSchema={validation}
        >
          {({ values, setFieldValue, handleSubmit, touched, errors }) => {
            return (
              <WorkContainerStyled>
                <Form onSubmit={handleSubmit}>
                  {/* <InfoRowStyled>
                    <Typography className="text">Client*</Typography>
                    <Select
                      selected={selectOption}
                      options={option}
                      onSelect={(selectedOption) => {
                        setSelectOption(selectedOption);
                        setFieldValue('clientId', selectedOption);
                      }}
                    />
                  </InfoRowStyled> */}

                  <InfoRowStyled>
                    <Typography className="text">Name*</Typography>
                    {touched.name && errors.name && (
                      <div style={{ color: 'red', marginBottom: '5px' }}>
                        {errors.name}
                      </div>
                    )}
                    <Input
                      value={values.name}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography className="text">Amount*</Typography>
                    {touched.amount && errors.amount && (
                      <div style={{ color: 'red', marginBottom: '5px' }}>
                        {errors.amount}
                      </div>
                    )}
                    <Input
                      value={values.amount}
                      onChange={(e) => setFieldValue('amount', e.target.value)}
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography className="text">Status*</Typography>
                    {touched.status && errors.status && (
                      <div style={{ color: 'red', marginBottom: '5px' }}>
                        {errors.status}
                      </div>
                    )}
                    <Select
                      placeholder="Status"
                      options={status}
                      selected={values.status}
                      onSelect={(selectedOption) =>
                        setFieldValue('status', selectedOption)
                      }
                    />
                  </InfoRowStyled>

                  <ButtonWrapperStyled>
                    <Button
                      type="submit"
                      className="btn-submit"
                      isLoading={loading}
                      variant="primary"
                      style={{ width: '200px', borderRadius: '5px' }}
                    >
                      Update Work
                    </Button>

                    <Button
                      onClick={() => {
                        closeModal && closeModal(false);
                      }}
                      style={{ width: '200px', borderRadius: '5px' }}
                    >
                      Cancel
                    </Button>
                  </ButtonWrapperStyled>
                </Form>
              </WorkContainerStyled>
            );
          }}
        </Formik>
      ) : (
        <>
          <BreadCrumb breadCrumb={breadcrumbs} />
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleUpdateWork}
            validationSchema={validation}
          >
            {({ values, setFieldValue, handleSubmit, touched, errors }) => {
              return (
                <Setting>
                  <WorkContainerStyled>
                    <Form onSubmit={handleSubmit}>
                      {/* <InfoRowStyled>
                        <Typography className="text">Client*</Typography>
                        <Select
                          selected={selectOption}
                          options={option}
                          onSelect={(selectedOption) => {
                            setSelectOption(selectedOption);
                            setFieldValue('clientId', selectedOption);
                          }}
                        />
                      </InfoRowStyled> */}

                      <InfoRowStyled>
                        <Typography className="text">Name*</Typography>
                        {touched.name && errors.name && (
                          <div style={{ color: 'red', marginBottom: '5px' }}>
                            {errors.name}
                          </div>
                        )}
                        <Input
                          value={values.name}
                          onChange={(e) =>
                            setFieldValue('name', e.target.value)
                          }
                        />
                      </InfoRowStyled>

                      <InfoRowStyled>
                        <Typography className="text">Amount*</Typography>
                        {touched.amount && errors.amount && (
                          <div style={{ color: 'red', marginBottom: '5px' }}>
                            {errors.amount}
                          </div>
                        )}
                        <Input
                          value={values.amount}
                          onChange={(e) =>
                            setFieldValue('amount', e.target.value)
                          }
                        />
                      </InfoRowStyled>

                      <InfoRowStyled>
                        <Typography className="text">Status*</Typography>
                        {touched.status && errors.status && (
                          <div style={{ color: 'red', marginBottom: '5px' }}>
                            {errors.status}
                          </div>
                        )}
                        <Select
                          placeholder="Status"
                          options={status}
                          selected={values.status}
                          onSelect={(selectedOption) =>
                            setFieldValue('status', selectedOption)
                          }
                        />
                      </InfoRowStyled>

                      <ButtonWrapperStyled>
                        <Button
                          type="submit"
                          className="btn-submit"
                          isLoading={loading}
                          variant="primary"
                          style={{ width: '200px', borderRadius: '5px' }}
                        >
                          Update Work
                        </Button>

                        <Button
                          onClick={() => {
                            closeModal && closeModal(false);
                          }}
                          style={{ width: '200px', borderRadius: '5px' }}
                        >
                          Cancel
                        </Button>
                      </ButtonWrapperStyled>
                    </Form>
                  </WorkContainerStyled>
                </Setting>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};

export default EditWork;
