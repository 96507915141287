import styled from 'styled-components';

export const DocumentContainerStyled = styled.div`
  margin: 0px auto;

  padding: 10px;

  & .label {
    width: 150px;
    font-size: 16px;
    margin-bottom: 10px;
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InfoRowStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const DocumentWrapperStyled = styled.div``;
