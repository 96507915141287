import { flexStart } from '@shared/style/reusableStyle';
import styled from 'styled-components';
export const IconLeft = styled.span``;

export const IconRight = styled.span``;

export const InputContainerStyled = styled.div`
  ${flexStart};
  gap: 10px;
  flex-wrap: nowrap;
  outline: 1px solid ${({ theme }) => theme.colors.transparent};
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 7px 20px;
  width: 100%;
  &:focus-within {
    &.input-container {
      outline-color: ${({ theme }) => theme.colors.black};
      border: 1px solid ${({ theme }) => theme.colors.black};
    }
  }

  &:focus-within&.error {
    outline-color: ${({ theme }) => theme.colors.red.strong};
    border-color: ${({ theme }) => theme.colors.red.strong};
  }

  &.error {
    border-color: ${({ theme }) => theme.colors.red.strong};
  }

  &.read-only {
    border: 1px solid ${({ theme }) => theme.colors.disable};
  }
`;
export const InputStyled = styled.input`
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  background-color: inherit;
  /* font-family: 'Roboto'; */
  line-height: 1.5rem;
  border: none;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.ad_disableButtonText};
  }
`;
