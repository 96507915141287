import { humanReadableDateWithTime } from '@shared/utils';
import { FaRegFilePdf } from 'react-icons/fa';
import { GoDownload } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import Tag from '../../../designComponents/Tag';
import Typography from '../../../designComponents/Typography';
import { WrapperStyled } from './style';

type Props = {
  title: string;
  date: string;
  attachments?: string;
  status?: string;
  amount?: string;
  buildType?: string;
};
const GrantsCard = ({
  title,
  date,
  attachments,
  status,
  amount,
  buildType,
}: Props) => {
  const theme = useTheme();

  return (
    <WrapperStyled>
      <div className="grantCard">
        <div className="details">
          <div className="details__title">
            <Typography
              style={{ fontWeight: 700, fontSize: 19 }}
              //   size={{ base: 14, sm: 16 }}
            >
              {title || 'This is test'}
            </Typography>
          </div>
          <div className=" details__date">
            <Typography
              //   size={{ base: 12 }}
              style={{
                width: '100%',
                fontSize: '20px',
                color: theme.colors.disableText,
                paddingTop: 10,
              }}
            >
              {humanReadableDateWithTime(date, 'YYYY MMM DD, hh:mm a') ||
                '2024-1-28'}
            </Typography>
          </div>
        </div>
        <div className="buildType">
          <Typography
            style={{
              fontSize: '16px',
              color: theme.colors.disableText,
            }}
          >
            Building Type
          </Typography>
          <Typography
            style={{ fontWeight: 700, fontSize: 19, paddingTop: 10 }}
            // size={{ base: 14, sm: 16 }}
          >
            {buildType || 'N/A'}
          </Typography>
        </div>
        <div className="attachments">
          <Typography
            style={{
              fontSize: '16px',
              color: theme.colors.disableText,
            }}
          >
            Attachments
          </Typography>
          <div className="attachments__details">
            <Typography
              style={{ fontWeight: 700, fontSize: 19, paddingTop: 10 }}
              //   size={{ base: 14, sm: 16 }}
            >
              {attachments ? (
                <FaRegFilePdf size={25} color="red" />
              ) : (
                'Test.pdf'
              )}
            </Typography>
            <div className="attachments__details__icon">
              {attachments ? (
                <Link to={attachments} download target="_blank">
                  <GoDownload color="gray" />
                </Link>
              ) : (
                <GoDownload />
              )}
            </div>
          </div>
        </div>
        <div className="amount">
          <Typography
            style={{
              fontSize: '16px',
              color: theme.colors.disableText,
            }}
          >
            Relief Amount
          </Typography>
          <Typography style={{ fontWeight: 700, fontSize: 19, paddingTop: 10 }}>
            € {amount?.toLocaleString() || '1000'}
          </Typography>
        </div>
        <div className="status">
          <Typography
            style={{
              fontSize: '16px',
              color: theme.colors.disableText,
            }}
          >
            Status
          </Typography>
          <Tag
            type={
              status === 'IN PROGRESS'
                ? 'caution'
                : status === 'APPROVED'
                  ? 'success'
                  : 'danger'
            }
            style={{
              width: '108px',
              height: '30px',
            }}
          >
            {status &&
              status
                .split(' ')
                .map((word, index) =>
                  index === 0 || status === 'IN PROGRESS'
                    ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                    : word.toLowerCase()
                )
                .join(' ')}
          </Tag>
        </div>
      </div>
    </WrapperStyled>
  );
};

export default GrantsCard;
