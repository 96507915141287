import styled from 'styled-components';

export const TimelineWrapper = styled.div<{
  $isLast?: boolean;
  $position: number;
  $completed: number;
  $isActive: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .circle-and-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    z-index: 10;
    position: relative;
    width: 100%;

    & .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      z-index: 10;
      background-color: ${({ $position, $isActive, theme }) =>
        $isActive >= $position
          ? theme.colors.status.completed
          : theme.colors.white};
      border: 2px solid
        ${({ $isActive, $position, theme }) =>
          $isActive >= $position
            ? theme.colors.green.strong
            : theme.colors.border};
      position: relative;
    }

    &::after {
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.colors.border};
      height: 2.5px;
      width: ${({ $position, $isLast }) =>
        $position === 1 || $isLast ? '50%' : '100%'};
      z-index: -10;
      top: 25%;
      right: ${({ $position }) => ($position === 1 ? '0px' : 'auto')};
      left: ${({ $isLast }) => ($isLast ? '0px' : 'auto')};
    }

    &::before {
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.colors.status.completed};
      height: 2.5px;
      width: ${({ $isActive, $position, $completed, $isLast }) =>
        ($position === $isActive && $isActive !== 1) ||
        ($completed === $position && $isLast)
          ? '50%'
          : $position < $isActive
            ? '100%'
            : '0'};
      z-index: -5;
      top: 25%;
      left: ${({ $position }) => ($position === 1 ? '50%' : '0')};
    }
  }
`;
