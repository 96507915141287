import { ConfigType } from '../utils/requestAPI';

type BuildingType = {
  _id?: string;
  buildingName: string;
};

const buildingConfig = {
  createBuildingType: (data: BuildingType): ConfigType => ({
    method: 'post',
    data,
    url: '/building-types',
  }),
  getAllBuildingType: (page?: number, limit?: number): ConfigType => ({
    method: 'get',

    url: `/building-types?page=${page}&limit=${limit}`,
  }),
  deleteBuilding: (id: string): ConfigType => ({
    method: 'delete',

    url: `/building-types/${id}`,
  }),
};

export default buildingConfig;
