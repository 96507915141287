import adminUsersConfig from '@shared/apiConfigs/adminUsers';
import theme from '@shared/theme';
import { requestAPI } from '@shared/utils';
import axios from 'axios';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Eye from '../../../assets/eye.svg';
import EyeSlash from '../../../assets/eyeSlash.svg';
import Mail from '../../../assets/Mail.svg';
import energyConstants from '../../../constants';
import toastMessage from '../../../constants/toastMessage';
import { useRoot } from '../../../context/RootProvider';
import AlertComponent from '../../../designComponents/Alert';
import Button from '../../../designComponents/Button';
import FormContainer from '../../../designComponents/FormContainer';
import Input from '../../../designComponents/Input';
import Typography from '../../../designComponents/Typography';
import { DASHBOARD_ROUTE } from '../../../routes/routePaths';
import { Container, LoginWrapper } from './style';
import validation from './validation';
type Props = {};

const initialValues = {
  email: '',
  password: '',
};

const Login = (_props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const { setAuth, setAccessToken, updateAuth } = useRoot();

  const handleLogin = async (data: typeof initialValues) => {
    try {
      setIsLoading(true);

      const loginData = await requestAPI(adminUsersConfig.login(data));

      if (loginData?.user?._id) {
        updateAuth(loginData?.user);
        localStorage.setItem(
          energyConstants.LOCAL_STORAGE_KEY.accessKey,

          loginData?.token
        );
        axios.defaults.headers.Authorization = `Bearer ${loginData?.token}`;

        localStorage.setItem(
          energyConstants.LOCAL_STORAGE_KEY.refreshKey,
          loginData?.refreshToken
        );
        navigate(DASHBOARD_ROUTE, { replace: true });
        toast.success(toastMessage.loginSuccess);

        setAccessToken(loginData.token);
        setAuth(loginData.user);
      }
      setIsLoading(false);
    } catch (error: any) {
      if (typeof error === 'string') setErrorMessage(error);

      if (error.error && typeof error.error === 'string')
        setErrorMessage(error.error);

      setIsLoading(false);
    }
  };

  return (
    <LoginWrapper>
      <Container>
        <Typography
          variant="heading"
          style={{
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          <span style={{ color: theme.colors.primary }}>Energyfix</span> Admin
          Login
        </Typography>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validation.Login}
          onSubmit={handleLogin}
        >
          {({ values, setFieldValue, errors, handleSubmit, touched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className="form-wrapper">
                  <div>
                    <FormContainer
                      label="Email*"
                      error={touched?.email ? errors.email : ''}
                    >
                      <Input
                        name="email"
                        value={values.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                        placeholder="Enter email"
                        iconRight={<img src={Mail} alt="mail icon" />}
                      />
                    </FormContainer>
                  </div>

                  <div>
                    <FormContainer
                      label="Password*"
                      error={touched.password ? errors.password : ''}
                    >
                      <Input
                        name="password"
                        value={values.password}
                        onChange={(e) =>
                          setFieldValue('password', e.target.value)
                        }
                        placeholder="Enter password"
                        type={showPassword ? 'text' : 'password'}
                        iconRight={
                          <img
                            src={showPassword ? EyeSlash : Eye}
                            alt="Eye icon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setShowPassword((prev) => !prev)}
                          />
                        }
                      />
                    </FormContainer>
                  </div>
                  <Button
                    variant="primary"
                    size="md"
                    type="submit"
                    isLoading={isLoading}
                    style={{ marginBottom: '20px', width: '100%' }}
                  >
                    Login
                  </Button>

                  {errorMessage && (
                    <AlertComponent text={errorMessage} type="error" />
                  )}

                  <Typography
                    variant="normal"
                    size={16}
                    className="forgot-password"
                  >
                    Forgot your password?
                  </Typography>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </LoginWrapper>
  );
};

export default Login;
