import * as Yup from 'yup';

const validation = Yup.object().shape({
  // clientId: Yup.string().required('Client is required'),

  name: Yup.string().required('Name is required'),
  amount: Yup.string().required('Amount is required'),
  status: Yup.string().required('Status is required'),
});

export default validation;
