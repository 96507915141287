import React, { CSSProperties, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down-fill.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up-fill.svg';
// import Checkbox from '../Checkbox';
import Loader from '../Loader';
import Typography from '../Typography/index';
import {
  HeaderTitleWrapper,
  LoadingStyled,
  NodataStyled,
  PaginationStyled,
  StyledTableRow,
  TableBody,
  TableContainer,
  TableDataCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TableStyled,
} from './style';
import TableCardView from './TabelCardView';

export type HeadingsType = Array<{
  key: string;
  label: string;
  style?: CSSProperties;
}>;
export type BodyType = Array<{
  id: string;
  pictureFile?: { URL: string; id: string }[];
  style?: {
    [key: string]: CSSProperties;
  };
  [key: string]: any;
}>;

export type TableDataType = {
  headings: HeadingsType;
  body: BodyType;
};

export interface ITableProps extends React.ComponentProps<'table'> {
  tableData: TableDataType;
  rowClick?: (id: string, record: any) => void;
  isLoading?: boolean;
  noData?: string | ReactNode;
  maxHeight?: string;
  ellipsis?: boolean;
  onCheck?: (data: Array<string>) => void;
  checkedData?: Array<string>;
  showCheckBox?: boolean;
  maxWidthForCardView?: string;
  headingBgColor?: string;
  hideHeader?: boolean;
  hidePagination?: boolean;
  hideSorting?: boolean;
  cellStyle?: CSSProperties;
  tableHeadStyle?: CSSProperties;
  tableHeadTypeStyle?: CSSProperties;
  hideColumnKeys?: string[];
}

const Table = ({
  tableData,
  rowClick = undefined,
  isLoading = false,
  noData = 'No data to display on Table.',
  maxHeight = 'calc(100vh - 220px)',
  ellipsis = true,
  onCheck = undefined,
  checkedData = [],
  showCheckBox = true,
  maxWidthForCardView = '968px',
  headingBgColor = '#E4EBED',
  hideHeader,
  hidePagination,
  hideSorting = false,
  cellStyle,
  tableHeadStyle,
  tableHeadTypeStyle,
  hideColumnKeys,
  ...restProps
}: ITableProps) => {
  const isMaxWidthForCardView = useMediaQuery({
    query: `(max-width:${maxWidthForCardView})`,
  });

  const handleTableDataCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { checked, value } = e.target;
    if (value === 'all') {
      const allIds = checked ? tableData?.body?.map((x) => x.id) : [];
      if (onCheck) {
        onCheck(allIds);
      }
      return;
    }
    let allIds = [...checkedData];
    if (checked) {
      allIds = [...allIds, value];
    } else {
      allIds = allIds.filter((x) => x !== value);
    }
    if (onCheck) {
      onCheck(allIds);
    }
  };

  return isMaxWidthForCardView ? (
    <TableCardView
      tableData={tableData}
      rowClick={rowClick}
      isLoading={isLoading}
      noData={noData}
      maxHeight={maxHeight}
      ellipsis={ellipsis}
      checkedData={checkedData}
      showCheckBox={showCheckBox}
      handleTableDataCheck={handleTableDataCheck}
      {...restProps}
    />
  ) : (
    <>
      <TableContainer $maxHeight={maxHeight}>
        <TableStyled {...restProps}>
          {!hideHeader && (
            <TableHead $bgColor={headingBgColor}>
              <TableRow className="header" $bgColor={headingBgColor}>
                {/* {showCheckBox && (
                  <TableHeadCell>
                    <Checkbox
                      checked={tableData.body.length === checkedData.length}
                      onChange={handleTableDataCheck}
                      value="all"
                      style={{ marginRight: '5px' }}
                    />
                  </TableHeadCell>
                )} */}
                {tableData?.headings?.map((heading) => {
                  if (
                    heading.key === 'id' ||
                    hideColumnKeys?.includes(heading.key)
                  ) {
                    return null;
                  }
                  return (
                    <TableHeadCell
                      style={tableHeadStyle}
                      key={heading.key}
                      className={heading.key === 'actions' ? 'actions' : ''}
                    >
                      <HeaderTitleWrapper
                        className={heading.key === 'actions' ? 'actions' : ''}
                        style={{ ...(heading?.style ? heading?.style : {}) }}
                      >
                        <Typography
                          style={{
                            marginRight: '6px',
                            textAlign:
                              heading.key === 'actions' ? 'right' : 'center',
                            ...tableHeadTypeStyle,
                          }}
                          strong
                        >
                          {heading.label}
                        </Typography>
                        {hideSorting ? null : (
                          <div>
                            <ArrowUp className="menu-icon" />
                            <ArrowDown className="menu-icon" />
                          </div>
                        )}
                      </HeaderTitleWrapper>
                    </TableHeadCell>
                  );
                })}
              </TableRow>
            </TableHead>
          )}
          <TableBody
            style={{
              height: tableData?.body?.length === 0 ? '50vh' : 'auto',
              overflowY: 'auto',
            }}
          >
            {isLoading && (
              <TableRow>
                <TableDataCell colSpan={tableData?.headings?.length}>
                  <LoadingStyled>
                    <Loader size="md" type="spinner" />
                  </LoadingStyled>
                </TableDataCell>
              </TableRow>
            )}
            {tableData?.body?.length === 0 && !isLoading && (
              <TableRow>
                <TableDataCell colSpan={tableData?.headings?.length}>
                  <NodataStyled>{noData}</NodataStyled>
                </TableDataCell>
              </TableRow>
            )}
            {tableData.body.map((body) => {
              return (
                <StyledTableRow
                  key={body.id}
                  onClick={() => rowClick && rowClick(body?.id, body)}
                  className={`data-row ${rowClick ? 'row-clickable' : ''}`}
                >
                  {/* {showCheckBox && (
                    <TableDataCell>
                      <span onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                          checked={checkedData.includes(body.id)}
                          value={body.id}
                          onChange={handleTableDataCheck}
                          style={{ marginRight: '5px' }}
                        />
                      </span>
                    </TableDataCell>
                  )} */}
                  {tableData?.headings.map((heading, index) => {
                    if (
                      heading.key === 'id' ||
                      hideColumnKeys?.includes(heading.key)
                    ) {
                      return null;
                    }
                    return (
                      <TableDataCell
                        key={`${body.id}-${index}`}
                        className={heading.key === 'actions' ? 'actions' : ''}
                        style={{
                          ...cellStyle,
                          ...(body?.style ? body?.style[heading.key] : {}),
                        }}
                      >
                        {typeof body[heading.key] === 'string' ? (
                          <Typography
                            ellipsis={ellipsis}
                            style={{ fontWeight: 500 }}
                          >
                            {body[heading.key]}
                          </Typography>
                        ) : (
                          body[heading.key]
                        )}
                      </TableDataCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </TableStyled>
      </TableContainer>
      {!hidePagination && tableData.body.length !== 0 && (
        <PaginationStyled></PaginationStyled>
      )}
    </>
  );
};

export default Table;
