import React from 'react';

import BreadCrumb from '../../../../designComponents/BreadCrumb';
import {
  EDIT_ADMIN_USER_ROUTE,
  SETTING_ROUTE,
  VIEW_ADMNN_USER_ROUTE,
} from '../../../../routes/routePaths';

type Props = {};

const EditAdmin = (_props: Props) => {
  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'View Admin Users',
      path: `${SETTING_ROUTE}/${VIEW_ADMNN_USER_ROUTE}`,
    },
    {
      label: 'Edit Admin User',
      path: `${SETTING_ROUTE}/${VIEW_ADMNN_USER_ROUTE}/${EDIT_ADMIN_USER_ROUTE}`,
    },
  ];
  return (
    <>
      {' '}
      <BreadCrumb breadCrumb={breadcrumbs} />
      <div>EditAdmin</div>
    </>
  );
};

export default EditAdmin;
