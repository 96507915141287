import { ConfigType } from '../utils/requestAPI';

const transactionsConfig = {
  getAllTransactions: (): ConfigType => ({
    method: 'get',
    url: '/transactions',
  }),

  getTransactionByLeadId: (id: string): ConfigType => ({
    method: 'get',
    url: `/transactions/users/${id}`,
  }),
};

export default transactionsConfig;
