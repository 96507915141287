import documentsConfig, { DocumentType } from '@shared/apiConfigs/documents';
import { requestAPI } from '@shared/utils';
import { uploadImagesToS3 } from '@shared/utils/s3Upload';
import { Form, Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { FaRegFilePdf } from 'react-icons/fa';
import { IoIosCloseCircleOutline } from 'react-icons/io';
// import { Document, Page } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import toastMessage from '../../../../../../constants/toastMessage';
import BrowseFile from '../../../../../../designComponents/BrowseFile';
import Button from '../../../../../../designComponents/Button';
import Checkbox from '../../../../../../designComponents/Checkbox';
import Input from '../../../../../../designComponents/Input';
import Select from '../../../../../../designComponents/SelectWithInput';
import Typography from '../../../../../../designComponents/Typography';
import {
  DocumentContainerStyled,
  DocumentWrapperStyled,
  FormContainer,
  InfoRowStyled,
} from './style';
import { validationSchema } from './validation';

type Props = {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  fetchDocuments: () => Promise<void>;
};

const initialValues = {
  name: '',
  // documentFileName: '',
  documentType: '',
};
const documentType = [
  { label: 'REPORT', value: 'REPORT' },
  { label: 'GRANT', value: 'GRANT' },
  { label: 'CONTRACT', value: 'CONTRACT' },
  { label: 'CERTIFICATE', value: 'CERTIFICATE' },
  { label: 'ADDITIONAL', value: 'ADDITIONAL' },
  { label: 'INVOICE', value: 'INVOICE' },
];

const AddDocument = ({ closeModal, fetchDocuments }: Props) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [showOnClient, setshowOnClient] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const { clientId } = useParams();

  const formikRef = useRef<FormikProps<typeof initialValues>>(null);

  const [file, setFile] = useState<File[]>([]);

  const handleAddDocument = async (values: DocumentType) => {
    try {
      setLoading(true);
      if (file.length > 0) {
        const documentURL = await uploadImagesToS3(file);
        if (documentURL && documentURL?.length > 0) {
          await requestAPI(
            documentsConfig.addDocument({
              name: values.name,
              // documentFileName: values.documentFileName,
              leadId: clientId,
              documentType: values.documentType,
              documentURL: documentURL[0],
              isVisible: showOnClient,
            })
          );
        }
        setLoading(false);
        toast.success(toastMessage.documentAdded);
        closeModal(false);
        await fetchDocuments();
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleShowOnCientChange = () => {
    setshowOnClient(!showOnClient);
  };
  const handleAddFiles = (files: File[]) => {
    if (formikRef?.current && files.length > 0) {
      formikRef?.current?.setFieldValue('documentFileName', files[0].name);
    }

    setFile(files);
  };
  const handleRemove = () => {
    setFile([]);
    formikRef?.current?.setFieldValue('documentFileName', '');
  };
  return (
    <DocumentContainerStyled>
      <Typography style={{ fontSize: '20px', marginBottom: '20px' }}>
        New Document
      </Typography>
      <DocumentWrapperStyled>
        <Formik
          initialValues={initialValues}
          onSubmit={handleAddDocument}
          innerRef={formikRef}
          validationSchema={validationSchema}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => {
            console.log('erros', errors);

            return (
              <Form onSubmit={handleSubmit}>
                <FormContainer>
                  <InfoRowStyled>
                    {' '}
                    <div>
                      <Typography className="label">Document Title*</Typography>
                      <Input
                        name="name"
                        value={values.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                      {touched.name && errors.name && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {errors.name}
                        </div>
                      )}
                    </div>
                    <div>
                      <Typography className="label">Document Type*</Typography>
                      <Select
                        options={documentType}
                        onSelect={(selectedOption) => {
                          setFieldValue('documentType', selectedOption);
                        }}
                        selected={values.documentType}
                        placeholder="Type of Document"
                      />

                      {touched.documentType && errors.documentType && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {errors.documentType}
                        </div>
                      )}
                    </div>
                  </InfoRowStyled>

                  <div>
                    <Typography className="label">Upload Document*</Typography>

                    {file.length === 0 ? (
                      <div
                        style={{
                          width: '100%',
                          // border: '1px solid black',
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '20px',
                          marginBottom: '20px',
                          background: theme.colors.gray.lighter,
                        }}
                      >
                        <BrowseFile
                          files={[]}
                          setFiles={handleAddFiles}
                          accepts=".pdf"
                          isUploading={false}
                          showSelectFiles={false}
                        />
                      </div>
                    ) : (
                      <div style={{ marginTop: '10px' }}>
                        {/* <Typography>Uploaded File:</Typography> */}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <div style={{ position: 'relative' }}>
                            <IoIosCloseCircleOutline
                              size={25}
                              style={{
                                position: 'absolute',
                                right: '0',
                                top: '-12px',
                                cursor: 'pointer',
                                color: 'red',
                                left: '60px',
                              }}
                              onClick={handleRemove}
                            />
                            <FaRegFilePdf size={80} color="red" />
                          </div>
                          <Typography>{file[0].name}</Typography>
                        </div>
                      </div>
                    )}
                    {file.length === 0 && isClicked && (
                      <div style={{ color: 'red', marginTop: '10px' }}>
                        {'Upload Document is required'}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Checkbox
                      isChecked={showOnClient}
                      value=""
                      onChange={() => {
                        handleShowOnCientChange();
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: '14px',
                        marginRight: '10px',
                        minWidth: 'fit-content',
                        marginTop: '10px',
                      }}
                      className="label"
                    >
                      Visible on client profile
                    </Typography>
                  </div>
                </FormContainer>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '20px',
                  }}
                >
                  {/* <Button style={{ borderRadius: '5px' }}>Close</Button> */}
                  <Button
                    type="submit"
                    variant="primary"
                    style={{ borderRadius: '5px' }}
                    isLoading={loading}
                    onClick={() => setIsClicked(true)}
                  >
                    Create
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </DocumentWrapperStyled>
    </DocumentContainerStyled>
  );
};

export default AddDocument;
