// import { toast } from 'react-toastify';
import faqsConfig from '@shared/apiConfigs/faqs';
import { requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { FaEye, FaTrash } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteModal from '../../../components/DeleteModal/deleteModal';
import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Flex from '../../../designComponents/Flex';
import Pagination from '../../../designComponents/Pagination';
import Table, {
  BodyType,
  TableDataType,
} from '../../../designComponents/Table';
import { SETTING_ROUTE, VIEW_FAQS_ROUTE } from '../../../routes/routePaths';
import Setting from '..';

export type FaqType = {
  _id: string;
  title: string;
  content: string;
  createdAt: Date;
  updatedAt: Date;
  slug: string;
};
export type FaqWithCategoryType = {
  _id: string;
  category: string;
  faq: FaqType[];
};

type Props = {};

const Faqs = (_props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [faqs, setFaqs] = useState<FaqWithCategoryType[]>([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [deleteFaqId, setDeleteFaqId] = useState<string>('');

  const navigate = useNavigate();
  const deleteHandler = async (faqId: string) => {
    try {
      await requestAPI(faqsConfig.deleteFaqById(faqId));
      toast.success('Deleted successfully');
      setOpenModal(false);
      setFaqs((prev) => prev.filter((x) => x._id !== faqId));
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  useEffect(() => {
    requestAPI(faqsConfig.getAllFaqs({ page: currentPage }))
      .then((res) => {
        setTotalData(res.pagination.totalData);
        setFaqs(res.data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, [currentPage]);

  const viewFaqs = (id: string) => navigate(id);

  const formData: BodyType = faqs.map((faq: FaqWithCategoryType, idx) => ({
    rowNo: `${idx + 1}.`,
    id: faq._id,
    category: faq.category,
    faqNumber: faq.faq.length,
    actions: (
      <Flex gap="16px" justifyContent="flex-end">
        <Button
          variant="nostyle"
          onClick={(e) => {
            e.stopPropagation();
            viewFaqs(faq._id);
          }}
        >
          <FaEye color="#5151ff" size={22} />
        </Button>

        <Button
          variant="nostyle"
          onClick={(e) => {
            e.stopPropagation();
            setDeleteFaqId(faq._id);
            setOpenModal(true);
          }}
        >
          <FaTrash color="#ff3f3f" size={22} />
        </Button>
      </Flex>
    ),
    style: {
      faqNumber: {
        textAlign: 'center',
      },
    },
  }));

  const tableData: TableDataType = {
    headings: [
      { key: 'rowNo', label: 'SN' },
      { key: 'id', label: 'Id' },
      { key: 'category', label: 'Category' },
      {
        key: 'faqNumber',
        label: "Faq's count ",
        style: { display: 'flex', justifyContent: 'center' },
      },
      {
        key: 'actions',
        label: 'Actions',
      },
    ],
    body: formData,
  };

  const handleAddFaq = () => navigate('add');

  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Faqs',
      path: `${SETTING_ROUTE}/${VIEW_FAQS_ROUTE}`,
    },
  ];

  const handlePagination = ({ page }: { page: number }) => {
    setCurrrentPage(page);
  };

  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <Flex justifyContent="end" margin="0px 0px 16px">
          <Button variant="primary" onClick={handleAddFaq}>
            Add FAQ
          </Button>
        </Flex>
        <Table
          hideSorting
          tableData={tableData}
          showCheckBox={false}
          isLoading={isLoading}
          cellStyle={{ padding: '10px 5px' }}
          rowClick={viewFaqs}
        />
        <Pagination
          activePage={currentPage}
          totalCount={totalData}
          limit={10}
          handlePagination={handlePagination}
        />
      </Setting>
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        desc="Are you sure want to delete FAQ?"
        onConfirm={() => deleteHandler(deleteFaqId)}
      />
    </>
  );
};

export default Faqs;
