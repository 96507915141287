import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRoot } from '../../context/RootProvider';
import menuList from './sidebarMenu';
import { SidebarItem, Wrapper } from './style';

type Props = {};

const Sidebar: React.FC<Props> = (_props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { auth } = useRoot();

  const filteredMenuList = menuList.filter((menu) => {
    if (
      auth?.role === 'CUSTOMER-SERVICE-ASSISTANT' &&
      menu.key === 'settings'
    ) {
      return false;
    }
    return true;
  });

  return (
    <Wrapper $isMini={pathname.startsWith('/settings')}>
      <div className="menu-container">
        {filteredMenuList?.map((menu) => {
          const Icon = menu.icon;
          const checkPath = menu.path.split('/')?.[1];
          return (
            <SidebarItem
              $isMini={pathname.startsWith('/settings')}
              $active={pathname.startsWith(`/${checkPath}`)}
              key={menu.key}
              className="sidebar-item"
              onClick={() => navigate(menu.path)}
            >
              <Icon className="icon" />
              <h2 className="label">{menu.label}</h2>
            </SidebarItem>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default Sidebar;
