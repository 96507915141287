import * as Yup from 'yup';

const validation = {
  ReliefAmount: Yup.object({
    grantNameId: Yup.string().required('Grant is required'),
    buildingTypeId: Yup.string().required('Building type is required'),
    reliefAmount: Yup.number()
      .required('Relief amount is required')
      .notOneOf([0], 'Please enter a valid amount.'),
  }),
};

export default validation;
