import useDetectOutsideClick from '@shared/hooks/useDetectOutsideClick';
import { selectDisable } from '@shared/style/reusableStyle';
import React, { useEffect, useRef, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { MdArrowDropDown } from 'react-icons/md';
import { styled, useTheme } from 'styled-components';

import Input from '../Input';
import Typography from '../Typography';
import { OptionsContainerStyled, SelectWrapper } from './style';

export type OptionType = { label: string; value: string; record?: any };

interface SelectProps {
  options: OptionType[];
  onSelect: (selectedOption: string, record?: any) => void;
  selected: string;
  placeholder?: string;
  onChange?: boolean;
  searchTerm?: string;
  style?: React.CSSProperties;
  containerStyle?: boolean;
  disabled?: boolean;
  borderRadius?: string;
}

const Select: React.FC<SelectProps> = ({
  options,
  onSelect,
  placeholder = 'Select',
  selected,
  onChange,
  disabled,
  borderRadius,
}) => {
  const selectWrapperRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const [isActive, setIsActive] = useDetectOutsideClick(
    selectWrapperRef,
    false
  );
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);
  const [search, setSearch] = useState('');

  const handleSearch = (e: any) => {
    e.preventDefault();
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    if (searchTerm === '') {
      setFilteredOptions(options);
    } else {
      const newFilteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOptions(newFilteredOptions);
    }
  };

  const handleDropdownClick = () => {
    setIsActive((prev: boolean) => !prev);
    if (!isActive) {
      setFilteredOptions(options);
    }
  };
  const handleOptionClick = (option: OptionType) => {
    onSelect(option.value, option.record);
    setIsActive(false);
  };

  const getSelectedValueLabel = () => {
    const selectedValue = options?.find((ob) => ob?.value === selected);

    if (selectedValue) {
      return selectedValue.label;
    }
    return null;
  };

  return (
    <StyledDiv
      style={{
        position: 'relative',
        width: '100%',
        opacity: disabled ? '0.5' : '1',
      }}
      ref={selectWrapperRef}
    >
      <SelectWrapper
        {...(!disabled && { onClick: handleDropdownClick })}
        $borderRadius={borderRadius ?? ''}
      >
        <Typography style={{ padding: '0px 13px' }}>
          {getSelectedValueLabel() || (
            <p style={{ color: theme.colors.disableText }}>{placeholder}</p>
          )}
        </Typography>
        <MdArrowDropDown size={22} />
      </SelectWrapper>

      {!disabled && isActive && (
        <OptionsContainerStyled>
          {onChange && (
            <Input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
              iconLeft={<CiSearch />}
            />
          )}

          <ul>
            {filteredOptions?.map((option, index) => (
              <li
                className="option"
                key={index}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </OptionsContainerStyled>
      )}
    </StyledDiv>
  );
};

export default Select;

const StyledDiv = styled.div`
  ${selectDisable}
`;
