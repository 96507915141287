import { ConfigType, ParamsType } from "../utils/requestAPI";

type SettingParams = {
  _id: string;
  price: number;
  currency: string;
};

const settingConfig = {
  createLeadPrice: (
    data: Pick<SettingParams, "price" | "currency">
  ): ConfigType => ({
    method: "post",
    url: "/stripes/price",
    data,
  }),

  getAllPrice: (params?: ParamsType): ConfigType => ({
    method: "get",
    url: `/stripes/prices`,
    params,
  }),

  changeSelectedPrice: (data: { id: string }): ConfigType => ({
    method: "patch",
    url: `/stripes/price`,
    data,
  }),
};

export default settingConfig;
