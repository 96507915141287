import { FaCheck, FaChevronDown } from 'react-icons/fa';

import Typography from '../../../designComponents/Typography';
import { TimelineWrapper } from './style';

type Props = {
  completed: number;
  isLast?: boolean;
  position: number;
  // title?: string;
};

const Timeline = ({ completed, position, isLast = false }: Props) => {
  const isActive = completed + 1;
  return (
    <TimelineWrapper
      $position={position}
      $isLast={isLast}
      $completed={completed}
      $isActive={isActive}
    >
      {isActive === position && (
        <FaChevronDown
          size={14}
          style={{
            position: 'absolute',
            top: -16,
          }}
          color="gray"
        />
      )}
      <div className="circle-and-title">
        <div className="circle">
          {isActive <= position ? (
            <Typography
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              fontSize={12}
              fontColor={`${isActive >= position ? 'white' : 'border'}`}
            >
              {position}
            </Typography>
          ) : (
            <FaCheck
              color="#fff"
              size={14}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
        </div>

        <div className="title">
          <Typography
            fontColor={`${isActive >= position ? 'normal' : 'border'}`}
          >
            {/* {title} */}
          </Typography>
        </div>
      </div>

      {/* <div className="line" /> */}
    </TimelineWrapper>
  );
};

export default Timeline;
