/* eslint-disable */

import requestAPI from "./requestAPI";

const getPresignedURl = async (fileName: string, fileType: string) => {
  const { url, key } = await requestAPI({
    method: "post",
    url: "/common/presignedurl",
    data: {
      fileName: fileName,
      fileType: fileType,
    },
  });
  return { url, key };
};

export const uploadImagesToS3 = async (filesArr: any) => {
  const _files = Array.from(filesArr);
  if (_files.length === 0) return;
  try {
    const uploadMultipleImagesPromise = [] as Promise<any>[];
    const keys = [] as string[];
    for (let i = 0; i < _files.length; i++) {
      const file = _files[i] as File;
      const uploadPromise = new Promise(async (resolve, reject) => {
        const { url, key } = await getPresignedURl(file?.name, file?.type);
        keys.push(key);

        const uploadResponse = await fetch(url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });
        if (!uploadResponse.ok) {
          throw reject(`Failed to upload file ${file.name} to S3`);
        }

        // Resolve the promise after successful upload
        resolve(`File ${file.name} uploaded successfully`);
      });

      uploadMultipleImagesPromise.push(uploadPromise);
    }

    await Promise.all(uploadMultipleImagesPromise);
    return keys;
  } catch (error) {
    console.log(error);
  }
};
