import delayedJobsConfig from '@shared/apiConfigs/delayedJobs';
import { humanReadableDateWithTime, requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Table, { TableDataType } from '../../../designComponents/Table';
import {
  SETTING_ROUTE,
  VIEW_DELAY_JOB_RECORD_ROUTE,
} from '../../../routes/routePaths';
import Setting from '..';

type DelayedJobType = {
  _id: string;
  id: string;
  priority: number;
  attempts: number;
  handler: string;
  lastError: string;
  lockedBy: string;
  queue: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
};

type Props = {};

const DelayedJobs = (_props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [delayedJobs, setDelayedJobs] = useState([]);

  const navigate = useNavigate();

  const tableData: TableDataType = {
    headings: [
      { key: 'id', label: 'Id' },

      { key: 'priority', label: 'Priority' },
      { key: 'attempts', label: 'Attempts' },

      {
        key: 'createdAt',
        label: 'Created At',
      },
      {
        key: 'runAt',
        label: 'Run At',
      },
      {
        key: 'action',
        label: 'Action',
      },
    ],
    body: delayedJobs,
  };

  useEffect(() => {
    const deleteHandler = async (jobId: string) => {
      // const confirmDelete = confirm('Are you sure you want to delete this?');
      // if (!confirmDelete) return;
      try {
        await requestAPI(delayedJobsConfig.deleteDelayedJobById(jobId));
        navigate(0);
      } catch (err) {
        toast.error('Something went wrong!');
      }
    };

    requestAPI(delayedJobsConfig.getAllDelayedJobs())
      .then(({ data }) => {
        const newData = data.map((delayedJob: DelayedJobType) => ({
          id: delayedJob.id,
          priority: delayedJob.priority,
          attempts: delayedJob.attempts,
          createdAt: humanReadableDateWithTime(delayedJob.createdAt),
          action: (
            <>
              <Button
                style={{
                  margin: '0px 12px',
                  background: 'grey',
                  border: 0,
                  borderRadius: 3,
                  fontSize: 12,
                  lineHeight: 18,
                }}
                onClick={() => {
                  navigate(delayedJob._id);
                }}
              >
                View
              </Button>
              <Button
                style={{
                  margin: '0px 12px',
                  background: 'grey',
                  border: 0,
                  borderRadius: 3,
                  fontSize: 12,
                  lineHeight: 18,
                }}
              >
                Edit
              </Button>
              <Button
                style={{
                  margin: '0px 12px',
                  background: 'grey',
                  border: 0,
                  borderRadius: 3,
                  fontSize: 12,
                  lineHeight: 18,
                }}
                onClick={() => deleteHandler(delayedJob._id)}
              >
                Delete
              </Button>
            </>
          ),
        }));

        setDelayedJobs(newData);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, [navigate]);

  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Delay Jobs',
      path: `${SETTING_ROUTE}/${VIEW_DELAY_JOB_RECORD_ROUTE}`,
    },
  ];
  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <Table
          hideSorting
          tableData={tableData}
          showCheckBox={false}
          isLoading={isLoading}
        />
      </Setting>
    </>
  );
};

export default DelayedJobs;
