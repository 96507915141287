import grantConfig, { GrantType } from '@shared/apiConfigs/grant';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import toastMessage from '../../../../constants/toastMessage';
import Button from '../../../../designComponents/Button';
import Input from '../../../../designComponents/Input';
import Typography from '../../../../designComponents/Typography';
import Setting from '../..';
import { GrantContainer, GrantWrapper, InfoRow } from './style';

type Props = {};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Grant Name is required'),
});

const NewGrant = (_props: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleAddGrant = async (values: GrantType) => {
    setLoading(true);
    try {
      await requestAPI(
        grantConfig.createGrant({
          name: values.name,
        })
      );
      toast.success(toastMessage.grantCreated);
      navigate(-1);
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      toast.error(error);
      setLoading(false);
    }
  };
  return (
    <>
      <Setting>
        <GrantWrapper>
          <GrantContainer>
            <Formik
              initialValues={{ name: '' }}
              enableReinitialize
              onSubmit={handleAddGrant}
              validationSchema={validationSchema}
            >
              {({ values, handleSubmit, setFieldValue, errors, touched }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Typography
                      style={{ fontWeight: '600', marginBottom: '20px' }}
                    >
                      Grant
                    </Typography>
                    <InfoRow>
                      <Typography>Grant Name</Typography>
                      <Input
                        name="name"
                        value={values.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                      {errors.name && touched.name && (
                        <Typography style={{ color: 'red' }}>
                          {errors.name}
                        </Typography>
                      )}
                    </InfoRow>

                    <Button type="submit" variant="primary" isLoading={loading}>
                      Create Grant
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </GrantContainer>
        </GrantWrapper>
      </Setting>
    </>
  );
};

export default NewGrant;
