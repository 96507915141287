import stagesConfig from '@shared/apiConfigs/stages';
import { humanReadableDate, requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Flex from '../../../designComponents/Flex';
import Table, { TableDataType } from '../../../designComponents/Table';
import { SETTING_ROUTE, STAGE_ROUTE } from '../../../routes/routePaths';
import Setting from '..';

type StageType = {
  _id: string;
  leadId: string;
  name: string;
  amount: number;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  id: number;
  __v: number;
};

type Props = {};

const Stages = (_props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [stages, setStages] = useState([]);
  // const { id: _id } = useParams();
  const navigate = useNavigate();

  const tableData: TableDataType = {
    headings: [
      { key: 'id', label: 'Id' },

      { key: 'client', label: 'Client' },
      { key: 'name', label: 'Name' },
      {
        key: 'amount',
        label: 'Amount',
      },

      {
        key: 'status',
        label: 'Status',
      },
      {
        key: 'createdAt',
        label: 'Created At',
      },
      {
        key: 'updatedAt',
        label: 'Updated At',
      },
      {
        key: 'actions',
        label: 'Actions',
      },
    ],
    body: stages,
  };

  useEffect(() => {
    const deleteHandler = async (stageId: string) => {
      // const confirmDelete = confirm('Are you sure you want to delete this?');
      // if (!confirmDelete) return;
      try {
        await requestAPI(stagesConfig.deleteStageById(stageId));
        navigate(0);
        toast.success('Stage deleted successfully');
      } catch (err) {
        toast.error('Something went wrong!');
      }
    };

    requestAPI(stagesConfig.getAllStages())
      .then(({ data }) => {
        const newData = data.map((stage: StageType) => {
          return {
            client: stage.leadId,
            name: stage.name,
            amount: stage.amount,
            status: stage.status,
            createdAt: humanReadableDate(stage.createdAt),
            updatedAt: humanReadableDate(stage.updatedAt),
            actions: (
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="secondary"
                  outline
                  onClick={() => {
                    navigate(stage._id);
                  }}
                >
                  View
                </Button>
                <Button
                  variant="update"
                  outline
                  onClick={() => {
                    navigate(`/settings/stages/edit-work/${stage._id}`);
                    // if (edit) {
                    //   navigate(edit);
                    // }
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  outline
                  onClick={() => {
                    deleteHandler(stage._id);
                  }}
                >
                  Delete
                </Button>
              </div>
            ),
          };
        });

        setStages(newData);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, [navigate]);

  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Stages',
      path: `${SETTING_ROUTE}/${STAGE_ROUTE}`,
    },
  ];
  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <Flex justifyContent="flex-end">
          <Button
            onClick={() => navigate('/settings/stages/create-work')}
            variant="primary"
            style={{ marginBottom: '16px', width: '200px' }}
          >
            Create Work
          </Button>
        </Flex>
        <Table
          hideSorting
          tableData={{ headings: tableData.headings, body: stages }}
          showCheckBox={false}
          isLoading={isLoading}
        />
      </Setting>
    </>
  );
};

export default Stages;
