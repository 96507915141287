import { ConfigType } from '../utils/requestAPI';

export type GrantType = {
  id?: string;
  name: string;
};

const grantConfig = {
  createGrant: (data: GrantType): ConfigType => ({
    method: 'post',
    data,
    url: '/grant-name',
  }),

  getAllGrants: (page?: number, limit?: number): ConfigType => ({
    method: 'get',
    url: `/grant-name?page=${page}&limit=${limit}`,
  }),
  deleteGrant: (id: any): ConfigType => ({
    method: 'delete',

    url: `/grant-name/${id}`,
  }),
};

export default grantConfig;
