import styled from 'styled-components';

export const WorkContainerStyled = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 30px;
`;

export const InfoRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 5px;
  /* gap: 20px; */
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  gap: 20px;
`;
