import documentsConfig from '@shared/apiConfigs/documents';
import { requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import TabComponent from '../../../designComponents/Tab';
import ClientDocuments, { ClientDocumentType } from './ClientDocuments';
import ClientGrants from './ClientGrants';
import ClientHistory from './ClientHistory';
import ClientInfo from './ClientInfo';
import ClientPayments from './ClientPayments';
import ClientStages from './ClientStages';
import { LeadContainer, LeadWrapperStyled } from './style';

const tabTitles = [
  'Info',
  'Grants',
  'Documents',
  'Stages',
  'Payments',
  'History',
];
const ViewDetailClient = () => {
  const [activeTab, setActiveTab] = useState(tabTitles[0]);
  const [documents, setDocuments] = useState<ClientDocumentType[]>([]);
  const { clientId } = useParams();

  const getAllDocuments = async () => {
    try {
      const { data } = await requestAPI(
        documentsConfig.getAllDocuments('', clientId)
      );
      setDocuments(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllDocuments();
  }, [clientId]);

  const handleTabChange = (label: string) => {
    setActiveTab(label);
  };
  return (
    <LeadWrapperStyled>
      <LeadContainer>
        <TabComponent
          tabTitles={tabTitles}
          activeTab={activeTab}
          onTabChange={handleTabChange}
        />{' '}
        {activeTab === 'Info' && (
          <div>
            <ClientInfo />
          </div>
        )}
        {activeTab === 'Grants' && (
          <div>
            <ClientGrants />
          </div>
        )}
        {activeTab === 'Documents' && (
          <div style={{ marginTop: '20px' }}>
            <ClientDocuments
              documents={documents}
              fetchDocuments={getAllDocuments}
            />
          </div>
        )}
        {activeTab === 'Stages' && (
          <div>
            <ClientStages />
          </div>
        )}
        {activeTab === 'History' && (
          <div>
            <ClientHistory />
          </div>
        )}
        {activeTab === 'Payments' && (
          <div>
            <ClientPayments />
          </div>
        )}
      </LeadContainer>
    </LeadWrapperStyled>
  );
};

export default ViewDetailClient;
