import documentsConfig from '@shared/apiConfigs/documents';
import { requestAPI } from '@shared/utils';
import { uploadImagesToS3 } from '@shared/utils/s3Upload';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { FaRegFilePdf } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import toastMessage from '../../../../constants/toastMessage';
import BreadCrumb from '../../../../designComponents/BreadCrumb';
import BrowseFile from '../../../../designComponents/BrowseFile';
import Button from '../../../../designComponents/Button';
import Input from '../../../../designComponents/Input';
import Select from '../../../../designComponents/SelectWithInput';
import Typography from '../../../../designComponents/Typography';
import {
  DOCUMENT_ROUTE,
  EDIT_DOCUMENT_ROUTE,
  SETTING_ROUTE,
} from '../../../../routes/routePaths';
// import PdfViewer from '../../../../designComponents/PdfViewer';
import Setting from '../..';
import {
  DocumentContainerStyled,
  DocumentWrapperStyled,
  InfoRowStyled,
} from './style';
// import { uploadImagesToS3 } from '@shared/utils/s3Upload';

type Props = {
  documentId?: string;
  closeModal?: React.Dispatch<React.SetStateAction<boolean>>;
  fetchDocuments?: () => Promise<void>;
};

const documentType = [
  { label: 'REPORT', value: 'REPORT' },
  { label: 'GRANT', value: 'GRANT' },
  { label: 'CONTRACT', value: 'CONTRACT' },
  { label: 'CERTIFICATE', value: 'CERTIFICATE' },
  { label: 'ADDITIONAL', value: 'ADDITIONAL' },
  { label: 'INVOICE', value: 'INVOICE' },
];
const EditDocument = (_props: Props) => {
  const { id: routeId } = useParams();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File[]>([]);
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);

  const [initialValues, setInitialValues] = useState({
    name: '',
    documentFileName: '',
    documentType: '',
    documentURL: '',
  });

  const getDocumentData = async () => {
    try {
      if (_props.documentId) {
        const data = await requestAPI(
          documentsConfig.getDocumentById(_props.documentId)
        );
        setInitialValues({
          name: data.name,
          documentFileName: data.documentFileName,
          documentType: data.documentType,
          documentURL: data.documentURL,
        });
      } else {
        const data =
          routeId &&
          (await requestAPI(documentsConfig.getDocumentById(routeId)));
        setInitialValues({
          name: data.name,
          documentFileName: data.documentFileName,
          documentType: data.documentType,
          documentURL: data.documentURL,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDocumentData();
  }, [routeId]);

  const handleUpdateDocument = async (data: any) => {
    try {
      if (_props.documentId && file.length > 0) {
        setLoading(true);
        const documentURL = await uploadImagesToS3(file);

        await requestAPI(
          documentsConfig.editDocumentById(
            {
              name: data.name,
              documentType: data.documentType,
              documentURL: documentURL && documentURL[0],
            },
            _props.documentId
          )
        );
        toast.success(toastMessage.documentUpdated);
        setLoading(false);
        _props.fetchDocuments && (await _props.fetchDocuments());
        _props.closeModal && _props.closeModal(false);
      } else {
        setLoading(true);
        const documentURL = await uploadImagesToS3(file);

        await requestAPI(
          documentsConfig.editDocumentById(
            {
              name: data.name,
              documentType: data.documentType,
              documentURL: documentURL && documentURL[0],
            },
            _props.documentId
          )
        );
        toast.success(toastMessage.documentUpdated);
        setLoading(false);
        _props.fetchDocuments && (await _props.fetchDocuments());
        _props.closeModal && _props.closeModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleAddFiles = (files: File[]) => {
    if (formikRef?.current && files.length > 0) {
      formikRef?.current?.setFieldValue('documentFileName', files[0].name);
    }
    setFile(files);
  };
  const handleRemove = () => {
    setFile([]);
    formikRef?.current?.setFieldValue('documentFileName', '');
  };
  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Documents',
      path: `${SETTING_ROUTE}/${DOCUMENT_ROUTE}`,
    },
    {
      label: 'Add Document',
      path: `${SETTING_ROUTE}/${DOCUMENT_ROUTE}/${EDIT_DOCUMENT_ROUTE}`,
    },
  ];
  return (
    <>
      {_props.documentId ? (
        <Formik
          initialValues={initialValues}
          onSubmit={handleUpdateDocument}
          enableReinitialize
          innerRef={formikRef}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <InfoRowStyled>
                  <Typography className="label">Document Title*</Typography>
                  <Input
                    name="name"
                    value={values.name}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                  />
                </InfoRowStyled>
                <InfoRowStyled>
                  <Typography className="label">Document Type*</Typography>
                  <Select
                    options={documentType}
                    onSelect={(selectedOption) => {
                      setFieldValue('documentType', selectedOption);
                    }}
                    selected={values.documentType}
                    placeholder="Type of Document"
                  />
                </InfoRowStyled>

                <InfoRowStyled>
                  <Typography>Upload Document*</Typography>
                  <Typography></Typography>
                  {values.documentURL ? (
                    <div style={{ position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          left: '55px',
                          top: '-8px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setInitialValues((initialValues) => ({
                            ...initialValues,
                            documentURL: '',
                          }));
                        }}
                      >
                        <IoClose size={20} />
                      </div>
                      <FaRegFilePdf size={60} color="red" />
                    </div>
                  ) : file.length === 0 ? (
                    <BrowseFile
                      files={[]}
                      setFiles={handleAddFiles}
                      accepts=".pdf"
                      isUploading={false}
                      showSelectFiles={false}
                    />
                  ) : (
                    <div style={{ marginTop: '10px' }}>
                      <Typography>Uploaded File:</Typography>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <div style={{ position: 'relative' }}>
                          <div
                            style={{
                              position: 'absolute',
                              left: '55px',
                              top: '-8px',
                              cursor: 'pointer',
                            }}
                            onClick={handleRemove}
                          >
                            <IoClose size={20} />
                          </div>
                          <FaRegFilePdf size={60} color="red" />
                        </div>
                        <Typography>{file[0].name}</Typography>
                      </div>
                    </div>
                  )}
                </InfoRowStyled>
                <Button
                  type="submit"
                  variant="primary"
                  isLoading={loading}
                  style={{ width: '150px', borderRadius: '5px' }}
                >
                  Update Document
                </Button>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <>
          <BreadCrumb breadCrumb={breadcrumbs} />
          <Setting>
            <DocumentContainerStyled>
              <Typography>New Document</Typography>
              <DocumentWrapperStyled>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleUpdateDocument}
                  enableReinitialize
                >
                  {({ values, handleSubmit, setFieldValue }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InfoRowStyled>
                          <Typography className="label">
                            Document Title*
                          </Typography>
                          <Input
                            name="name"
                            value={values.name}
                            onChange={(e) =>
                              setFieldValue('name', e.target.value)
                            }
                          />
                        </InfoRowStyled>
                        <InfoRowStyled>
                          <Typography className="label">
                            Document Type*
                          </Typography>
                          <Input
                            name="documentType"
                            value={values.documentType}
                            onChange={(e) =>
                              setFieldValue('documentType', e.target.value)
                            }
                          />
                        </InfoRowStyled>
                        <InfoRowStyled>
                          <Typography>Upload Document*</Typography>
                          <Typography></Typography>
                          {values.documentURL ? (
                            <div style={{ position: 'relative' }}>
                              <div
                                style={{
                                  position: 'absolute',
                                  left: '55px',
                                  top: '-8px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setInitialValues((initialValues) => ({
                                    ...initialValues,
                                    documentURL: '',
                                  }));
                                }}
                              >
                                <IoClose size={20} />
                              </div>
                              <FaRegFilePdf size={60} color="red" />
                            </div>
                          ) : file.length === 0 ? (
                            <BrowseFile
                              files={[]}
                              setFiles={handleAddFiles}
                              accepts=".pdf"
                              isUploading={false}
                              showSelectFiles={false}
                            />
                          ) : (
                            <div style={{ marginTop: '10px' }}>
                              {/* <Typography>Uploaded File:</Typography> */}
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <div style={{ position: 'relative' }}>
                                  <div
                                    style={{
                                      position: 'absolute',
                                      left: '55px',
                                      top: '-8px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={handleRemove}
                                  >
                                    <IoClose size={20} />
                                  </div>
                                  <FaRegFilePdf size={60} color="red" />
                                </div>
                                <Typography>{file[0].name}</Typography>
                              </div>
                            </div>
                          )}
                        </InfoRowStyled>
                        <Button
                          type="submit"
                          variant="secondary"
                          isLoading={loading}
                          style={{ width: '150px', borderRadius: '5px' }}
                        >
                          Update Document
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </DocumentWrapperStyled>
            </DocumentContainerStyled>
          </Setting>
        </>
      )}
    </>
  );
};

export default EditDocument;
