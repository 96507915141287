import grantsConfig from '@shared/apiConfigs/grants';
import stagesConfig from '@shared/apiConfigs/stages';
import theme from '@shared/theme';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteModal from '../../../../components/DeleteModal/deleteModal';
import toastMessage from '../../../../constants/toastMessage';
import Accordion from '../../../../designComponents/Accordion/Accordion';
import Button from '../../../../designComponents/Button';
import Divider from '../../../../designComponents/Divider';
import Input from '../../../../designComponents/Input';
import Modal from '../../../../designComponents/Modal';
import Select from '../../../../designComponents/SelectWithInput';
import Table, { TableDataType } from '../../../../designComponents/Table';
import Tag from '../../../../designComponents/Tag';
import Typography from '../../../../designComponents/Typography';
import EditWork from '../../../Settings/Stages/EditWork';
import {
  AccordianConatiner,
  ButtonWrapper,
  FormWrapper,
  WrapperStyled,
} from './style';
import validation from './validation';

type StageType = {
  _id: string;
  name: string;
  amount: string;
  status: string;
  id: number;
  __v: number;
};

const status = [
  {
    label: 'In Progress',
    value: 'In Progress',
  },
  {
    label: 'Completed',
    value: 'Completed',
  },
  {
    label: 'Delayed',
    value: 'Delayed',
  },
  {
    label: 'Not Started',
    value: 'Not Started',
  },
];

const ClientStages = () => {
  const [showAddRowInput, setShowAddRowInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stages, setStages] = useState<any[]>([]);
  const [stageId, setStageId] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { clientId } = useParams();
  const [grants, setGrants] = useState([]);

  const getClientAllGrants = async () => {
    try {
      const data =
        clientId &&
        (await requestAPI(grantsConfig.getGrantsByLeadId(clientId)));
      setGrants(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getClientAllGrants();
  }, []);

  const handleAddMoreRow = () => {
    setShowAddRowInput(true);
  };

  const handleCancelRow = () => {
    setShowAddRowInput(false);
  };

  const handleCreateWork = async (
    data: typeof initialValues,
    { resetForm }: { resetForm: any }
  ) => {
    try {
      setIsLoading(true);
      const workData =
        clientId &&
        (await requestAPI(
          stagesConfig.createStageByLeadId({
            leadId: clientId,
            name: data.name,
            amount: data.amount,
            status: data.status,
          })
        ));
      toast.success(toastMessage.workCreated);
      setStages((prev) => [
        ...prev,
        formatData(workData, setStageId, setShowEditModal, setShowDeleteModal),
      ]);
      resetForm();
      setIsLoading(false);
    } catch (error: any) {
      console.error('Client creation failed:', error);
      setIsLoading(false);
    }
  };

  const initialValues = {
    name: '',
    amount: '',
    status: '',
  };
  const tableData: TableDataType = {
    headings: [
      { key: 'id', label: 'Id' },

      {
        key: 'name',
        label: 'Title',
      },
      {
        key: 'amount',
        label: 'Amount',
      },

      {
        key: 'status',
        label: 'Status',
      },

      {
        key: 'action',
        label: 'Action',
      },
    ],
    body: stages,
  };

  const getStagesaData = async () => {
    try {
      const data =
        clientId && (await requestAPI(stagesConfig.getStageByLeadId(clientId)));
      const newData = data.data.map((stage: StageType) => {
        return formatData(
          stage,
          setStageId,
          setShowEditModal,
          setShowDeleteModal
        );
      });
      setStages(newData);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStagesaData();
  }, []);

  const deleteHandler = async () => {
    try {
      await requestAPI(stagesConfig.deleteStageById(stageId));
      setShowDeleteModal(false);
      toast.success('Deleted successfully');
      await getStagesaData();
      setShowAddRowInput(false);
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  const stageTotal = stages.reduce((prev: number, curr: StageType) => {
    const stage = curr.amount.split('€')?.[1];
    return prev + Number(stage);
  }, 0);

  const grantsTotal = grants.reduce((prev: number, curr: any) => {
    return prev + curr?.reliefAmount;
  }, 0);

  return (
    <WrapperStyled>
      <AccordianConatiner>
        <Accordion key="test">
          <Accordion.Header
            className="title"
            style={{
              backgroundColor: theme.colors.primary,
            }}
          >
            <Typography
              className="title-text"
              style={{ color: theme.colors.white }}
            >
              Work Include
            </Typography>
          </Accordion.Header>
          <Accordion.Content>
            <div style={{ padding: '10px' }}>
              {stages.length > 0 ? (
                <Table
                  tableData={tableData}
                  hidePagination={true}
                  showCheckBox={false}
                  hideSorting={true}
                />
              ) : (
                ''
              )}
              <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={handleCreateWork}
                validationSchema={validation}
              >
                {({ values, setFieldValue, handleSubmit, errors, touched }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      {showAddRowInput && (
                        <FormWrapper>
                          <Input
                            name="name"
                            placeholder="Title"
                            className="form-input"
                            value={values.name}
                            onChange={(e) =>
                              setFieldValue('name', e.target.value)
                            }
                            error={
                              touched.name && errors.name
                                ? !!errors.name
                                : false
                            }
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                              width: '100%',
                            }}
                          >
                            <Typography>€</Typography>
                            <Input
                              className="form-input"
                              placeholder="Amount"
                              name="amount"
                              value={values.amount}
                              type="number"
                              onChange={(e) =>
                                setFieldValue('amount', e.target.value)
                              }
                              error={
                                touched.amount && errors.amount
                                  ? !!errors.amount
                                  : false
                              }
                            />
                          </div>

                          <Select
                            placeholder="Status"
                            options={status}
                            selected={values.status}
                            onSelect={(selectedOption) =>
                              setFieldValue('status', selectedOption)
                            }
                            borderRadius="20px"
                          />

                          <ButtonWrapper>
                            <Button onClick={handleCancelRow}>Cancel</Button>

                            <Button type="submit" isLoading={isLoading}>
                              Save
                            </Button>
                          </ButtonWrapper>
                        </FormWrapper>
                      )}
                    </Form>
                  );
                }}
              </Formik>
              <Button onClick={handleAddMoreRow} style={{ marginTop: '10px' }}>
                Add More Row
              </Button>
            </div>
          </Accordion.Content>
        </Accordion>

        <Accordion key="test">
          <Accordion.Header
            className="title"
            style={{
              backgroundColor: theme.colors.primary,
            }}
          >
            <Typography
              className="title-text"
              style={{ color: theme.colors.white }}
            >
              Grants
            </Typography>
          </Accordion.Header>
          <Accordion.Content>
            <div style={{ padding: '10px' }}>
              <div className="grant-data">
                <div className="grant-title">
                  <Typography>Grant Title</Typography>
                  <Typography>Amount</Typography>
                  <Typography>Status</Typography>
                </div>
                <div>
                  {grants.map((grant: any, idx) => (
                    <div className="grant-with-data" key={idx}>
                      <Typography>
                        {grant?.buildingTypeId?.buildingName}
                      </Typography>
                      <Typography>{`€ ${grant?.reliefAmount}`}</Typography>
                      <Tag
                        type={
                          grant?.grantStatus === 'IN PROGRESS'
                            ? 'caution'
                            : 'success'
                        }
                      >
                        {grant?.grantStatus}
                      </Tag>
                    </div>
                  ))}
                </div>
              </div>

              <div className="total">
                <Typography strong variant="title">
                  GRANT RELIEF TOTAL COST
                </Typography>
                <Typography strong variant="title">
                  € {grantsTotal}
                </Typography>
                <Typography></Typography>
              </div>
            </div>
          </Accordion.Content>
        </Accordion>
        <Accordion key="test">
          <Accordion.Header
            className="title"
            style={{
              backgroundColor: theme.colors.primary,
            }}
          >
            <Typography
              className="title-text"
              style={{ color: theme.colors.white }}
            >
              Summary
            </Typography>
          </Accordion.Header>
          <Accordion.Content>
            <div className="summary">
              <div className="work-total" style={{ marginBottom: '10px' }}>
                <Typography>Works Total Cost</Typography>
                <Typography>€ {stageTotal}</Typography>
              </div>
              <div className="work-total">
                <Typography>Grant Relief Total Cost</Typography>
                <Typography>€ {grantsTotal}</Typography>
              </div>
              <Divider />

              <div className="work-total">
                <Typography strong variant="title">
                  BALANCE TO PAY
                </Typography>
                <Typography strong variant="title">
                  € {stageTotal + grantsTotal}
                </Typography>
              </div>
            </div>
          </Accordion.Content>
        </Accordion>
      </AccordianConatiner>
      {/* <Grants /> */}
      <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <EditWork
          stageId={stageId}
          fetchStages={getStagesaData}
          closeModal={setShowEditModal}
        />
      </Modal>
      <DeleteModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        desc="Are you sure want to delete? "
        title="Confirm delete stage"
        warning="This can't be undone"
        onConfirm={() => deleteHandler()}
      />
    </WrapperStyled>
  );
};

export default ClientStages;

const formatData = (
  stage: any,
  setStageId: React.Dispatch<React.SetStateAction<string>>,
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return {
    name: stage.name,
    amount: `€ ${stage.amount}`,
    // status: stage.status,
    status: (
      <Select
        options={status}
        selected={stage.status}
        onSelect={() => {}}
        borderRadius="20px"
      />
    ),

    action: (
      <div
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <Button
          variant="update"
          outline
          onClick={() => {
            setStageId(stage._id);
            setShowEditModal(true);
          }}
        >
          Edit
        </Button>
        <Button
          variant="danger"
          outline
          onClick={() => {
            setStageId(stage._id);
            setShowDeleteModal(true);
          }}
        >
          Delete
        </Button>
      </div>
    ),
  };
};
