import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import escConstants from '../../constants';
import { useRoot } from '../../context/RootProvider';
import { DASHBOARD_ROUTE } from '../../routes/routePaths';

export interface ICheckAuthProps {
  children: React.ReactNode;
  redirectTo?: string;
}
// used in public pages route, if user is logged in user redirected to provided route
const CheckAuthElement = ({
  redirectTo = DASHBOARD_ROUTE,
  children,
}: ICheckAuthProps) => {
  const token = localStorage.getItem(escConstants.LOCAL_STORAGE_KEY.accessKey);

  const { auth: user } = useRoot();
  const userId = user?._id;

  if (!!token && !!userId) {
    return <Navigate to={redirectTo} replace />;
  }
  return children || <Outlet />;
};

export default CheckAuthElement;
