import { ConfigType, ParamsType } from "../utils/requestAPI";

type CreateClientDataType = {
  leadId: string;
};
export type CreateClientManuallyDataType = {
  addressOne?: string;
  addressTwo?: string;
  country?: string;
  cityId?: string;
  eirCode?: string;
  typeOfProperty?: string;
  ageOfProperty?: string;
  ber?: string;
  mprn?: string;
  email?: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  emailNotification?: boolean;
};

const clientsConfig = {
  getAllClients: (
    params: ParamsType & {
      isClient: boolean;
    }
  ): ConfigType => {
    if (params.search) {
      params.search = params.search;
    }
    return {
      method: "get",
      url: `/leads`,
      params,
    };
  },
  getClientById: (id: string): ConfigType => ({
    method: "get",
    url: `/leads/${id}`,
  }),
  getActivityByClientId: (clientId: string, limit = 10): ConfigType => ({
    method: "get",
    url: `/activities?leadId=${clientId}&limit=${limit}`,
  }),

  updateClient: (
    id: string,
    data: CreateClientManuallyDataType
  ): ConfigType => ({
    method: "put",
    data,
    url: `/leads/${id}`,
  }),

  deleteClientById: (id: string): ConfigType => ({
    method: "delete",
    url: `/leads/${id}`,
  }),
  createClient: (data: CreateClientDataType): ConfigType => ({
    method: "post",
    data,
    url: "/leads/",
  }),
  createClientManually: (data: CreateClientManuallyDataType): ConfigType => ({
    method: "post",
    data,
    url: "/leads/client-by-admin",
  }),
};

export default clientsConfig;
