import transactionsConfig from '@shared/apiConfigs/transactions';
import { humanReadableDateWithTime, requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { MdOutlineDateRange } from 'react-icons/md';
import { useParams } from 'react-router';
import { useTheme } from 'styled-components';

import SuccessSVG from '../../../../assets/success.svg';
import Table, { TableDataType } from '../../../../designComponents/Table';
import Typography from '../../../../designComponents/Typography';

interface PaymentDataType {
  amount: number;
  createdAt: string;
  currency: string;
  description: string;
  paymentId: string;
  updatedAt: string;
  user: string;
  _id: string;
}
const ClientPayments = () => {
  const { clientId } = useParams();
  const [payments, setPayments] = useState([]);
  const tableData: TableDataType = {
    headings: [
      { key: 'id', label: '' },
      { key: 'createdAt', label: 'Payment Date' },
      {
        key: 'description',
        label: 'Description',
      },
      {
        key: 'amount',
        label: 'Amount',
      },
      {
        key: 'currency',
        label: 'Currency',
      },
      {
        key: 'status',
        label: 'Status',
      },
    ],
    body: formattedTableData(payments),
  };

  formattedTableData(payments);
  const getTransactions = async (id: any) => {
    try {
      const data = await requestAPI(
        transactionsConfig.getTransactionByLeadId(id)
      );
      setPayments(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTransactions(clientId);
  }, [clientId]);
  return (
    <div>
      <Table tableData={tableData} hideSorting />
    </div>
  );
};

export default ClientPayments;

const formattedTableData = (data: PaymentDataType[]) => {
  const newData = data.map((item) => ({
    createdAt: <FormattedDate date={item.createdAt} />,
    description: item.description,
    amount: item.amount,
    currency: item.currency.toUpperCase(),
    status: <FormatStatus />,
    id: item._id,
  }));

  return newData;
};

const FormattedDate = ({ date }: { date: string }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
      }}
    >
      {' '}
      <MdOutlineDateRange size={25} />
      {humanReadableDateWithTime(date)}
    </div>
  );
};

const FormatStatus = () => {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
      <img src={SuccessSVG} alt="success" />
      <Typography style={{ color: theme.colors.primary }}>
        Payment Success
      </Typography>
    </div>
  );
};
