// src/Select.tsx
import useDetectOutsideClick from '@shared/hooks/useDetectOutsideClick';
import { selectDisable } from '@shared/style/reusableStyle';
import React, { useEffect, useRef, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { MdArrowDropDown } from 'react-icons/md';
import styled, { useTheme } from 'styled-components';

import Input from '../Input';
import Typography from '../Typography';
import { OptionsContainerStyled, SelectWrapper } from './style';

export type OptionType = { label: string; value: string; record?: any };

interface SelectProps {
  options: OptionType[];
  onSelect: (selectedOptions: OptionType[]) => void;
  selected: OptionType[];
  placeholder?: string;
  onChange?: boolean;
  searchTerm?: string;
  style?: React.CSSProperties;
  containerStyle?: boolean;
  disabled?: boolean;
  borderRadius?: string;
}

const MultiSelect: React.FC<SelectProps> = ({
  options,
  onSelect,
  placeholder = 'Select',
  selected,
  onChange,
  disabled,
  borderRadius,
}) => {
  const selectWrapperRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const [isActive, setIsActive] = useDetectOutsideClick(
    selectWrapperRef,
    false
  );
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const handleDropdownClick = () => {
    setIsActive((prev: any) => !prev);
    if (!isActive) {
      setFilteredOptions(options);
    }
  };

  const handleOptionClick = (option: OptionType) => {
    if (selected.includes(option)) {
      const newSelectedOptions = selected.filter(
        (selectedOption) => selectedOption.value !== option.value
      );
      onSelect(newSelectedOptions);
    } else {
      onSelect([...selected, option]);
    }
  };

  const handleOptionDoubleClick = (option: OptionType) => {
    const newSelectedOptions = selected.filter(
      (selectedOption) => selectedOption.value !== option.value
    );
    onSelect(newSelectedOptions);
  };

  // const getSelectedValueLabel = () => {
  //   return selected.map((option) => option.label).join(', ');
  // };

  return (
    <StyledDiv
      style={{
        position: 'relative',
        width: '100%',
        opacity: disabled ? '0.5' : '1',
      }}
      ref={selectWrapperRef}
    >
      <SelectWrapper
        {...(!disabled && { onClick: handleDropdownClick })}
        $borderRadius={borderRadius ?? ''}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
          {selected.map((option) => (
            <Chip
              key={option.value}
              onDoubleClick={() => handleOptionDoubleClick(option)}
            >
              {option.label}
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionClick(option);
                }}
              >
                ×
              </span>
            </Chip>
          ))}
          <Typography style={{ padding: '0px 13px' }}>
            {selected.length === 0 && (
              <p style={{ color: theme.colors.disableText }}>{placeholder}</p>
            )}
          </Typography>
        </div>
        <MdArrowDropDown size={22} />
      </SelectWrapper>

      {!disabled && isActive && (
        <OptionsContainerStyled>
          {onChange && (
            <Input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
              iconLeft={<CiSearch />}
            />
          )}
          <ul>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li
                  className="option"
                  key={option.value}
                  onClick={() => handleOptionClick(option)}
                  onDoubleClick={() => handleOptionDoubleClick(option)}
                  // style={{
                  //   backgroundColor: selected.includes(option)
                  //     ? '#d3d3d3'
                  //     : 'transparent',
                  // }}
                >
                  {option.label}
                </li>
              ))
            ) : (
              <Typography>No more user remain in this role</Typography>
            )}
          </ul>
        </OptionsContainerStyled>
      )}
    </StyledDiv>
  );
};

export default MultiSelect;

const StyledDiv = styled.div`
  ${selectDisable}
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  background-color: #e0e0e0;
  border-radius: 12px;
  margin-right: 4px;
  span {
    margin-left: 8px;
    cursor: pointer;
  }
`;
