import settingConfig from '@shared/apiConfigs/settings';
import { requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Flex from '../../../designComponents/Flex';
import Table, { TableDataType } from '../../../designComponents/Table';
import Typography from '../../../designComponents/Typography';
import {
  ADD_LEAD_PRICE_ROUTE,
  LEAD_PRICE_ROUTE,
  SETTING_ROUTE,
} from '../../../routes/routePaths';
import Setting from '..';
import { LeadPriceStyled } from './style';

type LeadPriceType = {
  _id: string;
  price: number;
  currency: string;
  isSelectedPrice: boolean;
};

const LeadPrice = () => {
  const navigate = useNavigate();

  const [prices, setPrices] = useState<LeadPriceType[]>([]);

  const fetchPrices = async () => {
    const result = await requestAPI(settingConfig.getAllPrice());
    if (result) {
      setPrices(result);
    }
  };
  useEffect(() => {
    fetchPrices();
  }, []);

  const selectPrice = async (id: string) => {
    try {
      if (id) {
        await requestAPI(settingConfig.changeSelectedPrice({ id }));
        fetchPrices();
        toast('New Price selected for lead payment link creation.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectedPrice = prices.find((x) => x.isSelectedPrice) || {
    price: '',
    currency: '',
  };

  const priceTable: TableDataType = {
    headings: [
      {
        key: 'sn',
        label: 'SN',
      },
      {
        key: 'price',
        label: 'Price',
      },
      {
        key: 'currency',
        label: 'Currency',
      },
      {
        key: 'actions',
        label: 'Action',
      },
    ],
    body: prices.map((price, index) => ({
      id: price._id,
      sn: index + 1,
      price: price.price,
      currency: price.currency,
      actions: price.isSelectedPrice ? (
        <Typography
          style={{
            padding: '5px 10px',
            backgroundColor: 'green',
            display: 'inline-block',
            borderRadius: '16px',
            color: '#fff',
          }}
          size={12}
        >
          SELECTED
        </Typography>
      ) : (
        <Button
          variant="secondary"
          outline
          size="sm"
          style={{ fontSize: '16px', padding: '0px 18px' }}
          onClick={() => selectPrice(price._id)}
        >
          Select
        </Button>
      ),
    })),
  };
  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Lead Price',
      path: `${SETTING_ROUTE}/${LEAD_PRICE_ROUTE}`,
    },
  ];

  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />

      <Setting>
        <LeadPriceStyled>
          <Typography variant="heading">
            Lead Prices : {selectedPrice?.price} {selectedPrice?.currency}
          </Typography>
          <Flex justifyContent="flex-end" margin="0px 0px 30px 0px">
            <Button
              variant="primary"
              onClick={() => navigate(ADD_LEAD_PRICE_ROUTE)}
            >
              Add Price
            </Button>
          </Flex>
          <Table hideSorting tableData={priceTable} showCheckBox={false} />
        </LeadPriceStyled>
      </Setting>
    </>
  );
};

export default LeadPrice;
