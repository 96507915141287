import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface ToggleProps {
  initialValue?: boolean;
  onChange: (value: boolean) => void;
}

const Toggle: React.FC<ToggleProps> = ({ initialValue = false, onChange }) => {
  const [isChecked, setIsChecked] = useState(initialValue);
  useEffect(() => {
    setIsChecked(initialValue);
  }, [initialValue]);
  const handleClick = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    onChange(newValue);
  };

  return (
    <ToggleContainerStyled $isChecked={isChecked}>
      <ButtonStyled
        onClick={handleClick}
        style={{
          color: !isChecked ? 'red' : 'black',
          zIndex: 100,
        }}
        $isChecked={!isChecked}
        type="button"
      >
        Not Approved
      </ButtonStyled>
      <ButtonStyled $isChecked={isChecked} onClick={handleClick} type="button">
        Approved
      </ButtonStyled>
    </ToggleContainerStyled>
  );
};

export default Toggle;

const ButtonStyled = styled.button<{ $isChecked: boolean }>`
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  z-index: 100;
  width: 110px;
  text-align: center;
  color: ${({ $isChecked }) => ($isChecked ? 'green' : 'black')};
`;

const ToggleContainerStyled = styled.div<{ $isChecked: boolean }>`
  padding: 10px 10px;
  background-color: ${({ theme }) => theme.colors.defaultButton};
  color: black;
  border: none;
  border-radius: 12px;
  box-shadow: inherit;
  display: flex;
  gap: 10px;
  position: relative;
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    transition: all 0.3s ease-in-out;
    width: calc(50% - 10px);
    transform: translateX(${({ $isChecked }) => ($isChecked ? '100%' : '0%')});
    z-index: 10;
    bottom: 8px;
    top: 8px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 8px;
  }
`;
