import settingConfig from '@shared/apiConfigs/settings';
import { requestAPI } from '@shared/utils';
import { ErrorMessage, Form, Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import AlertComponent from '../../../designComponents/Alert';
import Button from '../../../designComponents/Button';
import Flex from '../../../designComponents/Flex';
import Input from '../../../designComponents/Input';
import Select from '../../../designComponents/SelectWithInput';
import Typography from '../../../designComponents/Typography';
import Setting from '..';
import { LeadPriceStyled } from './style';

const initialPriceState = {
  price: 300,
  currency: 'EUR',
};
type InitialValueType = typeof initialPriceState;

const validationSchema = yup.object({
  price: yup.number().required('Price is required.'),
  currency: yup.string().required('Currency is required.'),
});

const AddLeadPrice = () => {
  const formikRef = useRef<FormikProps<InitialValueType>>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState('');

  const handleCreateNewPrice = async (values: InitialValueType) => {
    try {
      setLoading(true);
      await requestAPI(
        settingConfig.createLeadPrice({
          currency: values.currency,
          price: values.price,
        })
      );
      setLoading(false);
      navigate(-1);
    } catch (error: any) {
      console.error(error);
      if (typeof error === 'string') setErrorMessages(error);

      if (error.error && typeof error.error === 'string')
        setErrorMessages(error.error);
      setLoading(false);
    }
  };

  return (
    <Setting>
      <LeadPriceStyled>
        <Typography variant="heading" style={{ margin: '0px 0px 30px ' }}>
          Add Lead Price
        </Typography>
        <Formik
          innerRef={formikRef}
          initialValues={initialPriceState}
          onSubmit={handleCreateNewPrice}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Flex direction="column" gap="12px" margin="0px 0px 20px">
                  <Typography>Price</Typography>
                  <Input
                    placeholder="Enter lead price"
                    name="price"
                    type="number"
                    value={values.price}
                    onChange={(e) => setFieldValue('price', e.target.value)}
                  />
                  <ErrorMessage name="price" component={Typography} />
                </Flex>
                <Flex direction="column" gap="12px">
                  <Typography>Currency</Typography>
                  <Select
                    placeholder="Enter lead price currency"
                    selected={values.currency}
                    onSelect={(text) => setFieldValue('currency', text)}
                    options={[
                      // { label: 'AED', value: 'AED' },
                      // { label: 'AUD', value: 'AUD' },
                      // { label: 'CAD', value: 'CAD' },
                      { label: 'EUR', value: 'EUR' },
                      { label: 'USD', value: 'USD' },
                    ]}
                  />
                  <ErrorMessage name="currency" />
                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  margin="40px 0px 0px"
                  gap="30px"
                  wrap="wrap"
                >
                  <Button variant="white" outline onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary" isLoading={loading}>
                    Create Price
                  </Button>
                </Flex>
                <div style={{ marginTop: '30px' }}>
                  {errorMessages && (
                    <AlertComponent text={errorMessages} type="error" />
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </LeadPriceStyled>
    </Setting>
  );
};

export default AddLeadPrice;
