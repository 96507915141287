import cityConfig from '@shared/apiConfigs/cities';
import clientsConfig from '@shared/apiConfigs/clients';
import leadsConfig from '@shared/apiConfigs/leads';
// import leadsConfig from '@shared/apiConfigs/leads';
import paymentAgreementConfig from '@shared/apiConfigs/paymentAgreement';
import { VerticalSpaceStyled } from '@shared/style/reusableStyle';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteModal from '../../../../components/DeleteModal/deleteModal';
import toastMessage from '../../../../constants/toastMessage';
import { useRoot } from '../../../../context/RootProvider';
import Button from '../../../../designComponents/Button';
import Checkbox from '../../../../designComponents/Checkbox';
import Input from '../../../../designComponents/Input';
import Select from '../../../../designComponents/SelectWithInput';
import Typography from '../../../../designComponents/Typography';
import AssignClient from './assignClient';
import {
  FormContainerStyled,
  InfoRowStyled,
  LeadContainer,
  LeadWrapperStyled,
} from './style';

type cityType = {
  _id: string;
  value: string;
  label: string;
  name: string;
};

export type ClientData = {
  _id: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  claimAccountLinkSentAt: Date;
  assessmentDate?: string;
  isPhoned?: boolean;
  isAssessmentGrantApproved?: boolean;
  assignLead?: {
    createdAt: string;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    updatedAt: string;
    __v: 0;
    _id: string;
  }[];
};

const ageOfProperty = [
  { label: 'Pre 1990', value: 'Pre 1990' },
  { label: '1960 1980', value: '1960 1980' },
  { label: '1980 2000', value: '1980 2000' },
  { label: '2001 2011', value: '2001 2011' },
  { label: '2011 onwards', value: '2011 onwards' },
  { label: "Don't Know", value: "Don't know" },
  { label: "Don't know the year", value: "Don't know the year0" },
];

const ber = [
  { label: 'A1', value: 'A1' },
  { label: 'A2', value: 'A2' },
  { label: 'A3', value: 'A3' },
  { label: 'B1', value: 'B1' },
  { label: 'B2', value: 'B2' },
  { label: 'B3', value: 'B3' },
  { label: 'C1', value: 'C1' },
  { label: 'C2', value: 'C2' },
  { label: 'C3', value: 'C3' },
  { label: 'D1', value: 'D1' },
  { label: 'D2', value: 'D2' },
  { label: 'E1', value: 'E1' },
  { label: 'E2', value: 'E2' },
  { label: 'F', value: 'F' },
  { label: 'G', value: 'G' },
  { label: "I don't know my rating", value: "I don't know my rating" },
];
const typeOfProperty = [
  { label: 'Bungalow', value: 'Bungalow' },
  { label: 'Detached', value: 'Detached' },
  { label: 'Semi Detached', value: 'Semi Detached' },
  { label: 'Mid Terrace', value: 'Mid Terrace' },
  { label: 'Apartment', value: 'Apartment' },
  { label: 'Multi Unit', value: 'Multi Unit' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Others', value: 'Others' },
];

const initialData = {
  firstName: '',
  lastName: '',
  mobileNumber: '',
  email: '',
  addressOne: '',
  addressTwo: '',
  typeOfProperty: '',
  ageOfProperty: '',
  cityId: '',
  eirCode: '',

  ber: '',
  mprn: '',
  emailNotification: false,
  leadCallDateTime: '',
  claimAccountLinkSentAt: '',
  interestedWork: '',
};
type ValuesType = typeof initialData;
const ClientInfo = () => {
  const { clientId } = useParams();
  const [cities, setCities] = useState([{ value: '', label: '' }]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState(initialData);
  const [clientData, setClientData] = useState<ClientData>();
  const [editMode, setEditMode] = useState(false);
  const [assessmentDate, setAssessmentDate] = useState('');
  const [grantChecked, setGrantChecked] = useState(false);
  const [phoneChecked, setPhoneChecked] = useState(false);
  const [phoneNote, setPhoneNote] = useState('');
  const [isClicked, setIsClicked] = useState(false);

  const { auth } = useRoot();

  const deleteHandler = async () => {
    try {
      if (clientId) {
        await requestAPI(leadsConfig.deleteLeadById(clientId));
        toast.success(toastMessage.clientDeleted);
        setOpenModal(false);
        navigate(-1);
      }
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  const handleGrantCheckboxChange = () => {
    setGrantChecked(!grantChecked);
  };

  const handlePhoneCheckboxChange = () => {
    setPhoneChecked(!phoneChecked);
  };
  useEffect(() => {
    if (clientData?.assessmentDate) {
      setAssessmentDate(
        moment(clientData.assessmentDate).format('YYYY-MM-DDTHH:mm')
      );
    }
  }, [clientData?.assessmentDate]);
  const getClientDetail = async (id: string) => {
    try {
      const data = await requestAPI(clientsConfig.getClientById(id));

      setClientData(data);

      if (data?.phoneCallNotes) {
        setPhoneNote(data.phoneCallNotes);
      }

      setInitialValues({
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        mobileNumber: data.mobileNumber || '',
        email: data.email || '',
        addressOne: data.addressOne || '',
        addressTwo: data.addressTwo || '',
        typeOfProperty: data.typeOfProperty || '',
        ageOfProperty: data.ageOfProperty || '',
        cityId: data.cityId || '',
        eirCode: data.eirCode || '',
        ber: data.ber || '',
        mprn: data.mprn || '',
        emailNotification: data.emailNotification || false,
        leadCallDateTime: data.leadCallDateTime || '',
        claimAccountLinkSentAt: data.claimAccountLinkSentAt || '',
        interestedWork: data.interestedWork || '',
      });
      // }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (typeof clientData?.isPhoned === 'boolean') {
      setPhoneChecked(clientData?.isPhoned);
    }
    if (typeof clientData?.isAssessmentGrantApproved === 'boolean') {
      setGrantChecked(clientData?.isAssessmentGrantApproved);
    }
  }, [clientData?.isPhoned, clientData?.isAssessmentGrantApproved]);
  useEffect(() => {
    if (clientId) {
      getClientDetail(clientId);
    }
  }, [clientId]);

  const handleEditInformation = async (values: ValuesType) => {
    try {
      await requestAPI(
        clientsConfig.updateClient(clientData!._id, {
          firstName: values.firstName,
          lastName: values.lastName,
          mobileNumber: values.mobileNumber,
          addressOne: values.addressOne,
          addressTwo: values.addressTwo,
          typeOfProperty: values.typeOfProperty,
          ageOfProperty: values.ageOfProperty,
          cityId: values.cityId,
          eirCode: values.eirCode,
          ber: values.ber,
          mprn: values.mprn,
          emailNotification: values.emailNotification,
        })
      );
      toast.success('Updated successfully');
    } catch (error) {
      toast.error('Something went wrong, please try again');
      console.error(error);
    }
  };
  const handleAssessmentGrantApprovalChange = async (checked: boolean) => {
    try {
      clientId &&
        (await requestAPI(
          leadsConfig.updateLeadById({}, clientId, undefined, checked)
        ));
      toast.success('Grant approval updated successfully');
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong, please try again');
    }
  };

  const handleAssessmentDateSave = async (assessmentDate: string) => {
    const date = new Date();
    const newAssessmentDate = new Date(assessmentDate);
    if (newAssessmentDate < date) {
      toast.error('Past dates are unavailable');
    } else {
      try {
        clientId &&
          (await requestAPI(
            leadsConfig.updateLeadById(
              {
                assessmentDate,
              },
              clientId
            )
          ));
        toast.success('Date saved successfully');
      } catch (error) {
        console.error(error);
        toast.error('Something went wrong, please try again');
      }
    }
  };

  const handlePhoneApprovalChange = async (checked: boolean) => {
    try {
      if (clientId) {
        await requestAPI(leadsConfig.updateLeadById({}, clientId, checked));
        toast.success('Phone approval updated successfully');
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong, please try again');
    }
  };

  const handlePhoneNote = async (note: string) => {
    try {
      if (clientId && phoneNote) {
        await requestAPI(
          leadsConfig.updateLeadById(
            {
              phoneCallNotes: note,
            },
            clientId,
            phoneChecked,
            grantChecked
          )
        );
        toast.success('Phone note updated successfully');
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong, please try again');
    }
  };
  const handleClaimAccount = async (email: string) => {
    try {
      await requestAPI(paymentAgreementConfig.claimAccountLink(email));
      toast.success('Link send successfully');
    } catch (error) {
      toast.error('Something went wrong, please try again');
      console.error(error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await requestAPI(
        cityConfig.getAllCities({
          limit: 100,
        })
      );
      const data = response.data;
      const newData = data.map((item: cityType) => {
        return {
          value: item._id,
          label: item.name,
        };
      });
      setCities(newData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <LeadWrapperStyled>
      <LeadContainer>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values) => handleEditInformation(values)}
        >
          {({ values, setFieldValue, handleSubmit, resetForm }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '30px',
                    paddingBlock: '10px',
                  }}
                >
                  {editMode ? (
                    <Typography
                      style={{
                        fontSize: '22px',
                        fontWeight: '600',
                        marginBottom: '15px',
                      }}
                    >
                      Edit Information
                    </Typography>
                  ) : (
                    <Typography
                      style={{
                        fontSize: '22px',
                        fontWeight: '600',
                        marginBottom: '15px',
                      }}
                    >
                      Information
                    </Typography>
                  )}
                  {!editMode && (
                    <div style={{ display: 'flex', gap: '20px' }}>
                      <Button
                        onClick={() =>
                          clientData?.email &&
                          handleClaimAccount(clientData.email)
                        }
                        variant="primary"
                      >
                        Claim account link sent
                      </Button>
                      <Button
                        variant="update"
                        outline
                        onClick={() => setEditMode(true)}
                      >
                        Edit Info
                      </Button>
                      <Button
                        variant="danger"
                        outline
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenModal(true);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                  {editMode && (
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Button
                        onClick={() => {
                          setEditMode(false);
                          resetForm();
                        }}
                        style={{
                          border: '1px solid gray',
                          borderRadius: '3px',
                          width: '150px',
                          background: 'white',
                          color: 'black',
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        onClick={() => {
                          setEditMode(false);
                          handleEditInformation(values);
                        }}
                        variant="primary"
                        style={{
                          width: '150px',
                        }}
                      >
                        Update Client
                      </Button>
                    </div>
                  )}
                </div>
                <FormContainerStyled>
                  <InfoRowStyled>
                    <Typography className="label">Address 1</Typography>
                    <Input
                      value={values.addressOne}
                      onChange={(e) =>
                        setFieldValue('addressOne', e.target.value)
                      }
                      disabled={!editMode}
                      containerStyle={{ border: !editMode ? 'none' : '' }}
                      style={{ opacity: editMode ? '1' : '0.5' }}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="label">Address 2</Typography>
                    <Input
                      value={values.addressTwo}
                      onChange={(e) =>
                        setFieldValue('addressTwo', e.target.value)
                      }
                      disabled={!editMode}
                      containerStyle={{ border: !editMode ? 'none' : '' }}
                      style={{ opacity: editMode ? '1' : '0.5' }}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="label">City/County</Typography>
                    <Select
                      selected={values.cityId || ''}
                      options={cities}
                      onSelect={(selectOption: any) =>
                        setFieldValue('cityId', selectOption)
                      }
                      disabled={!editMode}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="label">EirCode</Typography>

                    <Input
                      value={values.eirCode}
                      onChange={(e) => setFieldValue('eirCode', e.target.value)}
                      disabled={!editMode}
                      containerStyle={{ border: !editMode ? 'none' : '' }}
                      style={{ opacity: editMode ? '1' : '0.5' }}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="label">Type of Property</Typography>
                    <Select
                      selected={values.typeOfProperty || ''}
                      options={typeOfProperty}
                      onSelect={(selectOption: any) =>
                        setFieldValue('typeOfProperty', selectOption)
                      }
                      disabled={!editMode}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="label">Age of Property</Typography>
                    <Select
                      selected={values.ageOfProperty || ''}
                      options={ageOfProperty}
                      onSelect={(selectOption: any) =>
                        setFieldValue('ageOfProperty', selectOption)
                      }
                      disabled={!editMode}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="label">BER</Typography>
                    <Select
                      selected={values.ber || ''}
                      options={ber}
                      onSelect={(selectOption: any) =>
                        setFieldValue('ber', selectOption)
                      }
                      disabled={!editMode}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="label">FirstName</Typography>

                    <Input
                      value={values.firstName}
                      onChange={(e) =>
                        setFieldValue('firstName', e.target.value)
                      }
                      disabled={!editMode}
                      containerStyle={{ border: !editMode ? 'none' : '' }}
                      style={{ opacity: editMode ? '1' : '0.5' }}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="label">LastName</Typography>
                    <Input
                      value={values.lastName}
                      onChange={(e) =>
                        setFieldValue('lastName', e.target.value)
                      }
                      disabled={!editMode}
                      containerStyle={{ border: !editMode ? 'none' : '' }}
                      style={{ opacity: editMode ? '1' : '0.5' }}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="label">Mobile Number</Typography>
                    <Input
                      value={values.mobileNumber}
                      onChange={(e) =>
                        setFieldValue('mobileNumber', e.target.value)
                      }
                      disabled={!editMode}
                      containerStyle={{ border: !editMode ? 'none' : '' }}
                      style={{ opacity: editMode ? '1' : '0.5' }}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="label">Email</Typography>
                    <Input
                      value={values.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      disabled
                      containerStyle={{ border: !editMode ? 'none' : '' }}
                      style={{ opacity: editMode ? '1' : '0.5' }}
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <Typography className="label">MPRN</Typography>
                    <Input
                      value={values.mprn}
                      onChange={(e) => setFieldValue('mprn', e.target.value)}
                      disabled={!editMode}
                      containerStyle={{ border: !editMode ? 'none' : '' }}
                      style={{ opacity: editMode ? '1' : '0.5' }}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <Typography className="label">
                      HOME BUILT PRIOR TO 2010
                    </Typography>
                    <Input
                      value="Unsure"
                      disabled={!editMode}
                      containerStyle={{ border: !editMode ? 'none' : '' }}
                      style={{ opacity: editMode ? '1' : '0.5' }}
                    />
                  </InfoRowStyled>
                </FormContainerStyled>
              </Form>
            );
          }}
        </Formik>
      </LeadContainer>
      <LeadContainer>
        <Typography
          style={{
            fontSize: '22px',
            fontWeight: '600',
            marginBottom: '15px',
          }}
        >
          Assessment Grant
        </Typography>
        <VerticalSpaceStyled $space={14}>
          <InfoRowStyled>
            <Typography className="label">Assessment Grant Approval</Typography>
            <Checkbox
              isChecked={grantChecked}
              value=""
              onChange={(e) => {
                handleAssessmentGrantApprovalChange(e.target.checked);
                handleGrantCheckboxChange();
              }}
            />
          </InfoRowStyled>
          <InfoRowStyled>
            <Typography className="label">Grant Receipt</Typography>
            {/* TODO: To be displayed appropriate response after modifications in backend. Using static for now */}
            <Typography className="label">
              Grant Receipt not generated
            </Typography>
          </InfoRowStyled>
          <InfoRowStyled>
            <Typography className="label">Assessment Date</Typography>
            <Input
              type="datetime-local"
              // value={values.claimAccountLinkSentAt}
              value={assessmentDate}
              onChange={(e) => setAssessmentDate(e.target.value)}
              disabled={false}
              style={{ flex: 1 }}
            />
            <Button
              variant="primary"
              style={{ marginLeft: '20px' }}
              onClick={() => {
                handleAssessmentDateSave(assessmentDate);
              }}
            >
              Save
            </Button>
          </InfoRowStyled>
        </VerticalSpaceStyled>
      </LeadContainer>
      <LeadContainer>
        <Typography
          style={{
            fontSize: '22px',
            fontWeight: '600',
            marginBottom: '15px',
          }}
        >
          Phone Call
        </Typography>
        <InfoRowStyled>
          <Typography
            style={{
              fontSize: '16px',
              marginRight: '10px',
              minWidth: 'fit-content',
            }}
            className="label"
          >
            Has Called Via Phone
          </Typography>
          <Checkbox
            isChecked={phoneChecked}
            value=""
            onChange={(e) => {
              handlePhoneApprovalChange(e.target.checked);
              handlePhoneCheckboxChange();
            }}
          />
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography style={{ marginTop: '20px' }} className="label">
            Phone Notes
          </Typography>
        </InfoRowStyled>
        <InfoRowStyled
          style={{
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'flex-start',
          }}
        >
          <textarea
            style={{
              marginTop: '20px',
              width: '100%',
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              border: '1px solid #ccc',
              padding: '10px',
              boxSizing: 'border-box',
              outline: 'none',
            }}
            name=""
            id=""
            rows={10}
            onChange={(e) => {
              setPhoneNote(e.target.value);
            }}
            value={phoneNote}
          />

          {isClicked && !phoneNote ? (
            <Typography
              style={{
                color: 'red',
                marginTop: '-18px',
              }}
            >
              {'Phone note is required'}
            </Typography>
          ) : (
            ''
          )}

          <Button
            onClick={() => {
              setIsClicked(true);
              handlePhoneNote(phoneNote);
            }}
          >
            {' '}
            Save{' '}
          </Button>
        </InfoRowStyled>
      </LeadContainer>
      {auth?.role === 'CUSTOMER-SERVICE-ASSISTANT' ? null : (
        <AssignClient
          clientDetails={
            clientData || {
              _id: '',
              claimAccountLinkSentAt: new Date(),
              email: '',
              firstName: '',
              lastName: '',
              mobileNumber: '',
              assessmentDate: '',
              assignLead: [],
              isAssessmentGrantApproved: false,
              isPhoned: false,
            }
          }
          clientId={clientId ?? ''}
          fetchClientData={getClientDetail}
        />
      )}
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        desc={`Are you sure want to delete? `}
        onConfirm={() => deleteHandler()}
      />
    </LeadWrapperStyled>
  );
};

export default ClientInfo;
