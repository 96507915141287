import buildingConfig from '@shared/apiConfigs/buildingType';
import { humanReadableDate, requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteModal from '../../../components/DeleteModal/deleteModal';
import toastMessage from '../../../constants/toastMessage';
import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Flex from '../../../designComponents/Flex';
import Pagination from '../../../designComponents/Pagination';
import Table, { TableDataType } from '../../../designComponents/Table';
import {
  ADD_BUILDINGTYPE_ROUTE,
  BUILDING_ROUTE,
  SETTING_ROUTE,
} from '../../../routes/routePaths';
import Setting from '..';

type BuildingDataType = {
  _id: string;
  buildingName: string;
  createdAt: string;
  updatedAt: string;
};
const BuildingType = () => {
  const [loading, setLoading] = useState(false);
  const [loadingButtonId, setLoadingButtonId] = useState('');
  const [buildings, setBuildings] = useState<BuildingDataType[]>([]);
  const [currentPage, setCurrenePage] = useState(1);
  const [totalBuildings, setTotalBuildings] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [deleteBuildingId, setDeleteBuildingId] = useState<string>('');
  const deleteHandler = async (buildingId: string) => {
    setLoadingButtonId(buildingId);
    setLoading(true);
    try {
      await requestAPI(buildingConfig.deleteBuilding(buildingId));
      setBuildings((prev) => prev.filter((x) => x._id !== buildingId));
      toast.success(toastMessage.buildingDeleted);
      console.log('model', openModal);
      setOpenModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const navigate = useNavigate();

  const getBuildingType = async () => {
    try {
      const data = await requestAPI(
        buildingConfig.getAllBuildingType(currentPage, 10)
      );
      const totalBuildingsType = data.pagination.totalData;
      setTotalBuildings(totalBuildingsType);
      setBuildings(data.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getBuildingType();
  }, [currentPage]);

  const handlePagination = ({ page, limit }: any) => {
    setCurrenePage(page);
    console.log(limit);
  };

  const tableData: TableDataType = {
    headings: [
      { key: 'building', label: 'Building' },

      {
        key: 'createdAt',
        label: 'Created At',
      },

      {
        key: 'actions',
        label: 'Action',
      },
    ],
    body: buildings?.map((building) => ({
      id: building._id,
      building: building.buildingName,
      createdAt: humanReadableDate(building.createdAt),

      actions: (
        <div
          style={{ display: 'flex', gap: '7px', justifyContent: 'flex-end' }}
        >
          {/* <Button
            style={{
              background: 'white',
              color: 'blue',
              border: '1px solid gray',
              borderRadius: '10px',
            }}
            onClick={() =>
              navigate(`/settings/buildings/edit-building/${building._id}`)
            }
          >
            Edit
          </Button> */}
          <Button
            variant="danger"
            outline
            onClick={(e) => {
              e.stopPropagation();
              setDeleteBuildingId(building._id);

              setOpenModal(true);
            }}
            isLoading={loading && loadingButtonId === building._id}
          >
            Delete
          </Button>
        </div>
      ),
    })),
  };
  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Building Type',
      path: `${SETTING_ROUTE}/${BUILDING_ROUTE}`,
    },
  ];

  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />

      <Setting>
        <Flex
          justifyContent="flex-end"
          style={{
            marginBottom: 16,
          }}
        >
          <Button
            variant="primary"
            onClick={() => navigate(ADD_BUILDINGTYPE_ROUTE)}
          >
            Add Building Type
          </Button>
        </Flex>
        <Table tableData={tableData} showCheckBox={false} hideSorting={true} />
        <Pagination
          activePage={currentPage}
          totalCount={totalBuildings}
          limit={10}
          handlePagination={handlePagination}
        />
      </Setting>
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        desc="Are you sure want to delete Building Type?"
        onConfirm={() => deleteHandler(deleteBuildingId)}
      />
    </>
  );
};

export default BuildingType;
