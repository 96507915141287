import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Document Title is required'),
  documentType: Yup.string().required('Document Type is required'),
  // documentFile: Yup.mixed().required('Upload Document is required'),
  // .test(
  //   'fileFormat',
  //   'Invalid file format. Only PDF files are allowed.',
  //   (value) => value && value[0].type === 'application/pdf'
  // ),
});
