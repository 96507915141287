import React from 'react';

import {
  CLIENT_ROUTE,
  DASHBOARD_ROUTE,
  LEAD_ROUTE,
  PROFILE_ROUTE,
  SETTING_ROUTE,
} from '../../routes/routePaths';
import { ClientSVG, DashboardSVG, LeadSVG, SettingSVG } from './icons';

const menuList: MenuType = [
  {
    icon: DashboardSVG,
    key: 'dashboard',
    label: 'Dashboard',
    path: DASHBOARD_ROUTE,
  },
  {
    icon: LeadSVG,
    key: 'leads',
    label: 'Leads',
    path: LEAD_ROUTE,
  },
  {
    icon: ClientSVG,
    key: 'clients',
    label: 'Clients',
    path: CLIENT_ROUTE,
  },
  {
    icon: SettingSVG,
    key: 'settings',
    label: 'Settings',
    path: `${SETTING_ROUTE}/${PROFILE_ROUTE}`,
  },
];

export default menuList;

type MenuType = Array<MenuItemType>;

export type MenuItemType = {
  key: string;
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  path: string;

  children?: Omit<MenuItemType, 'order' | 'children'>[];
};
