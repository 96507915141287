import { ConfigType } from "../utils/requestAPI";

export type ReliefAmountDataType = {
  grantNameId: string;
  buildingTypeId: string;
  reliefAmount: number;
};

const reliefAmountConfig = {
  createReliefAmount: (data: ReliefAmountDataType): ConfigType => ({
    method: "post",
    data,
    url: "/relief-amounts",
  }),
  getAllReliefAmount: (page?: number, limit?: number): ConfigType => ({
    method: "get",
    url: `/relief-amounts?page=${page}&limit=${limit}`,
  }),
  deleteReliefAmount: (id: string): ConfigType => ({
    method: "delete",
    url: `/relief-amounts/${id}`,
  }),
};

export default reliefAmountConfig;
