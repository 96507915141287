import adminUsersConfig from '@shared/apiConfigs/adminUsers';
import leadsConfig from '@shared/apiConfigs/leads';
import { requestAPI } from '@shared/utils';
import { FormikState } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';

import Typography from '../../../../designComponents/Typography';
import { ClientData } from '../../../Client/ViewDetail/ClientInfo';
import { AdminUser, AdminUsersType, LeadDetailsType } from './types';

export const formatClientTableData = (assignedData: any[]) => {
  const groupedDataObject = assignedData.reduce(
    (prev: { [key: string]: any[] }, curr: any) => {
      (prev[curr.role] = prev[curr.role] || []).push(curr);
      return prev;
    },
    {}
  );

  const groupedDataArray = Object.entries(groupedDataObject).map(
    (item: any, idx) => ({
      id: `${idx}`,
      role: item[0],
      name: item[1].map((data: any, idx: number) => (
        <Typography key={idx}>{data.name}</Typography>
      )),
    })
  );

  return {
    headings: [
      { key: 'role', label: 'Role' },
      { key: 'name', label: 'Name' },
    ],
    body: groupedDataArray,
  };
};

export const getFilteredData = (
  adminUsers: AdminUser[],
  values: any[],
  leadDetails: LeadDetailsType
) => {
  const usersId = leadDetails.assignLead?.map((item) => item._id);
  const data = values.map((item, index) =>
    index !== values.length - 1 ? item?.name : ''
  );
  const Users = adminUsers.filter((item) => !data.includes(item._id));

  const filteredUsers = Users.filter((item) => !usersId?.includes(item._id));

  return filteredUsers.map((item) => ({
    label: `${item.firstName + '  ' + item.lastName} `,
    value: `${item._id}`,
    record: item,
  }));
};

export const getClientFilteredData = (
  adminUsers: AdminUser[],
  values: any[],
  leadDetails: ClientData | LeadDetailsType
) => {
  const usersId = leadDetails.assignLead?.map((item) => item._id);

  const data = values.map((item) => item?.value);
  const Users = adminUsers.filter((item) => !data.includes(item._id));

  const filteredUsers = Users.filter((item) => !usersId?.includes(item._id));

  return filteredUsers.map((item) => ({
    label: `${item.firstName + '  ' + item.lastName} `,
    value: `${item._id}`,
    record: item,
  }));
};

export const handleAssignLead = async (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  leadId: string | undefined,
  userId: string[],
  isClient?: boolean,
  formReset?: (
    nextState?:
      | Partial<
          FormikState<{
            assignTo: {
              role: string;
              name: string;
              record: {};
              selectedOptions: never[];
            }[];
          }>
        >
      | undefined
  ) => void,
  fetch?: (id: string) => Promise<void>
) => {
  const data = {
    assignLead: userId,
  };
  try {
    await requestAPI(leadsConfig.assignLead(data, leadId || ''));
    setLoading(false);
    formReset && formReset();
    fetch && (await fetch(leadId || ''));
  } catch (error: any) {
    console.error(error);
    if (typeof error === 'string') setErrorMessage(error);
    if (error.error && typeof error.error === 'string')
      setErrorMessage(error.error);
    setLoading(false);
  } finally {
    toast.success(`${isClient ? 'Client' : 'Lead'} assigned successfully`);
  }
};

export const handleToggleChange = async (
  value: boolean,
  leadId: string | undefined
) => {
  try {
    leadId &&
      (await requestAPI(
        leadsConfig.updateLeadById({}, leadId, undefined, value)
      ));
    toast.success('Grant updated successfully');
  } catch (error) {
    console.error(error);
    toast.error('Something went wrong, please try again');
  }
};

export const handlePhoneNote = async (
  note: string,
  leadId: string | undefined,
  phoneChecked: boolean
) => {
  try {
    if (leadId && note) {
      await requestAPI(
        leadsConfig.updateLeadById(
          {
            phoneCallNotes: note,
          },
          leadId,
          phoneChecked
        )
      );
      toast.success('Phone note updated successfully');
    }
  } catch (error) {
    console.error(error);
    toast.error('Something went wrong, please try again');
  }
};

export const getAdminUsersWithRole = async (
  role: string,
  setAdminUsers: React.Dispatch<React.SetStateAction<AdminUsersType>>
) => {
  try {
    const adminUserWithRole = await requestAPI(
      adminUsersConfig.getAdminUsersByRole(role)
    );
    setAdminUsers((prev: any) => ({ ...prev, [role]: adminUserWithRole.data }));
  } catch (error) {
    console.error(error);
  }
};

export const getLeadsAssignedData = (
  leadDetails: LeadDetailsType | ClientData
) => {
  if (leadDetails.assignLead) {
    const data = leadDetails.assignLead.map((item) => ({
      role: item.role,
      name: item.firstName + ' ' + item.lastName,
      id: `${item._id}`,
    }));
    return data;
  }
  return [];
};
