import styled from 'styled-components';

export const WrapperStyled = styled.div`
  & .title {
    background-color: #d9e6ff;
    padding: 10px;
    border-radius: 5px;
  }

  & .title-text {
    color: blue;
    font-size: 16px;
  }

  & .grant-data {
    width: 100%;
    border: 1px solid #d9e6ff;

    & .grant-title {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      background-color: #e4ebed;
      padding: 15px;
    }

    & .grant-with-data {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 10px;

      &:nth-child(even) {
        background-color: #f0f0f0;
      }
    }
  }
  & .total {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 20px;
  }

  & .summary {
    padding: 10px;

    & .work-total {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

export const FormWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 10px;

  & .form-input {
    border-radius: 15px;
  }
`;

export const AccordianConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
