import styled from 'styled-components';

export const LeadWrapperStyled = styled.div`
  max-width: 1200px;
  margin: 30px 20px;

  & .heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;
  }
`;

export const LeadContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  margin-bottom: 50px;
  border-radius: 8px;

  & .form-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

export const FormContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 40px 20px;
  margin-bottom: 50px;
`;

export const InfoRowStyled = styled.div`
  display: inline-flex;
  width: 100%;
  height: fit-content;
  align-items: center;

  & .label {
    width: 130px;
    flex-shrink: 0;
    color: ${({ theme }) => theme.colors.darkSlateGray.strong};
    font-size: 16px;
  }
`;

export const LinkContainerStyled = styled.div`
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  margin-bottom: 3rem;
`;

export const InfoRowLinkedStyled = styled.div`
  display: flex;
  gap: 70px;
  align-items: center;
  margin-bottom: 20px;
  & .label {
    width: 280px;
    flex-shrink: 0;
    color: ${({ theme }) => theme.colors.darkSlateGray.strong};
    font-size: 16px;
  }
`;
