import { useState } from 'react';

import TabComponent from '../../../designComponents/Tab';
import EditLead from './EditLead';
import LeadHistory from './LeadHistory';

const tabTitles = ['Info', 'Activity History'];
const ViewDetails = () => {
  const [activeTab, setActiveTab] = useState(tabTitles[0]);

  const handleTabChange = (label: string) => {
    setActiveTab(label);
  };
  return (
    <div>
      <TabComponent
        tabTitles={tabTitles}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      />
      {activeTab === 'Info' && (
        <div>
          <EditLead />
        </div>
      )}
      {activeTab === 'Activity History' && (
        <div>
          <LeadHistory />
        </div>
      )}
    </div>
  );
};

export default ViewDetails;
