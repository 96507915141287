import documentsConfig from '@shared/apiConfigs/documents';
import theme from '@shared/theme';
import { humanReadableDate, requestAPI } from '@shared/utils';
import React, { useState } from 'react';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { FaRegFilePdf } from 'react-icons/fa';
import { toast } from 'react-toastify';

import DeleteModal from '../../../../components/DeleteModal/deleteModal';
import Accordion from '../../../../designComponents/Accordion/Accordion';
import Button from '../../../../designComponents/Button';
// import Input from '../../../../designComponents/Input';
import Modal from '../../../../designComponents/Modal';
import Table, { TableDataType } from '../../../../designComponents/Table';
import Typography from '../../../../designComponents/Typography';
import EditDocument from '../../../Settings/Documents/EditDocument';
import AddDocument from './Documents/AddDocument';
import { AccordianConatiner, WrapperStyled } from './style';

export type ClientDocumentType = {
  _id: string;
  name: string;
  notes: string;
  documentFileName?: string;
  documentURL?: string;
  documentType?: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
};

type ClientDocumentPropsType = {
  documents: ClientDocumentType[];
  fetchDocuments: () => Promise<void>;
};

const ClientDocuments = ({
  documents,
  fetchDocuments,
}: ClientDocumentPropsType) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [documentId, setDocumentId] = useState('');

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const deleteHandler = async (documentId: string) => {
    try {
      await requestAPI(documentsConfig.deleteDocumentById(documentId));
      toast.success('Deleted Successfully');
      setShowDeleteModal(false);
      await fetchDocuments();
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  return (
    <WrapperStyled>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px',
        }}
      >
        <Typography size={22}>Documents</Typography>
        <div
          style={{
            maxWidth: '500px',
            display: 'flex',
            flex: '1',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={openModal} variant="primary">
            Add New
          </Button>
        </div>
      </div>
      <AccordianConatiner>
        <Accordion key="test">
          <Accordion.Header
            className="title"
            style={{
              backgroundColor: theme.colors.primary,
            }}
          >
            <Typography
              className="title-text"
              style={{ color: theme.colors.white }}
            >
              Report
            </Typography>
          </Accordion.Header>
          <Accordion.Content>
            <div style={{ padding: '10px' }}>
              {documents.filter(
                (item: ClientDocumentType) => item.documentType === 'REPORT'
              ).length > 0 && (
                <Table
                  tableData={getFilteredData(
                    'REPORT',
                    documents,
                    setDocumentId,
                    setShowEditModal,
                    setShowDeleteModal
                  )}
                  hidePagination={true}
                  showCheckBox={false}
                  hideSorting={true}
                />
              )}
            </div>
          </Accordion.Content>
        </Accordion>
        <Accordion key="test">
          <Accordion.Header
            className="title"
            style={{
              backgroundColor: theme.colors.primary,
            }}
          >
            <Typography
              className="title-text"
              style={{ color: theme.colors.white }}
            >
              Grant
            </Typography>
          </Accordion.Header>
          <Accordion.Content>
            <div style={{ padding: '10px' }}>
              {documents.filter(
                (item: ClientDocumentType) => item.documentType === 'GRANT'
              ).length > 0 && (
                <Table
                  tableData={getFilteredData(
                    'GRANT',
                    documents,
                    setDocumentId,
                    setShowEditModal,
                    setShowDeleteModal
                  )}
                  hidePagination={true}
                  showCheckBox={false}
                  hideSorting={true}
                />
              )}
            </div>
          </Accordion.Content>
        </Accordion>
        <Accordion key="test">
          <Accordion.Header
            className="title"
            style={{
              backgroundColor: theme.colors.primary,
            }}
          >
            <Typography
              className="title-text"
              style={{ color: theme.colors.white }}
            >
              Contract
            </Typography>
          </Accordion.Header>
          <Accordion.Content>
            <div style={{ padding: '10px' }}>
              {documents.filter(
                (item: ClientDocumentType) => item.documentType === 'CONTRACT'
              ).length > 0 && (
                <Table
                  tableData={getFilteredData(
                    'CONTRACT',
                    documents,
                    setDocumentId,
                    setShowEditModal,
                    setShowDeleteModal
                  )}
                  hidePagination={true}
                  showCheckBox={false}
                  hideSorting={true}
                />
              )}
            </div>
          </Accordion.Content>
        </Accordion>
        <Accordion key="test">
          <Accordion.Header
            className="title"
            style={{
              backgroundColor: theme.colors.primary,
            }}
          >
            <Typography
              className="title-text"
              style={{ color: theme.colors.white }}
            >
              Certificate
            </Typography>
          </Accordion.Header>
          <Accordion.Content>
            <div style={{ padding: '10px' }}>
              {documents.filter(
                (item: ClientDocumentType) =>
                  item.documentType === 'CERTIFICATE'
              ).length > 0 && (
                <Table
                  tableData={getFilteredData(
                    'CERTIFICATE',
                    documents,
                    setDocumentId,
                    setShowEditModal,
                    setShowDeleteModal
                  )}
                  hidePagination={true}
                  showCheckBox={false}
                  hideSorting={true}
                />
              )}
            </div>
          </Accordion.Content>
        </Accordion>
        <Accordion key="test">
          <Accordion.Header
            className="title"
            style={{
              backgroundColor: theme.colors.primary,
            }}
          >
            <Typography
              className="title-text"
              style={{ color: theme.colors.white }}
            >
              Invoice
            </Typography>
          </Accordion.Header>
          <Accordion.Content>
            <div style={{ padding: '10px' }}>
              {documents.filter(
                (item: ClientDocumentType) => item.documentType === 'INVOICE'
              ).length > 0 && (
                <Table
                  tableData={getFilteredData(
                    'INVOICE',
                    documents,
                    setDocumentId,
                    setShowEditModal,
                    setShowDeleteModal
                  )}
                  hidePagination={true}
                  showCheckBox={false}
                  hideSorting={true}
                />
              )}
            </div>
          </Accordion.Content>
        </Accordion>
        <Accordion key="test">
          <Accordion.Header
            className="title"
            style={{
              backgroundColor: theme.colors.primary,
            }}
          >
            <Typography
              className="title-text"
              style={{ color: theme.colors.white }}
            >
              Additional
            </Typography>
          </Accordion.Header>
          <Accordion.Content>
            <div style={{ padding: '10px' }}>
              {documents.filter(
                (item: ClientDocumentType) => item.documentType === 'ADDITIONAL'
              ).length > 0 && (
                <Table
                  tableData={getFilteredData(
                    'ADDITIONAL',
                    documents,
                    setDocumentId,
                    setShowEditModal,
                    setShowDeleteModal
                  )}
                  hidePagination={true}
                  showCheckBox={false}
                  hideSorting={true}
                />
              )}
            </div>
          </Accordion.Content>
        </Accordion>
      </AccordianConatiner>
      <Modal open={showModal} onClose={closeModal} title="Add New Document">
        <AddDocument
          closeModal={setShowModal}
          fetchDocuments={fetchDocuments}
        />
      </Modal>
      <Modal
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        title="Edit Document"
      >
        <EditDocument
          documentId={documentId}
          closeModal={setShowEditModal}
          fetchDocuments={fetchDocuments}
        />
      </Modal>
      <DeleteModal
        desc="Are you sure want to delete document?"
        onClose={() => setShowDeleteModal(false)}
        open={showDeleteModal}
        title="Confirm document delete"
        onConfirm={() => deleteHandler(documentId)}
        warning="This can't be undone"
      />
    </WrapperStyled>
  );
};

export default ClientDocuments;

export const FileView = ({ url }: { url: string }) => {
  return (
    <div style={{ display: 'flex', gap: '30px' }}>
      <FaRegFilePdf size={25} color="red" />

      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        style={{
          color: 'black',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BsBoxArrowUpRight size={20} />
      </a>
    </div>
  );
};

function getFilteredData(
  type: string,
  documents: ClientDocumentType[],
  setDocumentId: React.Dispatch<React.SetStateAction<string>>,
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
) {
  const filteredDocuments = documents.filter(
    (item: ClientDocumentType) => item.documentType === type
  );

  const tableData: TableDataType = {
    headings: [
      { key: 'title', label: 'Title' },
      { key: 'document', label: 'Document' },
      { key: 'uploadedBy', label: 'Uploaded By' },
      { key: 'updatedAt', label: 'Updated At' },
      { key: 'actions', label: 'Action' },
    ],
    body: filteredDocuments.map((document: ClientDocumentType) => ({
      id: document._id,
      title: document.name,
      document: (
        <FileView url={document.documentURL ? document.documentURL : ''} />
      ),
      uploadedBy: '',
      updatedAt: humanReadableDate(document.updatedAt),
      actions: (
        <div
          style={{ display: 'flex', gap: '7px', justifyContent: 'flex-end' }}
        >
          <Button
            variant="update"
            outline
            onClick={() => {
              setDocumentId(document._id);
              setShowEditModal(true);
            }}
          >
            Edit
          </Button>
          <Button
            variant="danger"
            outline
            onClick={() => {
              setDocumentId(document._id);
              setShowDeleteModal(true);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    })),
  };
  return tableData;
}
