import buildingConfig from '@shared/apiConfigs/buildingType';
import grantConfig from '@shared/apiConfigs/grant';
import grantsConfig, {
  GrantClientRelationshipType,
} from '@shared/apiConfigs/grants';
import reliefAmountConfig from '@shared/apiConfigs/releifAmount';
import { requestAPI } from '@shared/utils';
import { uploadImagesToS3 } from '@shared/utils/s3Upload';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { FaRegFilePdf } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import BrowseFile from '../../../../../designComponents/BrowseFile';
import Button from '../../../../../designComponents/Button';
import FormContainer from '../../../../../designComponents/FormContainer';
import Input from '../../../../../designComponents/Input';
import Select, {
  OptionType,
} from '../../../../../designComponents/SelectWithInput';
import Typography from '../../../../../designComponents/Typography';
import {
  DocumentContainerStyled,
  DocumentWrapperStyled,
  FormWrapper,
  InfoRowStyled,
} from './style';
import { GrantValidationSchema } from './validation';

type Props = {
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  fetchGrants: () => Promise<void>;
};

type GrantType = {
  _id: string;
  name: string;
};

type Buildingtype = {
  _id: string;
  buildingName: string;
};

type reliefAmountType = {
  buildingTypeId: Buildingtype;
  grantNameId: GrantType;
  reliefAmount: number;
  _id: string;
};

const initialValues = {
  grantNameId: '',
  buildingTypeId: '',
  reliefAmount: 0,
  grantStatus: '',
};

const status = [
  { label: 'IN PROGRESS', value: 'IN PROGRESS' },
  { label: 'APPROVED', value: 'APPROVED' },
  { label: 'REJECTED', value: 'REJECTED' },
];

const AddGrants = ({ setShowModal, fetchGrants }: Props) => {
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState<OptionType[]>([]);
  const [optionBuilding, setOptionBuilding] = useState<OptionType[]>([]);
  const [reliefAmount, setReliefAmount] = useState<reliefAmountType[]>([]);
  const [relief, setRelief] = useState<reliefAmountType>();
  const [isClicked, setIsClicked] = useState(false);

  const theme = useTheme();
  const { clientId } = useParams();
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);

  const [file, setFile] = useState<File[]>([]);

  const grantData = async () => {
    try {
      const { data } = await requestAPI(grantConfig.getAllGrants(1, 100));
      setOption(
        data?.map((grant: GrantType) => {
          return {
            label: grant.name,
            value: grant._id,
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    grantData();
  }, []);

  const buildingData = async () => {
    try {
      const { data } = await requestAPI(
        buildingConfig.getAllBuildingType(1, 100)
      );
      setOptionBuilding(
        data?.map((building: Buildingtype) => {
          return {
            label: building.buildingName,
            value: building._id,
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    buildingData();
  }, []);

  const getAllReliefAmount = async () => {
    try {
      const data = await requestAPI(
        reliefAmountConfig.getAllReliefAmount(1, 100)
      );
      setReliefAmount(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllReliefAmount();
  }, []);

  const handleAddDocument = async (values: GrantClientRelationshipType) => {
    try {
      setLoading(true);
      if (file.length > 0) {
        const documentURL = await uploadImagesToS3(file);
        if (documentURL && documentURL?.length > 0) {
          await requestAPI(
            grantsConfig.createGrants({
              buildingTypeId: values.buildingTypeId,
              reliefAmount: relief ? relief.reliefAmount : 0,
              grantStatus: values.grantStatus,
              grantNameId: values.grantNameId,
              document: documentURL[0],
              leadId: clientId,
            })
          );
        }
        setLoading(false);
        toast.success('grant created successfully');
        setShowModal && setShowModal(false);
        await fetchGrants();
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getReliefAmount = (grantId: string, buildingTypeId: string) => {
    const reliefAmt = reliefAmount?.find((x: reliefAmountType) => {
      return (
        x.buildingTypeId?._id === buildingTypeId &&
        x.grantNameId?._id === grantId
      );
    });
    if (reliefAmt) {
      setRelief(reliefAmt);

      return reliefAmt.reliefAmount;
    }

    return 0;
  };
  const handleAddFiles = (files: File[]) => {
    if (formikRef?.current && files.length > 0) {
      formikRef?.current?.setFieldValue('documentFileName', files[0].name);
    }

    setFile(files);
  };
  const handleRemove = () => {
    setFile([]);
    formikRef?.current?.setFieldValue('documentFileName', '');
  };

  return (
    <DocumentContainerStyled>
      <Typography style={{ fontSize: '20px', marginBottom: '10px' }}>
        New Grant
      </Typography>
      <Typography style={{ marginBottom: '20px' }}>
        Please fill out the details below to create a new grant.
      </Typography>
      <DocumentWrapperStyled>
        <Formik
          initialValues={initialValues}
          onSubmit={handleAddDocument}
          innerRef={formikRef}
          validationSchema={GrantValidationSchema}
          enableReinitialize
        >
          {({ values, handleSubmit, setFieldValue, touched, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <FormWrapper>
                  <InfoRowStyled>
                    <FormContainer
                      label="Grant Name"
                      error={
                        touched.grantNameId && errors.grantNameId
                          ? errors.grantNameId
                          : ''
                      }
                    >
                      <Select
                        options={option}
                        onSelect={(selectedOption) => {
                          setFieldValue('grantNameId', selectedOption);
                        }}
                        selected={values.grantNameId || ''}
                        placeholder="Name"
                      />
                    </FormContainer>

                    <FormContainer
                      label="Building Type"
                      error={
                        touched.buildingTypeId && errors.buildingTypeId
                          ? errors.buildingTypeId
                          : ''
                      }
                    >
                      <Select
                        options={optionBuilding}
                        onSelect={(selectedOption) => {
                          setFieldValue('buildingTypeId', selectedOption);
                        }}
                        selected={values.buildingTypeId || ''}
                        placeholder="Status"
                      />
                    </FormContainer>
                    <FormContainer
                      label="Relief Amount"
                      error={
                        touched.reliefAmount && errors.reliefAmount
                          ? errors.reliefAmount
                          : ''
                      }
                    >
                      <Input
                        name="reliefAmount"
                        value={getReliefAmount(
                          values.grantNameId,
                          values.buildingTypeId
                        )}
                        onChange={(e) => {
                          setFieldValue('reliefAmount', e.target.value);
                        }}
                      />
                    </FormContainer>
                    <FormContainer
                      label="Grant Status"
                      error={
                        touched.grantStatus && errors.grantStatus
                          ? errors.grantStatus
                          : ''
                      }
                    >
                      <Select
                        options={status}
                        onSelect={(selectedOption) => {
                          setFieldValue('grantStatus', selectedOption);
                        }}
                        selected={values.grantStatus}
                        placeholder="Status"
                      />
                    </FormContainer>
                  </InfoRowStyled>
                  <div style={{ marginTop: '-20px' }}>
                    <Typography className="label">Grant File</Typography>
                    {file.length === 0 ? (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '20px',
                          marginBottom: '20px',
                          background: theme.colors.gray.lighter,
                        }}
                      >
                        <BrowseFile
                          files={[]}
                          setFiles={handleAddFiles}
                          accepts=".pdf"
                          isUploading={false}
                          showSelectFiles={false}
                        />
                      </div>
                    ) : (
                      <div style={{ marginTop: '10px' }}>
                        {/* <Typography>Uploaded File:</Typography> */}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <div style={{ position: 'relative' }}>
                            <div
                              style={{
                                position: 'absolute',
                                left: '55px',
                                top: '-8px',
                                cursor: 'pointer',
                              }}
                              onClick={handleRemove}
                            >
                              <IoClose size={20} />
                            </div>
                            <FaRegFilePdf size={60} color="red" />
                          </div>
                          <Typography>{file[0].name}</Typography>
                        </div>
                      </div>
                    )}
                    {file.length === 0 && isClicked && (
                      <Typography
                        style={{
                          color: 'red',
                          marginTop: '-18px',
                        }}
                      >
                        {'Grant File is required'}
                      </Typography>
                    )}
                  </div>
                </FormWrapper>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '20px',
                  }}
                >
                  <Button
                    type="submit"
                    variant="primary"
                    style={{ borderRadius: '5px' }}
                    isLoading={loading}
                    onClick={() => setIsClicked(true)}
                  >
                    Create
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </DocumentWrapperStyled>
    </DocumentContainerStyled>
  );
};

export default AddGrants;
