import { useTheme } from 'styled-components';

import Typography from '../Typography';
import { CardWrapperStyled, Content, Image, Wrapper } from './style';

export type HistoryCardProps = {
  id: string;
  title: string;
  img: string;
  time: string;
  user: string;
};

const HistoryCard = ({ id, img, title, time, user }: HistoryCardProps) => {
  const theme = useTheme();
  return (
    <CardWrapperStyled>
      <Wrapper key={id}>
        <div>
          <Image src={img} alt="random" />
        </div>
        <Content>
          <Typography
            style={{
              color: theme.colors.blue.strong,
              fontSize: '18px',
              marginBottom: '5px',
            }}
          >
            {title}
          </Typography>
          <Typography style={{ color: 'gray', fontSize: '14PX' }}>
            {time}
          </Typography>
          <Typography style={{ marginBottom: '15px', color: 'gray' }}>
            by {user}
          </Typography>
          {/* <Accordion key="test">
            <Accordion.Header className="title">
              <Typography className="title-text">Show Changes</Typography>
            </Accordion.Header>
            <Accordion.Content>
              <Typography style={{ marginLeft: '5px', color: 'gray' }}>
                <li>
                  {' '}
                  <span style={{ fontWeight: '600', color: 'black' }}>
                    Status
                  </span>{' '}
                  changed from "In Progress" to "Delayed"
                </li>
              </Typography>
            </Accordion.Content>
          </Accordion> */}
        </Content>
      </Wrapper>
    </CardWrapperStyled>
  );
};

export default HistoryCard;
