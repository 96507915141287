import { useLocation, useNavigate } from 'react-router-dom';

import { useRoot } from '../../../context/RootProvider';
import Typography from '../../../designComponents/Typography';
import { menuData, menuDev, menuFrontendSetup } from './menuData';
import { SidebarItem } from './style';

type Props = {};

const SettingSidebar = (_props: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { auth } = useRoot();

  const checkAuth = () => {
    if (auth?.role === 'SUPER-ADMIN' || auth?.role === 'ADMIN') {
      return true;
    }
    return false;
  };

  return (
    <>
      {checkAuth() ? (
        <div className="setting-nav">
          <>
            <Typography variant="heading" className="heading">
              Settings
            </Typography>
            {menuData?.map((menu) => {
              const Icon = menu.icon;
              return (
                <SidebarItem
                  key={menu.label}
                  $active={pathname.includes(menu.Url)}
                  onClick={() => navigate(menu.Url)}
                >
                  <Icon className="icon" height={24} width={24} />
                  <h2 className="label">{menu.label}</h2>
                </SidebarItem>
              );
            })}
            <Typography variant="heading" className="heading">
              Dev Setup
            </Typography>
            {menuDev?.map((menu) => {
              const Icon = menu.icon;
              return (
                <SidebarItem
                  key={menu.label}
                  $active={pathname.includes(menu.Url)}
                  onClick={() => navigate(menu.Url)}
                >
                  <Icon className="icon" height={24} width={24} />
                  <h2 className="label">{menu.label}</h2>
                </SidebarItem>
              );
            })}
            <Typography variant="heading" className="heading">
              Front End Setup
            </Typography>
            {menuFrontendSetup?.map((menu) => {
              const Icon = menu.icon;

              return (
                <SidebarItem
                  key={menu.label}
                  onClick={() => navigate(menu.Url)}
                  $active={pathname.includes(menu.Url)}
                >
                  <Icon className="icon" height={24} width={24} />
                  <h2 className="label">{menu.label}</h2>
                </SidebarItem>
              );
            })}
          </>
        </div>
      ) : null}
    </>
  );
};

export default SettingSidebar;
