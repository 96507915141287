export const typeOfProperty = [
  { label: 'Bungalow', value: 'Bungalow' },
  { label: 'Detached', value: 'Detached' },
  { label: 'Semi Detached', value: 'Semi Detached' },
  { label: 'Mid Terrace', value: 'Mid Terrace' },
  { label: 'Apartment', value: 'Apartment' },
  { label: 'Multi Unit', value: 'Multi Unit' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Others', value: 'Others' },
];

export const ageOfProperty = [
  { label: 'Pre 1990', value: 'Pre 1990' },
  { label: '1960 1980', value: '1960 1980' },
  { label: '1980 2000', value: '1980 2000' },
  { label: '2001 2011', value: '2001 2011' },
  { label: '2011 onwards', value: '2011 onwards' },
  { label: "Don't Know", value: "Don't know" },
  { label: "Don't know the year", value: "Don't know the year0" },
];

export const ber = [
  { label: 'A1', value: 'A1' },
  { label: 'A2', value: 'A2' },
  { label: 'A3', value: 'A3' },
  { label: 'B1', value: 'B1' },
  { label: 'B2', value: 'B2' },
  { label: 'B3', value: 'B3' },
  { label: 'C1', value: 'C1' },
  { label: 'C2', value: 'C2' },
  { label: 'C3', value: 'C3' },
  { label: 'D1', value: 'D1' },
  { label: 'D2', value: 'D2' },
  { label: 'E1', value: 'E1' },
  { label: 'E2', value: 'E2' },
  { label: 'F', value: 'F' },
  { label: 'G', value: 'G' },
  { label: "I don't know my rating", value: "I don't know my rating" },
];

export const roles = [
  {
    label: 'Customer Service Assisstant ',
    value: 'CUSTOMER-SERVICE-ASSISTANT',
  },
  {
    label: 'Admin',
    value: 'ADMIN',
  },
  {
    label: 'Super Admin ',
    value: 'SUPER-ADMIN',
  },
  {
    label: 'Ber Advisor',
    value: 'BERADVISOR',
  },
];
