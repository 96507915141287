import reliefAmountConfig from '@shared/apiConfigs/releifAmount';
import { humanReadableDate, requestAPI } from '@shared/utils';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteModal from '../../../components/DeleteModal/deleteModal';
import toastMessage from '../../../constants/toastMessage';
import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Flex from '../../../designComponents/Flex';
import Pagination from '../../../designComponents/Pagination';
import Table, { TableDataType } from '../../../designComponents/Table';
import {
  ADD_RELIEF_AMOUNT_ROUTE,
  RELEIF_AMOUNT_ROUTE,
  SETTING_ROUTE,
} from '../../../routes/routePaths';
import Setting from '..';

type ReliefDatatype = {
  _id: string;
  grantNameId: {
    name: string;
  };
  buildingTypeId: {
    buildingName: string;
  };
  reliefAmount: number;
  createdAt: string;
};
const CreateReliefAmount = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [reliefAmount, setReliefAmount] = useState<ReliefDatatype[]>([]);
  const [loadingButtonId, setLoadingButtonId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRelief, setTotalRelief] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [deleteReliefId, setDeleteReliefId] = useState<string>('');
  const reliefAmountData = async () => {
    try {
      const data = await requestAPI(
        reliefAmountConfig.getAllReliefAmount(currentPage, 10)
      );
      const totalRelief = data.pagination.totalData;
      setTotalRelief(totalRelief);
      setReliefAmount(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteHandler = async (reliefAmountId: string) => {
    setLoadingButtonId(reliefAmountId);

    setLoading(true);
    try {
      await requestAPI(reliefAmountConfig.deleteReliefAmount(reliefAmountId));
      setLoading(false);
      setOpenModal(false);

      setReliefAmount((prev) => prev.filter((x) => x._id !== reliefAmountId));
      toast.success(toastMessage.buildingDeleted);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    reliefAmountData();
  }, [currentPage]);

  const handlePagination = ({ page, limit }: any) => {
    setCurrentPage(page);
    console.log(limit);
  };

  const tableData: TableDataType = {
    headings: [
      { key: 'name', label: 'Grant Name' },
      { key: 'building', label: 'Building' },
      { key: 'reliefAmount', label: 'Relief Amount' },

      {
        key: 'createdAt',
        label: 'Created At',
      },

      {
        key: 'actions',
        label: 'Action',
      },
    ],
    body: reliefAmount?.map((amount) => ({
      id: amount._id,
      name: amount?.grantNameId?.name,
      building: amount?.buildingTypeId?.buildingName,
      reliefAmount: `€ ${amount.reliefAmount}`,
      createdAt: humanReadableDate(amount.createdAt),
      actions: (
        <div
          style={{ display: 'flex', gap: '7px', justifyContent: 'flex-end' }}
        >
          <Button
            variant="danger"
            outline
            onClick={(e) => {
              e.stopPropagation();
              setDeleteReliefId(amount._id);
              setOpenModal(true);
            }}
            isLoading={loading && loadingButtonId === amount._id}
          >
            Delete
          </Button>
        </div>
      ),
    })),
  };
  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Relief Amount',
      path: `${SETTING_ROUTE}/${RELEIF_AMOUNT_ROUTE}`,
    },
  ];

  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />

      <Setting>
        <Flex
          justifyContent="flex-end"
          style={{
            marginBottom: 16,
          }}
        >
          <Button
            variant="primary"
            onClick={() => navigate(ADD_RELIEF_AMOUNT_ROUTE)}
          >
            Add Relief Amount
          </Button>
        </Flex>
        <Table tableData={tableData} showCheckBox={false} hideSorting={true} />

        <Pagination
          activePage={currentPage}
          totalCount={totalRelief}
          handlePagination={handlePagination}
          limit={10}
        />
      </Setting>
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        desc="Are you sure want to delete Relief Amount?"
        onConfirm={() => deleteHandler(deleteReliefId)}
      />
    </>
  );
};

export default CreateReliefAmount;
