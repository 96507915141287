import buildingConfig from '@shared/apiConfigs/buildingType';
import grantConfig from '@shared/apiConfigs/grant';
import reliefAmountConfig, {
  ReliefAmountDataType,
} from '@shared/apiConfigs/releifAmount';
import { requestAPI } from '@shared/utils';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import toastMessage from '../../../../constants/toastMessage';
import AlertComponent from '../../../../designComponents/Alert';
import Button from '../../../../designComponents/Button';
import FormContainer from '../../../../designComponents/FormContainer';
import Input from '../../../../designComponents/Input';
import Select, {
  OptionType,
} from '../../../../designComponents/SelectWithInput';
import Typography from '../../../../designComponents/Typography';
import Setting from '../..';
import { ReliefWrapper } from './style';
import validation from './validation';

type GrantType = {
  _id: string;
  name: string;
};

type Buildingtype = {
  _id: string;
  buildingName: string;
};

const initialValues = {
  grantNameId: '',
  buildingTypeId: '',
  reliefAmount: 0,
};
const CreateReliefAmount = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [optionBuilding, setOptionBuilding] = useState<OptionType[]>([]);
  const [option, setOption] = useState<OptionType[]>([]);
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);

  const grantData = async () => {
    try {
      const { data } = await requestAPI(grantConfig.getAllGrants(1, 100));
      setOption(
        data?.map((grant: GrantType) => {
          return {
            label: grant.name,
            value: grant._id,
          };
        })
      );
    } catch (error: any) {
      console.error(error);
    }
  };
  useEffect(() => {
    grantData();
  }, []);

  const buildingData = async () => {
    try {
      const { data } = await requestAPI(
        buildingConfig.getAllBuildingType(1, 100)
      );
      setOptionBuilding(
        data?.map((building: Buildingtype) => {
          return {
            label: building.buildingName,
            value: building._id,
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    buildingData();
  }, []);

  const handleCreateReliefAmount = async (values: ReliefAmountDataType) => {
    setLoading(true);
    try {
      await requestAPI(
        reliefAmountConfig.createReliefAmount({
          grantNameId: values.grantNameId,
          buildingTypeId: values.buildingTypeId,
          reliefAmount: values.reliefAmount,
        })
      );
      setLoading(false);

      navigate(-1);
      toast.success(toastMessage.reliefAmountCreated);
    } catch (error: any) {
      if (typeof error === 'string') setErrorMessage(error);
      if (error.error && typeof error.error === 'string')
        setErrorMessage(error.error);
      setLoading(false);
    }
  };
  return (
    <Setting>
      <ReliefWrapper>
        <Typography style={{ marginBottom: '20px', fontWeight: '600' }}>
          Relief Amount
        </Typography>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values) => {
            handleCreateReliefAmount(values);
          }}
          validationSchema={validation.ReliefAmount}
          innerRef={formikRef}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <FormContainer
                  label="Grants"
                  error={
                    touched.grantNameId && errors.grantNameId
                      ? errors.grantNameId
                      : ''
                  }
                >
                  <Select
                    selected={values.grantNameId}
                    options={option}
                    onSelect={(selectedOption) => {
                      setFieldValue('grantNameId', selectedOption);
                    }}
                  />
                </FormContainer>
                <FormContainer
                  label="Building Types"
                  error={
                    touched.buildingTypeId && errors.buildingTypeId
                      ? errors.buildingTypeId
                      : ''
                  }
                >
                  <Select
                    selected={values.buildingTypeId}
                    options={optionBuilding}
                    onSelect={(selectOption) => {
                      setFieldValue('buildingTypeId', selectOption);
                    }}
                  />
                </FormContainer>

                <FormContainer
                  label="Relief Amount"
                  error={
                    touched.reliefAmount && errors.reliefAmount
                      ? errors.reliefAmount
                      : ''
                  }
                >
                  <Input
                    name="reliefAmount"
                    value={values.reliefAmount}
                    onChange={(e) =>
                      setFieldValue('reliefAmount', e.target.value)
                    }
                  />
                </FormContainer>
                <Button variant="primary" type="submit" isLoading={loading}>
                  Create Relief Amount
                </Button>
              </Form>
            );
          }}
        </Formik>
        <div style={{ marginTop: '10px' }}>
          {errorMessage && <AlertComponent text={errorMessage} type="error" />}
        </div>
      </ReliefWrapper>
    </Setting>
  );
};

export default CreateReliefAmount;
