import adminUsersConfig from '@shared/apiConfigs/adminUsers';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useRoot } from '../../../context/RootProvider';
import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import FormContainer from '../../../designComponents/FormContainer';
import Input from '../../../designComponents/Input';
import Modal from '../../../designComponents/Modal';
import { PROFILE_ROUTE, SETTING_ROUTE } from '../../../routes/routePaths';
import ChangePassword from '../../Auth/ChangePassword';
import Setting from '..';
import { Wrapper } from './style';

type Props = {};
interface AdminDataType {
  _id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  createdAt: string;
  updatedAt: string;
}
const Profile = (_props: Props) => {
  const { auth } = useRoot();
  const id = auth?._id;
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState<AdminDataType>();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const getAdminUserData = async () => {
      try {
        const data =
          id && (await requestAPI(adminUsersConfig.getAdminUserById(id)));
        setAdminData(data);
      } catch (error) {
        console.error(error);
      }
    };
    getAdminUserData();
  }, [id]);

  const initialValues = {
    email: adminData?.email || '',
    firstName: adminData?.firstName || '',
    lastName: adminData?.lastName || '',
    role: adminData?.role || '',
    selectOption: '',
  };

  const checkAuth = () => {
    if (auth?.role === 'SUPER-ADMIN' || auth?.role === 'ADMIN') {
      return true;
    }
    return false;
  };

  const handleProfile = async (values: typeof initialValues) => {
    setLoading(true);
    try {
      id &&
        (await requestAPI(
          adminUsersConfig.updateAdminUser(
            {
              firstName: values.firstName,
              lastName: values.lastName,
              role: values.role,
            },
            id
          )
        ));
      setLoading(false);
      toast.success('Updated successfully');
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong, please try again');
      setLoading(false);
    }
  };

  const breadCrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Profile',
      path: `${SETTING_ROUTE}/${PROFILE_ROUTE}`,
    },
  ];

  return (
    <>
      <Modal open={showModal} onClose={toggleModal}>
        <ChangePassword />
      </Modal>
      {checkAuth() ? <BreadCrumb breadCrumb={breadCrumbs} /> : null}
      <Setting>
        <Wrapper>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setShowModal(true)}>Change Password</Button>
          </div>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleProfile}
          >
            {({ values, setFieldValue, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <FormContainer label="Email:">
                    <Input
                      value={values.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      disabled
                    />
                  </FormContainer>
                  <FormContainer label="First Name:">
                    <Input
                      value={values.firstName}
                      onChange={(e) =>
                        setFieldValue('firstName', e.target.value)
                      }
                    />
                  </FormContainer>
                  <FormContainer label="Last Name:">
                    <Input
                      value={values.lastName}
                      onChange={(e) =>
                        setFieldValue('lastName', e.target.value)
                      }
                    />
                  </FormContainer>
                  <FormContainer label="Role:">
                    <Input
                      value={values.role}
                      onChange={(e) => setFieldValue('role', e.target.value)}
                      disabled
                    />
                  </FormContainer>

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="primary"
                      isLoading={loading}
                      type="submit"
                      style={{
                        border: 'none',
                        borderRadius: '5px',
                        width: '150px',
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Wrapper>
      </Setting>
    </>
  );
};

export default Profile;
