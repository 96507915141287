import { requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import NavigateBack from '../../designComponents/NavigateBack';
import Setting from '../../pages/Settings';
import { labels } from '../../utils/labelsConfig';
import { paths } from '../../utils/pathConfig';
import { ShowDetailsStyled, TableWrapper } from './style';

type Props = {
  heading?: string;
  id?: string;
};

const ShowDetails = ({ heading }: Props) => {
  const rejectedKeys = ['_id', '__v', 'id'];

  const [data, setData] = useState<any[]>([]);
  const { id } = useParams();
  const { pathname } = useLocation();
  const [route, setRoute] = useState('document');
  const getDocumnetDetails = async (path: string, getId: string) => {
    try {
      const datas = await requestAPI({
        method: 'get',
        url: `/${path}/${getId}`,
      });
      setData(Object.entries(datas));
    } catch (err) {
      console.log('Error', err);
    }
  };
  useEffect(() => {
    if (id && pathname) {
      const newPath = pathname.split('/')[
        pathname.split('/').length - 2
      ] as string;
      setRoute(newPath);

      const matchPath = paths[newPath];

      setRoute(matchPath);
      getDocumnetDetails(matchPath, id);
    }
  }, [id, pathname]);
  return (
    <Setting>
      <TableWrapper>
        <NavigateBack />
        <div>{heading || `${route} Details`}</div>
        <div style={{ marginTop: 12 }}>
          {data.length > 0 &&
            data.map((item) => {
              if (rejectedKeys.includes(item[0])) {
                return null;
              }
              return (
                <ShowDetailsStyled key={item[1]}>
                  <div style={{ width: '15%' }}>
                    {route ? labels[route]?.[item[0]] : item[0]}
                  </div>
                  <div style={{ width: '85%' }}>{item[1]}</div>
                </ShowDetailsStyled>
              );
            })}
        </div>
      </TableWrapper>
    </Setting>
  );
};

export default ShowDetails;
