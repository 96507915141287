import stagesConfig from '@shared/apiConfigs/stages';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import toastMessage from '../../../../constants/toastMessage';
import AlertComponent from '../../../../designComponents/Alert';
import BreadCrumb from '../../../../designComponents/BreadCrumb';
import Button from '../../../../designComponents/Button';
import Input from '../../../../designComponents/Input';
import Select, {
  OptionType,
} from '../../../../designComponents/SelectWithInput';
import Typography from '../../../../designComponents/Typography';
import {
  SETTING_ROUTE,
  STAGE_CREATE_WORK_ROUTE,
  STAGE_ROUTE,
} from '../../../../routes/routePaths';
import Setting from '../..';
import { FormWrapper, InfoRow, WorkContainer } from './style';
import validation from './validation';

type Props = {};
type ClientType = {
  firstName: string;
  lastName: string;
  _id: string;
  leadId: string;
};

const statusOptions = [
  { label: 'In Progress', value: 'In Progress' },
  { label: 'Delayed', value: 'Delayed' },
  { label: 'Completed', value: 'Completed' },
];
const initialValues = {
  clientId: '',
  name: '',
  amount: '',
  status: '',
};

const CreateWork = (_props: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessage] = useState('');

  const [option, setOption] = useState<OptionType[]>([
    { value: '', label: '' },
  ]);

  const clientData = async () => {
    try {
      const response = await requestAPI(stagesConfig.getAllClients(true));
      const client = response.data;

      const newData = client.map((client: ClientType) => {
        return {
          label: client.firstName + ' ' + client.lastName,
          value: client._id,
        };
      });
      setOption(newData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    clientData();
  }, []);
  const handleCreateWork = async (data: typeof initialValues) => {
    try {
      setIsLoading(true);
      const workData = await requestAPI(
        stagesConfig.createStage({
          leadId: data.clientId,
          name: data.name,
          amount: data.amount,
          status: data.status,
        })
      );
      toast.success(toastMessage.workCreated);
      navigate('/settings/stages');
      console.log(workData);
      setIsLoading(false);
    } catch (error: any) {
      let errorMessage = '';

      if (typeof error === 'string') {
        errorMessage = error;
      } else if (error.error && typeof error.error === 'string') {
        errorMessage = error.error;
      }

      setErrorMessage(errorMessage);

      console.error('Client creation failed:', error);
      setIsLoading(false);
    }
  };

  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Stages',
      path: `${SETTING_ROUTE}/${STAGE_ROUTE}`,
    },
    {
      label: 'Create Work',
      path: `${SETTING_ROUTE}/${STAGE_ROUTE}/${STAGE_CREATE_WORK_ROUTE}`,
    },
  ];
  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <WorkContainer>
          <FormWrapper>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={handleCreateWork}
              validationSchema={validation}
            >
              {({ values, setFieldValue, handleSubmit, touched, errors }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <InfoRow>
                      <Typography className="text">Client*</Typography>

                      <Select
                        selected={values.clientId}
                        options={option}
                        onSelect={(selectOption: any) =>
                          setFieldValue('clientId', selectOption)
                        }
                        onChange
                      />
                      {touched.clientId && errors.clientId && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {errors.clientId}
                        </div>
                      )}
                    </InfoRow>

                    <InfoRow>
                      <Typography variant="heading" className="text">
                        Name*
                      </Typography>

                      <Input
                        value={values.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                      {touched.name && errors.name && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {errors.name}
                        </div>
                      )}
                    </InfoRow>
                    <InfoRow>
                      <Typography className="text">Amount*</Typography>

                      <Input
                        value={values.amount}
                        onChange={(e) =>
                          setFieldValue('amount', e.target.value)
                        }
                      />
                      {touched.amount && errors.amount && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {errors.amount}
                        </div>
                      )}
                    </InfoRow>
                    <InfoRow>
                      <Typography className="text">Status*</Typography>

                      <Select
                        selected={values.status}
                        options={statusOptions}
                        onSelect={(selectOption: any) =>
                          setFieldValue('status', selectOption)
                        }
                        onChange
                      />
                      {touched.status && errors.status && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                          {errors.status}
                        </div>
                      )}
                    </InfoRow>
                    {errorMessages && (
                      <AlertComponent text={errorMessages} type="error" />
                    )}
                    <Button
                      type="submit"
                      className="btn-submit"
                      isLoading={isLoading}
                      variant="primary"
                      style={{ width: '200px', borderRadius: '5px' }}
                    >
                      Create Work
                    </Button>
                    <Button
                      onClick={() => navigate(-1)}
                      style={{ width: '200px', borderRadius: '5px' }}
                    >
                      Cancel
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </FormWrapper>
        </WorkContainer>
      </Setting>
    </>
  );
};

export default CreateWork;
