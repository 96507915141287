import styled from 'styled-components';

export const Wrapper = styled.div<{ $isMini: boolean }>`
  position: relative;
  height: calc(100vh - 60px);
  width: ${({ $isMini }) => ($isMini ? '55px' : '200px')};

  .menu-container {
    padding: 20px 0px;
    z-index: 3000;
    background-color: ${({ theme }) => theme.colors.white};
    transition: all 0.3s;
    position: fixed;
    top: 60px;
    width: ${({ $isMini }) => ($isMini ? '55px' : '200px')};
    bottom: 0;
  }
`;

export const SidebarItem = styled.div<{ $active: boolean; $isMini: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  gap: 18px;
  padding: 5px 10px;
  margin: 2.5px 5px;
  border-radius: 4px;
  height: 40px;
  background-color: ${({ $active, theme }) =>
    $active ? theme.colors.primary : 'transparent'};
  color: ${({ $active, theme }) =>
    $active ? theme.colors.white : theme.colors.black};
  transition: all 0.1s ease;
  & svg.icon {
    height: 24px;
    width: 24px;
    margin: 0 !important;
    flex-shrink: 0;
  }

  & .label {
    font-size: 16px;
    line-height: 24px;
    display: ${({ $isMini }) => ($isMini ? 'none' : 'block')};
  }

  &:hover {
    background-color: ${({ theme, $active }) =>
      $active ? theme.colors.primary : theme.colors.ad_disableButtonText};
    & svg.icon {
      height: 24px;
      width: 24px;
      flex-shrink: 0;
    }
  }
`;
