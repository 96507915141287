import { ConfigType } from "../utils/requestAPI";

export type GrantClientRelationshipType = {
  adminUserId?: string;
  grantNameId: string;
  buildingTypeId: string;
  reliefAmount: number;
  grantStatus: string;
  document?: string;
  leadId?: string;
};

const grantsConfig = {
  getAllGrants: (): ConfigType => ({
    method: "get",

    url: "/grants",
  }),
  getGrantsByLeadId: (leadId: string): ConfigType => ({
    method: "get",

    url: `/grants?leadId=${leadId}`,
  }),
  createGrants: (data: GrantClientRelationshipType): ConfigType => ({
    method: "post",
    data,
    url: "/grants",
  }),
  deleteGrantById: (id: string): ConfigType => ({
    method: "delete",
    url: `/grantClientRelationShip/${id}`,
  }),

  getGrants: (): ConfigType => ({
    method: "get",
    url: "/grant",
  }),
};

export default grantsConfig;
