import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  addressOne: Yup.string().required('First Address is Required.'),
  addressTwo: Yup.string().required('Second Address is Required.'),
  cityId: Yup.string().required('City is Required.'),
  eirCode: Yup.string().required('EirCode is Required.'),
  typeOfProperty: Yup.string().required('Property type is Required.'),
  ageOfProperty: Yup.string().required('Property type is Required.'),
  ber: Yup.string().required('BER type is Required.'),
  mprn: Yup.string().required('MPRN type is Required.'),
  email: Yup.string().required('Email Required.'),
  firstName: Yup.string().required('FirstName Required.'),
  lastName: Yup.string().required('LastName Required.'),
  mobileNumber: Yup.string().required('phone Number Required.'),
});
