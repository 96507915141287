import adminUsersConfig, { AdminDataType } from '@shared/apiConfigs/adminUsers';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import toastMessage from '../../../../constants/toastMessage';
import AlertComponent from '../../../../designComponents/Alert';
import BreadCrumb from '../../../../designComponents/BreadCrumb';
import Button from '../../../../designComponents/Button';
import Input from '../../../../designComponents/Input';
import Select from '../../../../designComponents/SelectWithInput';
import Typography from '../../../../designComponents/Typography';
import {
  ADD_ADMIN_ROUTE,
  SETTING_ROUTE,
  VIEW_ADMNN_USER_ROUTE,
} from '../../../../routes/routePaths';
import Setting from '../..';
import { AdminWrapperStyled, InfoRowStyled } from './style';
import { validationSchema } from './validation';

type Props = {};
const userRoles = [
  { label: 'ADMIN', value: 'ADMIN' },
  { label: 'SUPER-ADMIN', value: 'SUPER-ADMIN' },
  { label: 'CUSTOMER-SERVICE-ASSISTANT', value: 'CUSTOMER-SERVICE-ASSISTANT' },
  { label: 'BER-ADVISOR', value: 'BER-ADVISOR' },
];

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  role: '',
};

const AddAdmin = (_props: Props) => {
  const navigate = useNavigate();
  const [selectOption, setSelectOption] = useState<string>('');
  const [errorMessages, setErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const handleAddAdmin = async (value: AdminDataType) => {
    try {
      setLoading(true);
      await requestAPI(
        adminUsersConfig.addAdmin({
          email: value.email,
          firstName: value.firstName,
          lastName: value.lastName,
          role: selectOption,
        })
      );

      setLoading(false);
      toast.success(toastMessage.adminCreated);
      navigate('/settings/admin-users');
    } catch (error: any) {
      let errorMessage = '';

      if (typeof error === 'string') {
        errorMessage = error;
      } else if (error.error && typeof error.error === 'string') {
        errorMessage = error.error;
      }

      setErrorMessage(errorMessage);

      console.error('Client creation failed:', error);
      setLoading(false);
    }
  };
  const handleSelect = (selectedOption: string) => {
    setSelectOption(selectedOption);
  };

  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'View Admin Users',
      path: `${SETTING_ROUTE}/${VIEW_ADMNN_USER_ROUTE}`,
    },
    {
      label: 'Add Admin',
      path: `${SETTING_ROUTE}/${VIEW_ADMNN_USER_ROUTE}/${ADD_ADMIN_ROUTE}`,
    },
  ];
  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <AdminWrapperStyled>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleAddAdmin}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <InfoRowStyled>
                      <Typography size={16}>Email:</Typography>
                      {errors.email && touched.email && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {errors.email}
                        </div>
                      )}
                      <Input
                        name="email"
                        value={values.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography size={16}>First Name:</Typography>
                      {errors.firstName && touched.firstName && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {errors.firstName}
                        </div>
                      )}
                      <Input
                        value={values.firstName}
                        name="firstName"
                        onChange={(e) =>
                          setFieldValue('firstName', e.target.value)
                        }
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography size={16}>Last Name:</Typography>
                      {errors.lastName && touched.lastName && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {errors.lastName}
                        </div>
                      )}
                      <Input
                        value={values.lastName}
                        name="lastName"
                        onChange={(e) =>
                          setFieldValue('lastName', e.target.value)
                        }
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography size={16}>Role:</Typography>
                      {/* {errors.role && touched.role && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.role}
                    </div>
                  )} */}
                      <Select
                        selected={selectOption}
                        options={userRoles}
                        onSelect={handleSelect}
                        onChange
                      />
                    </InfoRowStyled>

                    <Button
                      variant="primary"
                      isLoading={loading}
                      style={{
                        marginBottom: '30px',
                        width: '150px',
                        borderRadius: '5px',
                      }}
                      type="submit"
                    >
                      {' '}
                      Add
                    </Button>
                    {errorMessages && (
                      <AlertComponent text={errorMessages} type="error" />
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </AdminWrapperStyled>
      </Setting>
    </>
  );
};

export default AddAdmin;
