import { ConfigType } from '../utils/requestAPI';

const delayedJobsConfig = {
  getAllDelayedJobs: (): ConfigType => ({
    method: 'get',
    url: '/delayedJob',
  }),
  deleteDelayedJobById: (id: string): ConfigType => ({
    method: 'delete',
    url: `/delayedJob/${id}`,
  }),
};

export default delayedJobsConfig;
