import styled from 'styled-components';
export const WrapperStyled = styled.div`
  width: 100%;
  .grantCard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin: 25px 30px;
    background-color: white;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid #e2e8f0;
    padding: 1.5rem;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    }

    .details {
      border-right: 1px solid #b4a3af;
      // padding-right: 15rem;
    }

    .buildType {
      padding-left: 1.5rem;
      border-right: 1px solid #b4a3af;
      padding-right: 4rem;
    }

    .attachments {
      padding-left: 1.5rem;
      border-right: 1px solid #b4a3af;
      padding-right: 1.5rem;

      &__details {
        display: flex;
        justify-content: space-between;

        &__icon {
          margin-top: 0.5rem;
        }
      }
    }

    .amount {
      padding-left: 1.5rem;
      border-right: 1px solid #b4a3af;
      padding-right: 4rem;
    }

    .status {
      padding-left: 2rem;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
`;
