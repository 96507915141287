import clientsConfig from '@shared/apiConfigs/clients';
import { requestAPI } from '@shared/utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useRoot } from '../../../../context/RootProvider';
import HistoryCard from '../../../../designComponents/HistoryCard';
export interface ActivityDataType {
  _id: string;
  leadId: string;
  refName: string;
  title: string;
  description: string;
  activity: string;
  createdAt: string;
  updatedAt: string;
}
const ClientHistory = () => {
  const [activityData, setActivityData] = useState<ActivityDataType[]>([]);
  const { auth } = useRoot();

  const email = auth?.email;

  const { clientId } = useParams();
  useEffect(() => {
    const getHistory = async () => {
      try {
        if (clientId) {
          const data = await requestAPI(
            clientsConfig.getActivityByClientId(clientId)
          );
          setActivityData(data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getHistory();
  }, [clientId]);
  const formatActivity = () => {
    return activityData.map((item) => {
      return {
        user: email,
        id: item._id,
        title: item.title,
        time: moment(item.updatedAt).startOf('minutes').fromNow(),
        img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF6p3GwOIRG6j5IH9TgxaCafCqJbTvt8yvAQ&usqp=CAU',
      };
    });
  };
  return (
    <div>
      {formatActivity().map((item: any) => {
        return <HistoryCard key={item.id} {...item} />;
      })}
    </div>
  );
};

export default ClientHistory;
