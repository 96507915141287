const toastMessage = {
  loginSuccess: 'Login Successful',
  workCreated: 'Work Created Successfully',
  clientCreated: 'Client Created Successfully',
  clientDeleted: 'Client Deleted Successfully',
  leadCreated: 'Lead Created Successfully',
  leadDeleted: 'Lead Deleted Successfully',
  adminCreated: 'Admin created successfully',
  documentAdded: 'Document added successfully',
  workUpdated: 'Work updated successfully',
  GrantClientRelationship: 'Grant Client Relationship created',
  documentUpdated: 'Document updated successfully',
  passwordUpdated: 'password updated successfully',
  grantCreated: 'grant created successfully',
  grantDeleted: 'grant deleted successfully',
  buildingAdded: 'building added successfully',
  buildingDeleted: 'building deleted successfully',
  reliefAmountCreated: 'relief amount created successfully',
  cityCreated: 'City created successfully',
  cityDeleted: 'City deleted successfully',
};

export default toastMessage;
