import * as yup from 'yup';

export const GrantValidationSchema = yup.object().shape({
  grantNameId: yup
    .string()
    .min(2, 'Grant Name is too short!')
    .max(50, 'Grant Name is too Long!')
    .required('Grant Name is required'),

  buildingTypeId: yup.string().required('Building Type is required'),
  reliefAmount: yup
    .number()
    .required('Relief Amount is required')
    .typeError('Relief amount must be number'),

  grantStatus: yup.string().required('Grant Status is required'),
});
