import buildingConfig from '@shared/apiConfigs/buildingType';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import toastMessage from '../../../../constants/toastMessage';
import Button from '../../../../designComponents/Button';
import Input from '../../../../designComponents/Input';
import Typography from '../../../../designComponents/Typography';
import Setting from '../..';
import { BuildingsStyled, InfoRowStyled } from './style';

type BuildingType = {
  buildingName: string;
};

const validationSchema = Yup.object().shape({
  buildingName: Yup.string().required('Building Name is required'),
});

const AddBuilding = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleCreateBuilding = async (values: BuildingType) => {
    setLoading(true);
    try {
      await requestAPI(
        buildingConfig.createBuildingType({
          buildingName: values.buildingName,
        })
      );

      toast.success(toastMessage.buildingAdded);
      navigate(-1);
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      toast.error(error);

      setLoading(false);
    }
  };
  return (
    <Setting>
      <BuildingsStyled>
        <Typography style={{ fontWeight: '600', marginBottom: '20px' }}>
          Add Building Type
        </Typography>
        <Formik
          initialValues={{ buildingName: '' }}
          enableReinitialize
          onSubmit={handleCreateBuilding}
          validationSchema={validationSchema}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <InfoRowStyled>
                  <Typography>Building Name</Typography>
                  <Input
                    name="buildingName"
                    value={values.buildingName}
                    onChange={(e) =>
                      setFieldValue('buildingName', e.target.value)
                    }
                  />
                  {errors.buildingName && touched.buildingName && (
                    <Typography style={{ color: 'red' }}>
                      {errors.buildingName}
                    </Typography>
                  )}
                  <Button variant="primary" type="submit" isLoading={loading}>
                    Create Building Type
                  </Button>
                </InfoRowStyled>
              </Form>
            );
          }}
        </Formik>
      </BuildingsStyled>
    </Setting>
  );
};

export default AddBuilding;
