import { ConfigType } from '../utils/requestAPI';

type CreateStageType = {
  leadId?: string;
  name: string;
  amount: string;
  status: string;
  stageId?: string;
};

const stagesConfig = {
  getAllStages: (): ConfigType => ({
    method: 'get',
    url: '/stages',
  }),
  getStagesById: (id: string): ConfigType => ({
    method: 'get',
    url: `/stages/${id}`,
  }),
  deleteStageById: (id: string): ConfigType => ({
    method: 'delete',
    url: `/stages/${id}`,
  }),
  updateStageById: (data: CreateStageType, id: any): ConfigType => ({
    method: 'put',
    data,
    url: `/stages/${id}`,
  }),
  createStage: (data: CreateStageType): ConfigType => ({
    method: 'post',
    data,
    url: `/stages/`,
  }),
  createStageByLeadId: (data: CreateStageType): ConfigType => ({
    method: 'post',
    data,
    url: `/stages`,
  }),
  getAllClients: (isClient: boolean): ConfigType => ({
    method: 'get',
    url: `/leads?isClient=${isClient}`,
  }),
  getStageByLeadId: (leadId: string): ConfigType => ({
    method: 'get',
    url: `/stages?leadId=${leadId}`,
  }),
};

export default stagesConfig;
