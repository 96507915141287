import { ConfigType } from "../utils/requestAPI";

type AdminUserParams = {
  _id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  password: string;
  userId: string;
  newPassword: string;
  passwordConfirmation: string;
};

export type AdminDataType = {
  email?: string;
  firstName: string;
  lastName: string;
  role: string;
};

type LoginData = {
  email: string;
  password: string;
};
export type updateData = {
  token?: string;
  email?: string;
  password: string;
  confirmPassword?: string;
};

const adminUsersConfig = {
  addAdmin: (data: AdminDataType): ConfigType => ({
    method: "post",
    data,
    url: "/admin-users/admin",
  }),
  getAllAdminUsers: (
    currentPage: number,
    limit: number,
    role: string
  ): ConfigType => ({
    method: "get",
    url: `/admin-users?page=${currentPage}&limit=${limit}&role=${role}`,
  }),
  deleteAdminUserById: (id: string): ConfigType => ({
    method: "delete",
    url: `/admin-users/${id}`,
  }),

  // Unpaginated
  getAdminUsersByRole: (role: string): ConfigType => ({
    method: "get",
    url: `/admin-users?role=${role}`,
  }),
  login: (data: LoginData): ConfigType => ({
    method: "post",
    url: "/admin-users/log-in",
    data,
  }),

  updatePassword: (data: updateData): ConfigType => ({
    method: "post",
    url: "/admin-users/set-password",
    data,
  }),
  getAdminUserById: (id: string): ConfigType => ({
    method: "get",
    url: `/admin-users/${id}`,
  }),
  // Unpaginated
  updateAdminUser: (data: AdminDataType, adminId: string): ConfigType => ({
    method: "put",
    data,
    url: `/admin-users/${adminId}`,
  }),
};

export default adminUsersConfig;
