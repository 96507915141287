import grantTableRowsConfig from '@shared/apiConfigs/grantTableRows';
import { humanReadableDate, requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteModal from '../../../components/DeleteModal/deleteModal';
import ReadOnlyEditor from '../../../components/Editor/ReadOnlyEditor';
import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Modal from '../../../designComponents/Modal';
import Pagination from '../../../designComponents/Pagination';
import Table, { TableDataType } from '../../../designComponents/Table';
import {
  SETTING_ROUTE,
  VIEW_GRANT_TABLE_ROW_ROUTE,
} from '../../../routes/routePaths';
import Setting from '..';
import CreateTableRows from './CreateTableRows';

type GrantType = {
  _id: string;
  name: string;
  homeType: string;
  value: string;
  createdAt: Date;
  updatedAt: Date;
  slug: string;
  id: number;
  __v: number;
};

type Props = {};

const GrantTableRows = (_props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [grantTableRows, setGrantTableRows] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [grantId, setGrantId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [deleteGrantId, setDeleteGrantId] = useState<string>('');
  const navigate = useNavigate();

  const tableData: TableDataType = {
    headings: [
      { key: 'id', label: 'Id' },

      { key: 'name', label: 'Name' },
      {
        key: 'homeType',
        label: 'Home type',
      },

      {
        key: 'value',
        label: 'Value',
      },
      {
        key: 'createdAt',
        label: 'Created At',
      },
      {
        key: 'updatedAt',
        label: 'Updated At',
      },
      {
        key: 'actions',
        label: 'Actions',
      },
    ],
    body: grantTableRows,
  };
  const deleteHandler = async (grantTableId: string) => {
    try {
      setIsLoading(true);

      await requestAPI(
        grantTableRowsConfig.deleteGrantTableRowById(grantTableId)
      );
      setOpenModal(false);
    } catch (err) {
      toast.error('Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    requestAPI(
      grantTableRowsConfig.getAllGrantTableRows({
        page: currentPage,
        limit: 10,
      })
    )
      .then((data) => {
        setTotalData(data.pagination.totalData);

        const newData = data.data.map((grant: GrantType) => ({
          id: grant.id,
          name: grant.name,
          homeType: grant.homeType,
          value: <ReadOnlyEditor content={grant.value} />,
          createdAt: humanReadableDate(grant.createdAt),
          updatedAt: humanReadableDate(grant.updatedAt),
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'flex-end',
              }}
            >
              {/* <Button
                variant="primary"
                outline
                onClick={() => {
                  navigate(grant._id);
                }}
              >
                View
              </Button> */}
              <Button
                variant="update"
                outline
                onClick={() => {
                  setGrantId(grant._id);
                  setShowModal(true);
                }}
              >
                Edit
              </Button>
              <Button
                variant="danger"
                outline
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteGrantId(grant._id);
                  setOpenModal(true);
                }}
                isLoading={isLoading}
              >
                Delete
              </Button>
            </div>
          ),
        }));

        setGrantTableRows(newData);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, [navigate, isLoading, showModal, currentPage]);

  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Grant Table Rows',
      path: `${SETTING_ROUTE}/${VIEW_GRANT_TABLE_ROW_ROUTE}`,
    },
  ];

  const handlePagination = ({ page }: { page: number }) => {
    setCurrrentPage(page);
  };

  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '16px',
          }}
        >
          <Button
            variant="primary"
            onClick={() => navigate('/settings/grant-table-rows/new')}
          >
            New Grant Table Row
          </Button>
        </div>
        <Table
          hideSorting
          tableData={tableData}
          showCheckBox={true}
          isLoading={isLoading}
        />
        <Pagination
          activePage={currentPage}
          totalCount={totalData}
          limit={10}
          handlePagination={handlePagination}
        />
      </Setting>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <CreateTableRows grantId={grantId} setShowModal={setShowModal} />
      </Modal>
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        desc="Are you sure want to delete this grant table row?"
        onConfirm={() => deleteHandler(deleteGrantId)}
      />
    </>
  );
};

export default GrantTableRows;
