import theme, { ThemeType } from '@shared/theme';
import styled, { css } from 'styled-components';

export type StyleProps = {
  variant?:
    | 'primary'
    | 'danger'
    | 'secondary'
    | 'gray'
    | 'white'
    | 'nostyle'
    | 'update'
    | 'cancel';
  size?: 'sm' | 'md' | 'xs' | 'xl' | 'lg';
  $bgColor?: string;
  color?: string;
  $outline?: boolean;
  width?: string;
};
const getBackgroundColor = (
  variant: string,
  outline: boolean,
  theme: ThemeType,
  bgColor?: string
) => {
  if (bgColor) {
    return bgColor;
  }
  if (outline) {
    return theme.colors.white;
  }
  switch (variant) {
    case 'primary':
      return theme.colors.primary;
    case 'secondary':
      return theme.colors.ad_secondary;
    case 'gray':
      return theme.colors.defaultButton;
    case 'danger':
      return theme.colors.white;
    case 'white':
      return theme.colors.white;
    case 'update':
      return theme.colors.white;
    case 'cancel':
      return theme.colors.disableText;
    default:
      return theme.colors.blue.faded;
  }
};

const getSize = (size?: string) => {
  switch (size) {
    case 'xs':
      return css`
        font-size: 12px;
        height: 32px;
      `;
    case 'sm':
      return css`
        font-size: 12px;
        height: 36px;
      `;
    case 'md':
      return css`
        font-size: 16px;
        height: 39px;
      `;
    case 'lg':
      return css`
        font-size: 16px;
        height: 45px;
      `;
    default:
      return css`
        font-size: 16px;
        height: 36px;
      `;
  }
};

const getColor = (
  variant: string,
  outline: boolean,
  theme: ThemeType,
  color: string
) => {
  if (color) {
    return color;
  }
  if (!outline && variant !== 'gray' && variant !== 'white') {
    return theme.colors.white;
  }

  switch (variant) {
    case 'gray':
      return theme.colors.primary;
    case 'primary':
      return theme.colors.ad_primary;
    case 'secondary':
      return theme.colors.ad_secondary;
    case 'danger':
      return theme.colors.red.strong;
    case 'update':
      return theme.colors.black;
    default:
      return theme.colors.black;
  }
};

const buttonStyleSelector = (
  variant: string,
  outline: boolean,
  bgColor: string,
  color: string
) => {
  return css`
    background-color: ${({ theme }) =>
      getBackgroundColor(variant, outline, theme, bgColor)};
    color: ${({ theme }) => getColor(variant, outline, theme, color)};
    border: 2px solid
      ${({ theme }) =>
        outline
          ? getColor(variant, outline, theme, color)
          : getBackgroundColor(variant, outline, theme, bgColor)};
  `;
};

export const StyledButton = styled.button<StyleProps>`
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  box-sizing: border-box;
  width: ${({ width }) => width};
  min-width: fit-content;
  padding: 0px 10px;
  border-radius: 4px;
  gap: 10px;
  ${({ size }) => getSize(size)};
  ${({ variant, $outline, $bgColor, color }) =>
    buttonStyleSelector(
      variant || 'primary',
      $outline || false,
      $bgColor || '',
      color || ''
    )};

  &:hover {
    ${({ variant, $outline }) =>
      variant &&
      buttonStyleSelector(
        variant,
        $outline || false,
        variant === 'update'
          ? theme.colors.black
          : variant === 'danger'
            ? theme.colors.red.strong
            : variant === 'cancel'
              ? theme.colors.black
              : variant === 'secondary'
                ? theme.colors.ad_secondary
                : variant === 'white'
                  ? theme.colors.black
                  : 'null',
        theme.colors.white
      )};

    > svg {
      fill: ${({ theme, variant }) => {
        return variant === 'update' || variant === 'danger'
          ? theme.colors.white
          : 'null';
      }};
    }
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  ${({ variant }) =>
    variant === 'nostyle'
      ? css`
          all: unset;
          color: inherit;
          font: inherit;
          cursor: pointer;
          outline: inherit;
        `
      : css``}
`;
