export const paths: Record<string, string> = {
  'admin-users': 'adminUsers',
  'delayed-job-records': 'delayedJob',
  grants: 'grantClientRelationShip',
  faqs: 'faqs',
  'grant-table-rows': 'grantTable',
  document: 'document',
  stages: 'stages',
  'payment-agreerment': 'paymentAggerment',
};
