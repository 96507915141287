import styled from 'styled-components';

export const CardWrapperStyled = styled.div`
  width: 100%;
  padding: 30px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  border-left: 7px solid ${(props) => props.theme.colors.primary};
  border-radius: 8px;
  margin-bottom: 20px;
  border-bottom: 0.5px solid gray;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const Image = styled.img`
  height: 30px;
  width: 30px;
`;

export const Content = styled.div`
  & .title {
    max-width: 120px;
    margin-bottom: 5px;
  }
  & .title-text {
    color: gray;
  }
`;
