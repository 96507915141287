import adminUsersConfig from '@shared/apiConfigs/adminUsers';
import cityConfig from '@shared/apiConfigs/cities';
import leadsConfig, { LeadParams } from '@shared/apiConfigs/leads';
import paymentAgreementConfig from '@shared/apiConfigs/paymentAgreement';
import { requestAPI } from '@shared/utils';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteModal from '../../../../components/DeleteModal/deleteModal';
import toastMessage from '../../../../constants/toastMessage';
import { useRoot } from '../../../../context/RootProvider';
import Button from '../../../../designComponents/Button';
import Checkbox from '../../../../designComponents/Checkbox';
import Flex from '../../../../designComponents/Flex';
import Input from '../../../../designComponents/Input';
import MultiSelect from '../../../../designComponents/MultiSelectDropdown';
import Select from '../../../../designComponents/SelectWithInput';
import Table from '../../../../designComponents/Table';
import Toggle from '../../../../designComponents/Toggle';
import Typography from '../../../../designComponents/Typography';
import { ageOfProperty, ber, roles, typeOfProperty } from './data';
import {
  formatClientTableData,
  getAdminUsersWithRole,
  getClientFilteredData,
  getLeadsAssignedData,
  // getLeadsAssignedData,
  handleAssignLead,
  handlePhoneNote,
  handleToggleChange,
} from './function';
import {
  FormContainerStyled,
  InfoRowLinkedStyled,
  InfoRowStyled,
  LeadContainer,
  LeadWrapperStyled,
  LinkContainerStyled,
} from './style';
import { AdminUser, AdminUsersType, cityType, LeadDetailsType } from './types';

const EditLead = () => {
  const { leadId } = useParams();

  const [leadDetails, setLeadDetails] = useState<LeadDetailsType>({
    berAdvisorID: '',
    customerServiceAssistantId: '',
    firstName: '',
    lastName: '',
    email: '',
    cityId: '',
    mobileNumber: '',
    addressOne: '',
    addressTwo: '',
    eirCode: '',
    ber: '',
    mprn: '',
    regionId: '',
    typeOfProperty: '',
    ageOfProperty: '',
    paymentLinkUrl: '',
    paymentInvoiceURL: '',
    interest: [],
    isPhoned: false,
    typeOfQuestions: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [cities, setCities] = useState([{ value: '', label: '' }]);

  const [errorMessage, setErrorMessage] = useState('');
  console.log(errorMessage);

  const [isApproved, setIsApproved] = useState(false);
  const [adminUsers, setAdminUsers] = useState<AdminUsersType>({});
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [phoneChecked, setPhoneChecked] = useState(false);
  const [phoneNote, setPhoneNote] = useState('');
  const [userRoleData, setUserRoleData] = useState<AdminUser[]>([]);
  const [isClicked, setIsClicked] = useState(false);

  const [selectedAdminUser, setSelectedAdminUser] = useState<{
    [key: string]: string;
  }>({
    BERADVISOR: leadDetails.berAdvisorID,
    CUSTOMERSERVICEASSISTANT: leadDetails.customerServiceAssistantId,
  });
  console.log({ selectedAdminUser });

  const { auth } = useRoot();
  useEffect(() => {
    if (typeof leadDetails.isAssessmentGrantApproved === 'boolean') {
      setIsApproved(leadDetails?.isAssessmentGrantApproved);
    }
  }, [leadDetails.isAssessmentGrantApproved]);
  const getLeadDetails = async (id: string) => {
    try {
      const leadData = await requestAPI(leadsConfig.getLeadById(id));
      setLeadDetails(leadData);
      setPhoneNote(leadData.phoneCallNotes);
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong. Please try again.');
    }
  };
  console.log(leadDetails);

  useEffect(() => {
    if (typeof leadDetails?.isPhoned === 'boolean') {
      setPhoneChecked(leadDetails?.isPhoned);
    }
  }, [leadDetails?.isPhoned]);

  const handlePhoneCheckboxChange = () => {
    setPhoneChecked(!phoneChecked);
  };

  console.log(adminUsers, 'adasfkldsdkhf');
  useEffect(() => {
    if (leadId) {
      getLeadDetails(leadId);
    }
  }, [leadId]);

  useEffect(() => {
    getAdminUsersWithRole('CUSTOMER-SERVICE-ASSISTANT', setAdminUsers);
    getAdminUsersWithRole('BERADVISOR', setAdminUsers);
  }, []);

  const handleEditInformation = async (data: LeadParams) => {
    try {
      if (leadId) {
        await requestAPI(
          leadsConfig.updateLeadById(
            {
              addressOne: data.addressOne,
              addressTwo: data.addressTwo,
              firstName: data.firstName,
              lastName: data.lastName,
              typeOfProperty: data.typeOfProperty,
              ageOfProperty: data.ageOfProperty,
              cityId: data.cityId,
              eirCode: data.eirCode,
              ber: data.ber,
              mprn: data.mprn,
              mobileNumber: data.mobileNumber,
            },
            leadId
          )
        );
        toast.success('Lead updated successfully');
        setEditMode(false);
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to update lead');
    }
  };

  const handleEditModeToggle = () => {
    setEditMode(!editMode);
  };

  const sendPaymentLinkToClient = async () => {
    if (leadId) {
      await requestAPI(paymentAgreementConfig.createPaymentLink(leadId));
      toast.success('Payment Link send successfully.');
    }
  };
  const handleRoleChange = async (
    role: string,
    setFieldValue: Function,
    index: number
  ) => {
    try {
      const { data } = await requestAPI(
        adminUsersConfig.getAdminUsersByRole(role)
      );
      setUserRoleData(data);
      setFieldValue(`assignTo[${index}].selectedOptions`, []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setSelectedAdminUser({
      BERADVISOR: leadDetails.berAdvisorID,
      CUSTOMERSERVICEASSISTANT: leadDetails.customerServiceAssistantId,
    });
  }, [leadDetails]);

  const fetchData = async () => {
    try {
      const response = await requestAPI(
        cityConfig.getAllCities({
          limit: 100,
        })
      );
      const data = response.data;
      const newData = data.map((item: cityType) => {
        return {
          value: item._id,
          label: item.name,
        };
      });
      setCities(newData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const deleteHandler = async () => {
    try {
      if (leadId) {
        await requestAPI(leadsConfig.deleteLeadById(leadId));
        toast.success(toastMessage.leadDeleted);
        setOpenModal(false);
        navigate(-1);
      }
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  const handlePhoneApprovalChange = async (checked: boolean) => {
    try {
      if (leadId) {
        await requestAPI(leadsConfig.updateLeadById({}, leadId, checked));
        toast.success('Phone approval updated successfully');
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <LeadWrapperStyled>
      <LeadContainer>
        <div>
          <Formik
            initialValues={leadDetails}
            enableReinitialize
            onSubmit={handleEditInformation}
          >
            {({ values, setFieldValue, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Flex
                    justifyContent="space-between"
                    margin="0px 0px 30px 0px"
                    gap="20px"
                  >
                    <Typography
                      variant="heading"
                      style={{
                        fontSize: '22px',
                        fontWeight: '600',
                        marginBottom: '15px',
                      }}
                    >
                      {editMode ? 'Edit Information' : 'Information'}
                    </Typography>
                    <Flex gap="10px" justifyContent="flex-end">
                      {!editMode && (
                        <>
                          <Button
                            onClick={handleEditModeToggle}
                            variant="primary"
                            style={{
                              width: '115px',
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="danger"
                            outline
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenModal(true);
                            }}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                      {editMode && (
                        <>
                          <Button
                            // variant="white"
                            onClick={handleEditModeToggle}
                            style={{
                              border: '1px solid gray',
                              borderRadius: '3px',
                              width: '150px',
                              background: 'white',
                              color: 'black',
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            variant="primary"
                            style={{
                              width: '150px',
                            }}
                          >
                            Update Lead
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Flex>
                  <FormContainerStyled>
                    <InfoRowStyled>
                      <Typography className="label">Address 1</Typography>
                      <Input
                        disabled={!editMode}
                        containerStyle={{ border: !editMode ? 'none' : '' }}
                        style={{ opacity: editMode ? '1' : '0.5' }}
                        readOnly={!editMode}
                        value={values.addressOne}
                        onChange={(e) =>
                          setFieldValue('addressOne', e.target.value)
                        }
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="label">Address 2</Typography>
                      <Input
                        disabled={!editMode}
                        containerStyle={{ border: !editMode ? 'none' : '' }}
                        style={{ opacity: editMode ? '1' : '0.5' }}
                        readOnly={!editMode}
                        value={values.addressTwo}
                        onChange={(e) =>
                          setFieldValue('addressTwo', e.target.value)
                        }
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="label">City/County</Typography>
                      <Select
                        selected={values.cityId || ''}
                        options={cities}
                        onSelect={(selectOption: any) =>
                          setFieldValue('cityId', selectOption)
                        }
                        disabled={!editMode}
                      />
                    </InfoRowStyled>

                    <InfoRowStyled>
                      <Typography className="label">EirCode</Typography>

                      <Input
                        value={values.eirCode}
                        onChange={(e) =>
                          setFieldValue('eirCode', e.target.value)
                        }
                        disabled={!editMode}
                        containerStyle={{ border: !editMode ? 'none' : '' }}
                        style={{ opacity: editMode ? '1' : '0.5' }}
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="label">
                        Type of Property
                      </Typography>
                      <Select
                        selected={values.typeOfProperty || ''}
                        options={typeOfProperty}
                        onSelect={(selectOption: any) =>
                          setFieldValue('typeOfProperty', selectOption)
                        }
                        disabled={!editMode}
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="label">Age of Property</Typography>
                      <Select
                        selected={values.ageOfProperty || ''}
                        options={ageOfProperty}
                        onSelect={(selectOption: any) =>
                          setFieldValue('ageOfProperty', selectOption)
                        }
                        disabled={!editMode}
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="label">BER</Typography>
                      <Select
                        selected={values.ber || ''}
                        options={ber}
                        onSelect={(selectOption: any) =>
                          setFieldValue('ber', selectOption)
                        }
                        disabled={!editMode}
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="label">FirstName</Typography>

                      <Input
                        value={values.firstName}
                        onChange={(e) =>
                          setFieldValue('firstName', e.target.value)
                        }
                        disabled={!editMode}
                        containerStyle={{ border: !editMode ? 'none' : '' }}
                        style={{ opacity: editMode ? '1' : '0.5' }}
                        readOnly={!editMode}
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="label">LastName</Typography>
                      <Input
                        disabled={!editMode}
                        containerStyle={{ border: !editMode ? 'none' : '' }}
                        style={{ opacity: editMode ? '1' : '0.5' }}
                        readOnly={!editMode}
                        value={values.lastName}
                        onChange={(e) =>
                          setFieldValue('lastName', e.target.value)
                        }
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="label">Mobile Number</Typography>
                      <Input
                        disabled={!editMode}
                        containerStyle={{ border: !editMode ? 'none' : '' }}
                        style={{ opacity: editMode ? '1' : '0.5' }}
                        readOnly={!editMode}
                        value={values.mobileNumber}
                        onChange={(e) =>
                          setFieldValue('mobileNumber', e.target.value)
                        }
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="label">Email</Typography>
                      <Input
                        disabled={true}
                        containerStyle={{ border: !editMode ? 'none' : '' }}
                        style={{ opacity: editMode ? '1' : '0.5' }}
                        readOnly={!editMode}
                        value={values.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                      />
                    </InfoRowStyled>

                    <InfoRowStyled>
                      <Typography className="label">BER</Typography>
                      <Input
                        disabled={!editMode}
                        containerStyle={{ border: !editMode ? 'none' : '' }}
                        style={{ opacity: editMode ? '1' : '0.5' }}
                        readOnly={!editMode}
                        value={values.ber}
                        onChange={(e) => setFieldValue('ber', e.target.value)}
                      />
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="label">MPRN</Typography>
                      <Input
                        disabled={!editMode}
                        containerStyle={{ border: !editMode ? 'none' : '' }}
                        style={{ opacity: editMode ? '1' : '0.5' }}
                        readOnly={!editMode}
                        value={values.mprn}
                        onChange={(e) => setFieldValue('mprn', e.target.value)}
                      />
                    </InfoRowStyled>

                    <InfoRowStyled>
                      <Typography className="label">
                        Additional Works
                      </Typography>
                      {leadDetails.interest.map((item) => (
                        <div
                          key={item}
                          style={{
                            padding: '2px 30px',
                            borderRadius: '5px',

                            boxShadow:
                              'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: '600',
                            }}
                          >
                            {item}
                          </Typography>
                        </div>
                      ))}
                    </InfoRowStyled>
                    <InfoRowStyled>
                      <Typography className="label">
                        Where did you hear about us?{' '}
                      </Typography>
                      <Input
                        disabled={!editMode}
                        containerStyle={{ border: !editMode ? 'none' : '' }}
                        style={{ opacity: editMode ? '1' : '0.5' }}
                        readOnly={!editMode}
                        value={values.typeOfQuestions}
                        // onChange={(e) =>
                        //   setFieldValue('typeOfQuestions', e.target.value)
                        // }
                      />
                    </InfoRowStyled>
                  </FormContainerStyled>
                </Form>
              );
            }}
          </Formik>
        </div>
      </LeadContainer>
      <LinkContainerStyled>
        <Typography className="heading">Assessment Grant</Typography>
        <InfoRowLinkedStyled>
          <Typography className="label">ASSESSMENT GRANT APPROVAL</Typography>
          <Toggle
            initialValue={isApproved}
            onChange={() => handleToggleChange(isApproved, leadId)}
          />
        </InfoRowLinkedStyled>
        {leadDetails?.paymentInvoiceURL && (
          <InfoRowLinkedStyled>
            <Typography className="label">Payment Completed</Typography>
            <a
              href={leadDetails.paymentInvoiceURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Invoice
            </a>
          </InfoRowLinkedStyled>
        )}
        {leadDetails?.paymentLinkUrl ? (
          <InfoRowLinkedStyled>
            <Typography className="label">Payment Link Sent</Typography>
            <a
              href={leadDetails.paymentLinkUrl}
              target="_blank"
              rel="noreferrer"
            >
              View Payment Link
            </a>
          </InfoRowLinkedStyled>
        ) : (
          <InfoRowLinkedStyled>
            <Typography className="label">SEND GRANT PAYMENT LINK</Typography>
            <Button
              style={{
                width: '200px',
                color: 'white',
                outline: 'none',
                border: 'none',
              }}
              onClick={sendPaymentLinkToClient}
              variant="primary"
            >
              Send Link
            </Button>
          </InfoRowLinkedStyled>
        )}
      </LinkContainerStyled>

      {auth?.role === 'CUSTOMER-SERVICE-ASSISTANT' ? null : (
        <LinkContainerStyled>
          <Typography variant="heading" style={{ marginBottom: '10px' }}>
            Assigned To
          </Typography>
          <Formik
            initialValues={{
              assignTo: [
                { role: '', name: '', record: {}, selectedOptions: [] },
              ],
            }}
            enableReinitialize
            onSubmit={(values, { resetForm }) => {
              const userIds = [
                ...values.assignTo.flatMap((item) =>
                  item.selectedOptions.map(
                    (data: { label: string; value: string }) => data.value
                  )
                ),
                ...(leadDetails?.assignLead?.map((item) => item?._id) || []),
              ];

              handleAssignLead(
                setLoading,
                setErrorMessage,
                leadId,
                userIds,
                true,
                resetForm,
                getLeadDetails
              );
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                {leadDetails.assignLead &&
                leadDetails?.assignLead?.length > 0 ? (
                  <Table
                    tableData={formatClientTableData(
                      getLeadsAssignedData(leadDetails)
                    )}
                    style={{ marginBottom: '10px' }}
                  />
                ) : (
                  ''
                )}
                {values.assignTo.map((assignTo, index) => (
                  <InfoRowLinkedStyled key={index}>
                    <Select
                      options={roles}
                      onSelect={(selected) => {
                        setFieldValue(`assignTo[${index}].role`, selected);
                        handleRoleChange(selected, setFieldValue, index);
                      }}
                      selected={assignTo.role}
                      onChange
                      searchTerm=""
                    />
                    <MultiSelect
                      options={getClientFilteredData(
                        userRoleData,
                        assignTo.selectedOptions,
                        leadDetails
                      )}
                      selected={assignTo.selectedOptions}
                      onSelect={(selected) =>
                        setFieldValue(
                          `assignTo[${index}].selectedOptions`,
                          selected
                        )
                      }
                    />

                    {index !== values.assignTo.length - 1 && (
                      <Button
                        outline
                        onClick={() => {
                          const newAssignTo = values.assignTo.filter(
                            (_, i) => i !== index
                          );
                          setFieldValue('assignTo', newAssignTo);
                        }}
                        style={{
                          backgroundColor: 'red',
                          color: 'white',
                          border: '1px solid red',
                        }}
                      >
                        <AiOutlineCloseCircle size={25} />
                      </Button>
                    )}
                    {index === values.assignTo.length - 1 && (
                      <Button
                        onClick={() => {
                          setFieldValue('assignTo', [
                            ...values.assignTo,
                            {
                              role: '',
                              name: '',
                              record: {},
                              selectedOptions: [],
                            },
                          ]);
                        }}
                        disabled={
                          !values.assignTo[values.assignTo.length - 1]
                            .selectedOptions ||
                          values.assignTo[values.assignTo.length - 1]
                            .selectedOptions.length === 0
                        }
                      >
                        <AiOutlinePlusCircle size={25} />
                      </Button>
                    )}
                  </InfoRowLinkedStyled>
                ))}
                <Button
                  type="submit"
                  isLoading={loading}
                  variant="primary"
                  disabled={
                    !values.assignTo[values.assignTo.length - 1]
                      .selectedOptions ||
                    values.assignTo[values.assignTo.length - 1].selectedOptions
                      .length === 0
                  }
                >
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </LinkContainerStyled>
      )}
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        desc={`Are you sure want to delete? `}
        onConfirm={() => deleteHandler()}
      />
      <LeadContainer>
        <Typography
          style={{
            fontSize: '22px',
            fontWeight: '600',
            marginBottom: '15px',
          }}
        >
          Phone Call
        </Typography>
        <InfoRowStyled>
          <Typography
            style={{
              fontSize: '16px',
              marginRight: '10px',
              minWidth: 'fit-content',
            }}
            className="label"
          >
            Has Called Via Phone
          </Typography>
          <Checkbox
            isChecked={phoneChecked}
            value=""
            onChange={(e) => {
              handlePhoneApprovalChange(e.target.checked);
              handlePhoneCheckboxChange();
            }}
          />
        </InfoRowStyled>
        <InfoRowStyled>
          <Typography style={{ marginTop: '20px' }} className="label">
            Phone Notes
          </Typography>
        </InfoRowStyled>
        <InfoRowStyled
          style={{
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'flex-start',
          }}
        >
          <textarea
            style={{
              marginTop: '20px',
              width: '100%',
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              border: '1px solid #ccc',
              padding: '10px',
              boxSizing: 'border-box',
              outline: 'none',
            }}
            name=""
            id=""
            rows={10}
            onChange={(e) => {
              setPhoneNote(e.target.value);
            }}
            value={phoneNote}
          />

          {isClicked && !phoneNote ? (
            <Typography
              style={{
                color: 'red',
                marginTop: '-18px',
              }}
            >
              {'Phone note is required'}
            </Typography>
          ) : (
            ''
          )}

          <Button
            onClick={() => {
              setIsClicked(true);
              handlePhoneNote(phoneNote, leadId, phoneChecked);
            }}
          >
            {' '}
            Save{' '}
          </Button>
        </InfoRowStyled>
      </LeadContainer>
    </LeadWrapperStyled>
  );
};

export default EditLead;
