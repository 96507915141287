import documentsConfig from '@shared/apiConfigs/documents';
import { humanReadableDate, requestAPI } from '@shared/utils';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import BreadCrumb from '../../../designComponents/BreadCrumb';
import Button from '../../../designComponents/Button';
import Table, { TableDataType } from '../../../designComponents/Table';
import { DOCUMENT_ROUTE, SETTING_ROUTE } from '../../../routes/routePaths';
import Setting from '..';
import { Wrapper } from './style';

type CountType = {
  REPORT: number;
  GRANT: number;
  CONTRACT: number;
  CERTIFICATE: number;
  ADDITIONAL: number;
};

type DocumentType = {
  _id: string;
  name: string;
  documentType: keyof CountType;
  notes: string;
  documentFileName: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
};

type Props = {};

const Documents = (_props: Props) => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState('All');
  const [type, setType] = useState<keyof CountType | ''>('');
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [count, setCount] = useState<CountType>({
    REPORT: 0,
    GRANT: 0,
    CONTRACT: 0,
    CERTIFICATE: 0,
    ADDITIONAL: 0,
  });
  const [totalData, setTotalData] = useState(0);

  const deleteHandler = async (documentId: string) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this?'
    );
    if (!confirmDelete) return;
    try {
      await requestAPI(documentsConfig.deleteDocumentById(documentId));
      navigate(0);
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  const tableData: TableDataType = {
    headings: [
      { key: 'title', label: 'Title' },
      { key: 'document', label: 'Document' },
      { key: 'uploadedBy', label: 'Uploaded By' },
      { key: 'updatedAt', label: 'Updated At' },
      { key: 'actions', label: 'Actions' },
    ],
    body: documents.map((document) => ({
      id: document._id,
      title: document.name,
      document: document.documentFileName,
      uploadedBy: '',
      updatedAt: humanReadableDate(document.updatedAt),
      actions: (
        <div
          style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}
        >
          <Button
            variant="secondary"
            outline
            onClick={() => navigate(document._id)}
          >
            View
          </Button>
          <Button
            variant="update"
            outline
            onClick={() =>
              navigate(`/settings/document/edit-document/${document._id}`)
            }
          >
            Edit
          </Button>
          <Button
            variant="danger"
            outline
            onClick={() => deleteHandler(document._id)}
          >
            Delete
          </Button>
        </div>
      ),
    })),
  };

  useEffect(() => {
    requestAPI(documentsConfig.getAllDocuments(type))
      .then(({ data, counts, pagination }) => {
        setTotalData(pagination.totalData);
        setCount(counts);
        setDocuments(data);
      })
      .catch((err) => console.error(err));
  }, [navigate, type]);

  const handleButtonClick = (buttonLabel: string) => {
    setActiveButton(buttonLabel);
    setType(
      buttonLabel === 'All'
        ? ''
        : (buttonLabel.toUpperCase() as keyof CountType)
    );
  };

  const buttons: string[] = [
    'All',
    'Report',
    'Grant',
    'Contract',
    'Certificate',
    'Additional',
  ];

  const breadcrumbs = [
    {
      label: 'Settings',
      path: SETTING_ROUTE,
    },
    {
      label: 'Documents',
      path: `${SETTING_ROUTE}/${DOCUMENT_ROUTE}`,
    },
  ];

  return (
    <>
      <BreadCrumb breadCrumb={breadcrumbs} />
      <Setting>
        <Wrapper>
          <div className="content">
            {buttons.map((buttonLabel) => (
              <Button
                key={buttonLabel}
                onClick={() => handleButtonClick(buttonLabel)}
                style={{
                  borderRadius: 3,
                  background: activeButton === buttonLabel ? 'black' : 'white',
                  color: activeButton === buttonLabel ? 'white' : 'black',
                }}
                className="filter-btn"
              >
                {buttonLabel}
                {buttonLabel === 'All' && ` (${totalData})`}
                {buttonLabel !== 'All' &&
                  ` (${count[buttonLabel.toUpperCase() as keyof CountType] || 0})`}
              </Button>
            ))}
            <Button
              variant="secondary"
              onClick={() => navigate('/settings/document/add-document')}
              style={{
                marginLeft: 'auto',
              }}
            >
              New Document
            </Button>
          </div>
          <Table hideSorting tableData={tableData} showCheckBox={false} />
        </Wrapper>
      </Setting>
    </>
  );
};

export default Documents;
