import styled from 'styled-components';

export const TableWrapper = styled.div`
  padding: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
`;
export const ShowDetailsStyled = styled.div`
  display: flex;
  padding: 8px;
  border: 1px solid #e4ebed;
`;
