import { ThemeType } from '@shared/theme';
import styled from 'styled-components';

export const TabWrapperStyled = styled.nav<{ theme: ThemeType }>`
  & .tab {
    border-bottom: 2px solid #e5e7eb;
    margin-bottom: 40px;

    &_item {
      position: relative;
      background-color: transparent;
      border: none;
      font: inherit;
      border-radius: 0;
      padding: 16px;
      font-size: 18px;
      /* font-weight: 600; */
      color: black;
      cursor: pointer;
      transition: all 0.2s ease-out;

      &:hover {
        color: black;
      }

      &.active {
        color: ${({ theme }) => theme.colors.primary};

        span {
          position: absolute;
          bottom: -2px;
          left: 0;
          height: 3px;
          width: 100%;
          background-color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`;
