import { FaArrowLeft, FaHouse } from 'react-icons/fa6';
import { useNavigate, useRouteError } from 'react-router-dom';
import { styled } from 'styled-components';

import { HOME_ROUTE } from '../../routes/routePaths';
import Button from '../Button';
import Flex from '../Flex';
import Typography from '../Typography';

const ErrorBoundaryStyled = styled.div`
  background: ${({ theme }) => theme?.colors.red.faded};
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ErrorBoundary = () => {
  const error = useRouteError() as { message: string };
  const navigate = useNavigate();
  if (!error) {
    return (
      <ErrorBoundaryStyled style={{ background: 'rgba(100,50,255,0.2)' }}>
        <Typography variant="heading" style={{ marginBottom: '30px' }}>
          Route Not Found:
        </Typography>
        <caption>{JSON.stringify(window.location.href)}</caption>
        <Flex justifyContent="center" alignItems="center" gap="30px">
          <Button
            variant="white"
            outline
            style={{ marginTop: '30px' }}
            onClick={() => navigate(-1)}
          >
            <FaArrowLeft />
            Go Back
          </Button>
          <Button
            variant="white"
            outline
            style={{ marginTop: '30px' }}
            onClick={() => navigate(HOME_ROUTE)}
          >
            <FaHouse />
            Go Home
          </Button>
        </Flex>
      </ErrorBoundaryStyled>
    );
  }
  return (
    <ErrorBoundaryStyled>
      <h4>Error Occured :</h4>
      <caption>{JSON.stringify(error)}</caption>
    </ErrorBoundaryStyled>
  );
};

export default ErrorBoundary;
