import { Link } from 'react-router-dom';

import Typography from '../Typography';
import { BreadCrumbStyle } from './style';

const BreadCrumb = ({
  breadCrumb,
}: {
  breadCrumb: { label: string; path: string }[];
}) => {
  return (
    <BreadCrumbStyle>
      <nav
        style={{
          background: 'white',
          padding: 12,
          borderRadius: 3,
          marginLeft: 30,
          boxShadow:
            ' rgba(0, 0, 0, 0.12) 0px 1px 3px 0px, rgb(255, 255, 255) 0px 0px 1px 0px inset',
          boxSizing: 'border-box',
        }}
      >
        <ol style={{ display: 'flex', gap: 6 }}>
          {breadCrumb?.map((label, idx) => (
            <li key={idx}>
              {idx === breadCrumb.length - 1 ? (
                <Typography fontColor="dark">{label.label}</Typography>
              ) : (
                <Link
                  to={label.path}
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Typography fontColor="light">{label.label}/</Typography>
                </Link>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </BreadCrumbStyle>
  );
};

export default BreadCrumb;
