import React from 'react';

type TagType = 'success' | 'warning' | 'danger' | 'caution';

interface TagProps {
  style?: React.CSSProperties;
  type?: TagType;
  bgColor?: string;
  textColor?: string;
  children: React.ReactNode;
}

const tagColors: {
  [key in TagType]: { bgColor: string; textColor: string };
} = {
  success: { bgColor: '#D9F2E9', textColor: '#00C67F' },
  warning: { bgColor: '#FFFED1', textColor: '#757438' },
  danger: { bgColor: '#FDD5D5', textColor: '#F11' },
  caution: { bgColor: '#CEF6FF', textColor: '#00BDFF' },
};

const Tag: React.FC<TagProps> = ({
  style,
  type = 'success',
  bgColor,
  textColor,
  children,
  ...restProps
}) => {
  const selectedColor = bgColor ? bgColor : tagColors[type].bgColor;
  const selectedTextColor = textColor ? textColor : tagColors[type].textColor;

  const tagStyle: React.CSSProperties = {
    width: 'fit-content',
    padding: '4px 10px',
    borderRadius: '15px',
    backgroundColor: selectedColor,
    color: selectedTextColor,
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...style,
  };

  return (
    <span style={tagStyle} {...restProps}>
      {children}
    </span>
  );
};

export default Tag;
