import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 60px;
  background-color: white;
  box-shadow: 0 2px 2px -2px gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: sticky;
  z-index: 9999;
  top: 0;

  & .label {
    cursor: pointer;
    padding: 5px;

    &:hover {
      background-color: ${(props) => props.theme.colors.primary};
      color: white;
    }
  }
`;
export const LogoWrapper = styled.div``;

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  gap: 10px;

  cursor: pointer;

  & .user-pic {
    height: 40px;
    width: 40px;
    border: 1px solid gray;
    border-radius: 50%;
    padding: 5px;
  }
`;
