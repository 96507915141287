import clientsConfig from '@shared/apiConfigs/clients';
import { humanReadableDate, requestAPI } from '@shared/utils';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SearchIcon from '../../assets/search.svg';
import Button from '../../designComponents/Button';
import Input from '../../designComponents/Input';
import Pagination from '../../designComponents/Pagination';
import Table, { TableDataType } from '../../designComponents/Table';
import Typography from '../../designComponents/Typography';
import useDebounce from '../../hooks/useDebounce';

type ClientType = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: number;
  createdAt: Date;
  id: number;
};

type Props = {};

const Clients = (_props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);

  const navigate = useNavigate();

  const handleChange = (value: string) => {
    setSearch(value);
    navigate(`/clients/?search=${encodeURIComponent(value)}`, {
      replace: true,
    });
  };

  const tableData: TableDataType = {
    headings: [
      { key: 'id', label: 'Client Id' },
      { key: 'fullName', label: 'Client Name' },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'phone',
        label: 'Phone Number',
      },
      {
        key: 'createdAt',
        label: 'Created At',
      },

      {
        key: 'actions',
        label: 'Actions',
      },
    ],
    body: clients,
    // body: clients.filter((client) =>
    //   `${client.fullName}`.toLowerCase().includes(searchQuery.toLowerCase())
    // ),
  };

  useEffect(() => {
    const fetchClients = async (searchTerm: string) => {
      try {
        const response = await requestAPI(
          clientsConfig.getAllClients({
            isClient: true,
            search: searchTerm,
            page: currentPage,
          })
        );
        setTotalData(response.pagination.totalData);
        const data = response.data;
        const newData = data.map((client: ClientType) => {
          return {
            id: client._id,
            fullName: `${client.firstName} ${client.lastName}`,
            email: client.email,
            phone: client.mobileNumber,
            createdAt: humanReadableDate(client.createdAt),
            actions: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <Button
                  variant="cancel"
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${client._id}`);
                  }}
                >
                  View
                </Button>
              </div>
            ),
          };
        });
        setClients(newData);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchClients(debouncedSearch);
  }, [debouncedSearch, currentPage, navigate]);
  const handlePagination = ({ page }: { page: number }) => {
    setCurrrentPage(page);
  };

  return (
    <div
      style={{
        marginLeft: '20px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {/* <Button>Action</Button> */}
      <Typography style={{ fontWeight: '600', fontSize: '16px' }}>
        Clients
      </Typography>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ maxWidth: '500px' }}>
          <Input
            placeholder="Search Client..."
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(ev.target.value);
            }}
            iconLeft={<img src={SearchIcon} alt="search icon" />}
            value={search}
            containerStyle={{ borderRadius: '8px' }}
          />
        </div>
        <Button variant="primary" onClick={() => navigate('/clients/add')}>
          Add Client
        </Button>
      </div>
      <div>
        <Table
          tableData={tableData}
          isLoading={isLoading}
          onCheck={setSelectedClients}
          checkedData={selectedClients}
          hideSorting
        />
        <Pagination
          activePage={currentPage}
          totalCount={totalData}
          limit={10}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default Clients;
