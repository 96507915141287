import styled from 'styled-components';

export const WorkContainer = styled.div`
  max-width: 700px;
  /* margin: 20px auto; */
  /* background-color: red; */

  padding: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  & .btn-submit {
    margin-right: 20px;
  }

  & .text {
    font-weight: 500;
    width: 100px;
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

export const FormWrapper = styled.div`
  /* max-width: 500px; */
  width: 100%;
  margin: 0 auto;
`;

export const InfoRow = styled.div`
  /* display: flex; */
  /* width: 100%; */
  gap: 20px;
  margin-bottom: 20px;
`;
