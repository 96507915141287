import leadsConfig from '@shared/apiConfigs/leads';
import { requestAPI } from '@shared/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Timeline from '../../components/Dashboard/Timeline';
import Button from '../../designComponents/Button';
import Table from '../../designComponents/Table';
import Typography from '../../designComponents/Typography';
import {
  DashboardWrapper,
  MainSectionContainer,
  TableContainer,
} from './style';

type Props = {};
interface LeadType {
  _id: string;

  firstName: string;
  lastName: string;
  hasPaid: boolean;
  isClient: boolean;
  isPhoned: boolean;
  isAssessmentGrantApproved: boolean;
  paymentLinkUrl: string;
}
interface ClientType {
  _id: string;
  assessmentBooked: string;
  hasPaid: boolean;
  firstName: string;
  lastName: string;
  email: string;
  isClient: boolean;
  createdAt: string;
  updatedAt: string;
  claimAccountLinkSentAt: string;
  claimAccountToken: string;
  addressOne: string;
  addressTwo: string;
  emailNotification: boolean;
  mobileNumber: string;
  assessmentDate: string;
  isAssessmentGrantApproved: boolean;
}

const Dashboard = (_props: Props) => {
  const navigate = useNavigate();
  const [leads, setLeads] = useState<LeadType[]>([]);
  const [clients, setClients] = useState<ClientType[]>([]);
  useEffect(() => {
    const fetchLeadsAndClients = async () => {
      try {
        const leadsPromise = requestAPI(
          leadsConfig.getAllLeads({ page: 1, limit: 5, isClient: false })
        );
        const clientsPromise = requestAPI(
          leadsConfig.getAllLeads({ page: 1, limit: 5, isClient: true })
        );

        const [leadsResponse, clientsResponse] = await Promise.all([
          leadsPromise,
          clientsPromise,
        ]);

        const leadsData = Array.isArray(leadsResponse.data)
          ? leadsResponse.data
          : [leadsResponse.data];
        const clientsData = Array.isArray(clientsResponse.data)
          ? clientsResponse.data
          : [clientsResponse.data];

        setLeads(leadsData as LeadType[]);
        setClients(clientsData as ClientType[]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchLeadsAndClients();
  }, []);
  const getActiveLeadIndex = (data: LeadType) => {
    let count = 0;
    if (data.isPhoned) count++;
    if (data.isAssessmentGrantApproved) count++;
    if (data.paymentLinkUrl) count++;
    if (data.hasPaid) count++;
    return count;
  };
  const getActiveClientIndex = (data: ClientType) => {
    let count = 0;
    if (data.claimAccountLinkSentAt) count++;
    if (data.claimAccountToken) count++;
    if (data.assessmentBooked === 'Completed') count++;
    if (data.assessmentDate) count++;
    if (data.isAssessmentGrantApproved) count++;
    return count;
  };
  return (
    <DashboardWrapper>
      <div className="heading">
        <Typography variant="heading" size={16}>
          Dashboard
        </Typography>
      </div>
      <MainSectionContainer>
        <section>
          <Typography variant="heading" strong>
            Leads
          </Typography>

          <TableContainer>
            <Table
              tableData={{
                headings: [
                  { key: 'name', label: 'Full Name' },
                  { key: 'call', label: 'Call Reach Out' },
                  { key: 'assessment', label: 'Assessment Grant Approved' },
                  { key: 'paymentSent', label: 'Payment Sent' },
                  { key: 'paymentComplete', label: 'Payment Complete' },
                  { key: 'actions', label: 'Action' },
                ],
                body: leads?.map((lead) => {
                  const activeIndex = getActiveLeadIndex(lead);
                  return {
                    id: lead._id,
                    name: `${lead?.firstName} ${lead?.lastName}`,

                    call: <Timeline completed={activeIndex} position={1} />,
                    assessment: (
                      <Timeline completed={activeIndex} position={2} />
                    ),
                    paymentSent: (
                      <Timeline completed={activeIndex} position={3} />
                    ),
                    paymentComplete: (
                      <Timeline isLast completed={activeIndex} position={4} />
                    ),
                    actions: (
                      <Button
                        variant="white"
                        $outline={true}
                        onClick={() => {
                          navigate(`/leads/${lead._id}`);
                        }}
                      >
                        View
                      </Button>
                    ),
                  };
                }),
              }}
              showCheckBox={false}
              hidePagination
              headingBgColor="#f8f8f8"
              hideSorting
              cellStyle={{ padding: '30px 0', textAlign: 'center' }}
              tableHeadTypeStyle={{
                width: '100%',
              }}
              tableHeadStyle={{ textAlign: 'center', border: 'none' }}
            />
          </TableContainer>
        </section>
        <section>
          <Typography variant="heading" strong>
            Clients
          </Typography>

          <TableContainer>
            <Table
              tableData={{
                headings: [
                  { key: 'name', label: 'Full Name' },
                  { key: 'claim', label: 'Claim Account Sent' },
                  { key: 'account', label: 'Account Claimed' },
                  { key: 'ber', label: 'BER Assessment Complete' },
                  { key: 'bha', label: 'BHA Rep Assigned' },
                  { key: 'quotation', label: 'Quotation' },
                  { key: 'actions', label: 'Action' },
                ],
                body: clients?.map((client) => {
                  const activeIndex = getActiveClientIndex(client);
                  return {
                    id: client._id,
                    name: `${client?.firstName} ${client?.lastName}`,

                    claim: <Timeline completed={activeIndex} position={1} />,
                    account: <Timeline completed={activeIndex} position={2} />,
                    ber: <Timeline completed={activeIndex} position={3} />,
                    bha: <Timeline completed={activeIndex} position={4} />,
                    quotation: (
                      <Timeline isLast completed={activeIndex} position={5} />
                    ),
                    actions: (
                      <Button
                        variant="white"
                        $outline={true}
                        onClick={() => {
                          navigate(`/clients/${client._id}`);
                        }}
                      >
                        View
                      </Button>
                    ),
                  };
                }),
              }}
              showCheckBox={false}
              hidePagination
              headingBgColor="#f8f8f8"
              hideSorting
              cellStyle={{ padding: '30px 0', textAlign: 'center' }}
              tableHeadTypeStyle={{
                width: '100%',
              }}
              tableHeadStyle={{ textAlign: 'center', border: 'none' }}
            />
          </TableContainer>
        </section>
      </MainSectionContainer>
    </DashboardWrapper>
  );
};

export default Dashboard;
