import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  padding: 10px 30px;

  & .heading {
    background-color: white;
    padding: 14px 20px;
    font-size: 16px;
    margin-bottom: 20px;
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0.12),
      0 0 1px #fff inset;
    border-radius: 4px;
  }
`;

export const MainSectionContainer = styled.div`
  padding: 24px 20px;
  background-color: white;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 0 1px #fff inset;
  border-radius: 4px;
`;

export const TableContainer = styled.div`
  text-align: center;
  margin: 24px 0;
`;
